import classNames from 'classnames';
import AccountSelectMenu from 'components/Accounts/AccountSelectMenu';
import Menu from 'components/Menu/DotMenu';
import { useConfig } from 'contexts/configContext';
import { useResponsive } from 'hooks';
import { useAccount } from 'wagmi';
import ChainSelector from './ChainSelector';
import { MobileNavbar } from './MobileNavs';
import NavLinks, { NAVLINKPATH, ONLINE_BASE_URL } from './NavLinks';
import NavZkNFT from './NavZkNFT';

export const Navbar = () => {
  const { address } = useAccount();
  const { isDesktop } = useResponsive();
  const { EVM_CHAIN } = useConfig();
  const networkUrlParam = `/calamari`;

  const links = [
    {
      text: 'MantaPay',
      link: `${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Transact}`
    },
    {
      text: 'Bridge',
      link: `${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Bridge}`
    },
    {
      text: 'Staking',
      link: `${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Stake}`
    },
    {
      text: 'Govern',
      link: 'https://forum.manta.network/'
    },
    {
      text: 'Block Explorer',
      link: `${EVM_CHAIN?.blockExplorers?.etherscan?.url}`
    }
  ];

  return (
    <div>
      <div
        className={classNames(
          'flex justify-between items-center h-20 py-4 px-6',
          'lg:px-10 lg:sticky lg:left-0 lg:right-0 lg:top-0 lg:z-50 lg:bg-nav',
          '2xl:px-30'
        )}>
        <div className="flex items-center">
          <ChainSelector />
          {isDesktop && <NavLinks />}
        </div>
        <div className="flex">
          {address && <NavZkNFT />}
          <div className="h-10 gap-4 flex flex-wrap justify-end items-center">
            {isDesktop && <AccountSelectMenu />}
            {isDesktop ? <Menu /> : <MobileNavbar links={links} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { useCallback, useEffect } from 'react';

import { useConfig } from 'contexts/configContext';
import { showWarning } from 'utils/ui/Notifications';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { isMetaMask, useResponsive } from './useResponsive';
import { useContextSelector } from 'use-context-selector';
import { SbtCommonContext } from 'pages/SBTPage/SBTContext/sbtCommonContext';

export const useConnectMetamask = ({
  autoConnect = false
}: {
  autoConnect: boolean;
}) => {
  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected, isConnecting } = useAccount();
  const { EVM_CHAIN } = useConfig();
  const { isDesktop } = useResponsive();
  const hasAutoConnectMetamask = useContextSelector(
    SbtCommonContext,
    (v) => v?.hasAutoConnectMetamask
  );
  const setHasAutoConnectMetamask = useContextSelector(
    SbtCommonContext,
    (v) => v?.setHasAutoConnectMetamask
  );

  const connectMetamask = useCallback(async () => {
    if (!isMetaMask) return;
    if (!isConnected) {
      try {
        await connectAsync({
          connector: new MetaMaskConnector({
            // This will trigger 'wallet_addEthereumChain' function if the user's extension lack of manta testnet
            // https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
            chains: [EVM_CHAIN],
            options: {
              shimDisconnect: true,
              UNSTABLE_shimOnConnectSelectAccount: true
            }
          }),
          // This will trigger switch 'wallet_switchEthereumChain' function if the user's current network is not manta testnet
          // https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain
          chainId: EVM_CHAIN.id
        });
      } catch (e: any) {
        const isMetamaskInstalled =
          (window as any).ethereum?.isMetaMask &&
          !(window as any).ethereum?.isBraveWallet &&
          !(window as any).ethereum.isTalisman;
        let errorMsg = e?.message;
        if (!isMetamaskInstalled) {
          errorMsg = isDesktop
            ? 'Metamask not detected. Please install Metamask and try again.'
            : 'Please open this page using Metamask App';
        }
        errorMsg && showWarning(errorMsg);
      }
      return;
    }

    await disconnectAsync();
    await connectAsync({
      // see this doc for detail info https://wagmi.sh/react/connectors/metaMask#unstable_shimonconnectselectaccount
      connector: new MetaMaskConnector({
        chains: [EVM_CHAIN],
        options: {
          shimDisconnect: true,
          UNSTABLE_shimOnConnectSelectAccount: true
        }
      }),
      chainId: EVM_CHAIN.id
    });
    return;
  }, [isConnected]);

  useEffect(() => {
    const autoConnectFunc = async () => {
      setHasAutoConnectMetamask && setHasAutoConnectMetamask(true);
      await connectMetamask();
    };

    if (
      autoConnect &&
      !isConnected &&
      !isConnecting &&
      !hasAutoConnectMetamask
    ) {
      autoConnectFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoConnect,
    connectMetamask,
    isConnected,
    isConnecting,
    hasAutoConnectMetamask
  ]);
  return connectMetamask;
};

export enum WalletErrorCode {
  rejected = 4001,
  locked = 500,
  notAuth = 4100
}

export enum RelayerReturnedCode {
  TRANSACTION_FEE_TOO_HIGH = -6,
  SIGNATURE_MATCH_FAILED,
  SERVER_ERROR,
  INVALID_PARAMS,
  ADDRESS_IS_MINTED,
  ADDRESS_NOT_EXISTS,
  OK
}

export enum ServiceReturnedCode {
  PROOF_TAG_EXIST = -34,
  UPDATE_TAG_FAILED,
  EMAIL_SEND_FAILED,
  CODE_NOT_MATCH,
  TRANSACTION_FAILED,
  ADDRESS_IS_MINTED_ETH,
  ADDRESS_IS_MINTED_CAL,
  ASSET_ID_ALLOCATE_FAILED,
  INELIGIBLE_BALANCE_ZERO,
  INELIGIBLE,
  MINT_NOT_FOUND,
  MINT_DUPLICATED,
  MINT_FAILED,
  POMP_TRY_AGAIN_TIPS,
  CHAIN_TYPE_IS_NOT_SUPPORTED,
  CHECK_NFT_OWNER_FAILED,
  TOKEN_TYPE_IS_NOT_SUPPORTED,
  RESERVE_NEW_ASSET_ID_FAILED,
  RELAY_EXTRINSIC__FAILED,
  FREE_MINT_IS_NOT_SUPPORTED,
  MINT_ONCE_ON_HOUR,
  RECORD_NOT_EXIST,
  ASSET_ID_IS_NOT_MATCHED,
  INVALID_EXACT_ASSET,
  ASSET_TYPE_IS_NOT_SUPPORTED,
  INVALID_ASSET_RANGE,
  CHAIN_ID_IS_NOT_SUPPORTED,
  ADDRESS_IS_MINTING,
  TRANSACTION_FEE_TOO_HIGH,
  SIGNATURE_MATCH_FAILED,
  SERVER_ERROR,
  INVALID_PARAMS,
  ADDRESS_IS_MINTED,
  ADDRESS_NOT_EXISTS,
  OK
}

export const TX_SUCCESS = 1;

import { ChangeEvent, useRef } from 'react';

import classNames from 'classnames';
import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import { useSBT } from 'pages/SBTPage/SBTContext';
import { useAccount } from 'wagmi';

const UploadImg = ({
  uploadLoading,
  toggleUploadLoading
}: {
  uploadLoading: boolean;
  toggleUploadLoading: (uploadLoading: boolean) => void;
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);

  const externalAccount = useAccount();
  const { uploadImgs } = useSBT();

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      toggleUploadLoading(true);
      await uploadImgs([...e.target.files]);
      toggleUploadLoading(false);
    }
    // fix the same file can not upload twice bug
    if (fileRef?.current) {
      fileRef.current.value = '';
    }
  };
  const disabledStyle =
    uploadLoading || !externalAccount
      ? 'brightness-50 cursor-not-allowed'
      : 'cursor-pointer';

  return (
    <div className="relative w-max">
      <div
        className={classNames(
          'border border-dash-gray border-dashed bg-secondary rounded-lg w-22 h-22 lg:w-52 lg:h-52 flex justify-center items-center',
          'lg:w-48 lg:h-48'
        )}>
        {uploadLoading ? (
          <DotLoader cls="transform scale-200" />
        ) : (
          <Icon name="defaultImg" />
        )}
      </div>
      <input
        className={`opacity-0 absolute top-0 left-0 right-0 bottom-0 ${disabledStyle}`}
        type="file"
        multiple
        accept="image/*"
        onChange={onImageChange}
        disabled={uploadLoading}
        ref={fileRef}
      />
    </div>
  );
};

export default UploadImg;

import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import BN from 'bn.js';
import Balance from 'types/Balance';
import AssetType from 'types/AssetType';
import { useSubstrate } from './substrateContext';
import { useMantaWallet } from './mantaWalletContext';
import { useConfig } from './configContext';
import { useAccount } from 'wagmi';

interface IPublicBalances {
  [key: number]: Balance;
}

type PublicBalancesContext = {
  publicBalances: IPublicBalances | undefined;
  getPublicBalance: (assetType: AssetType) => Balance | null;
  getNativePublicBalance: () => Balance | null;
};
const PublicBalancesContext = createContext<PublicBalancesContext | null>(null);

export const PublicBalancesContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const { api } = useSubstrate();
  const  externalAccount  = useAccount();;
  const { IS_TESTNET, NETWORK_NAME } = useConfig();

  const [publicBalances, setPublicBalances] = useState<
    IPublicBalances | undefined
  >();

  const subscribeBalanceChange = useCallback(
    async (address: string, assetType: AssetType) => {
      if (!api || !address) {
        return null;
      }

      if (assetType.isNativeToken) {
        const unsub = await api.query.system.account(
          address,
          (balance: { data: { free: any; miscFrozen: any } }) => {
            const total = new Balance(
              assetType,
              new BN(balance.data.free.toString())
            );
            const staked = new Balance(
              assetType,
              new BN(balance.data.miscFrozen.toString())
            );
            const actuallFreeValue = total.sub(staked);

            setPublicBalances((prev) => {
              return {
                ...prev,
                [assetType.assetId]: actuallFreeValue
              };
            });
          }
        );
        return unsub;
      }
      const unsub = await api.query.assets.account(
        assetType.assetId,
        address,
        ({ value }: { value: any }) => {
          const balanceString = value.isEmpty ? '0' : value.balance.toString();
          setPublicBalances((prev) => {
            return {
              ...prev,
              [assetType.assetId]: new Balance(assetType, new BN(balanceString))
            };
          });
        }
      );
      return unsub;
    },
    [api]
  );

  const subscribeBalanceChanges = useCallback(
    async (address: string) => {
      if (!api || !address) {
        return null;
      }
      // fix: will have performance issue when use the whole config data here
      const assetTypes = AssetType.AllCurrencies(
        { NETWORK_NAME, IS_TESTNET },
        false
      );
      return await Promise.all(
        assetTypes?.map(async (assetType) => {
          return await subscribeBalanceChange(address, assetType);
        }) ?? []
      );
    },
    [api, NETWORK_NAME, IS_TESTNET, subscribeBalanceChange]
  );

  const getPublicBalance = useCallback(
    (assetType: AssetType) => {
      if (!publicBalances) {
        return null;
      }
      return publicBalances[assetType.assetId];
    },
    [publicBalances]
  );

  useEffect(() => {
    let unsubs: any;
    const getBalances = async () => {
      if (api && externalAccount) {
        unsubs = await subscribeBalanceChanges(externalAccount?.address);
      }
    };
    getBalances();
    return () => {
      if (unsubs?.length) {
        unsubs.forEach((unsub: () => void | null) => {
          unsub && unsub();
        });
      }
    };
  }, [api, externalAccount, subscribeBalanceChanges]);

  const getNativePublicBalance = useCallback(() => {
    return getPublicBalance(
      AssetType.Native({
        IS_TESTNET,
        NETWORK_NAME
      })
    );
  }, [IS_TESTNET, NETWORK_NAME, getPublicBalance]);

  const value = useMemo(
    () => ({
      publicBalances,
      getPublicBalance,
      getNativePublicBalance
    }),
    [publicBalances, getPublicBalance, getNativePublicBalance]
  );

  return (
    <PublicBalancesContext.Provider value={value}>
      {children}
    </PublicBalancesContext.Provider>
  );
};

export const usePublicBalances = () => {
  const data = useContext(PublicBalancesContext);
  if (!data || !Object.keys(data).length) {
    throw new Error(
      'usePublicBalances can only be used inside of <PublicBalancesContext />, please declare it at a higher level.'
    );
  }
  return data;
};

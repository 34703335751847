import classNames from 'classnames';
import Icon from 'components/Icon';
import { WalletType, iconNames } from 'components/Modal/EVMWalletModal';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useResponsive } from 'hooks';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useAccount } from 'wagmi';
import AccountSelectDropdown from './AccountSelectDropdown';
import ConnectWallet from './ConnectWallet';

const DisplayAccountsButton = () => {
  const  externalAccount  = useAccount();;
  const { address: ethAddress, connector } = useAccount();
  const [showAccountList, setShowAccountList] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const { isDesktop } = useResponsive();

  const succinctAccountName =
    (externalAccount?.meta?.name as string)?.length > 11
      ? `${(externalAccount?.meta?.name as string)?.slice(0, 11)}...`
      : (externalAccount?.meta?.name as string);

  const succinctEthAddress = `${ethAddress?.slice(0, 6)}...${ethAddress?.slice(
    -6
  )}`;

  const ExternalAccountBlock = ({ text }: { text: string }) => {
    return (
      <>
        <div
          className="relative flex items-center gap-2"
          onMouseEnter={() => setShowFullName(true)}
          onMouseLeave={() => setShowFullName(false)}>
          <Icon
            name={iconNames[connector?.name as WalletType] || 'metamask'}
            className="w-6 h-6 rounded-full"
          />
          {isDesktop && (
            <p className="unselectable-text">{succinctEthAddress}</p>
          )}
          {showFullName && (
            <div className="absolute -right-16 top-12 px-6 pt-1 pb-2 rounded-b-xl font-red-hat-mono bg-nav">
              {ethAddress}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="relative">
      <OutsideClickHandler onOutsideClick={() => setShowAccountList(false)}>
        <div
          className={classNames(
            'flex flex-row justify-center h-10 px-4 gap-3 border border-white-light bg-fifth dark:text-black dark:text-white font-red-hat-text text-sm cursor-pointer rounded-lg items-center',
            'lg:w-44'
          )}>
          <ExternalAccountBlock text={succinctAccountName} />
        </div>
        {showAccountList && (
          <div className="w-70 flex flex-col mt-3 absolute right-0 top-full border border-white-light rounded-lg text-black dark:text-white">
            <div className="max-h-96 overflow-y-auto bg-primary px-5 py-5 rounded-b-lg">
              <AccountSelectDropdown />
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

const AccountSelectMenu = () => {
  const { address } = useAccount();

  return address ? (
    <DisplayAccountsButton />
  ) : (
    <ConnectWallet
      isButtonShape={true}
      className={
        'bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg'
      }
    />
  );
};

export default AccountSelectMenu;

import { useEffect, useMemo, useRef, useState } from 'react';
import { type Swiper as SwiperRef } from 'swiper';

import classNames from 'classnames';
import { useModal, useResponsive } from 'hooks';
import {
  ThemeItem,
  useSBTTheme
} from 'pages/SBTPage/SBTContext/sbtThemeContext';
import ButtonWithWallet from '../ButtonWithWallet';
import ThemeCheckModal from '../ThemeCheckModal';
import ThemeChecked from '../ThemeChecked';
import NoticeCheckModal from './NoticeCheckModal';
import { LoadingDots } from 'components/LoadingDots';
import { useSbtToken } from 'pages/SBTPage/SBTContext/sbtTokenContext';

export const MAX_THEME_LEN = 10;

type ThemeItemProps = {
  themeItem: ThemeItem;
  index: number;
  toggleCheckImg: (index: number) => void;
};
const Theme = ({ themeItem, index, toggleCheckImg }: ThemeItemProps) => {
  const { name, url } = themeItem;
  const { checkedThemeItems } = useSBTTheme();
  const bgStyle = checkedThemeItems.has(name)
    ? 'bg-light-check border border-check'
    : 'bg-secondary';
  const cursorStyle =
    checkedThemeItems.size >= MAX_THEME_LEN && !checkedThemeItems.has(name)
      ? 'cursor-not-allowed'
      : 'cursor-pointer';

  return (
    <div
      onClick={() => toggleCheckImg(index)}
      key={index}
      className={`flex ${bgStyle} ${cursorStyle} rounded-xl w-24 h-28 lg:w-44 lg:h-28 flex-col items-center justify-center`}>
      <img src={url} className="w-14 h-14 rounded-full mb-2" />
      <p className="w-24 lg:w-44 text-center overflow-hidden whitespace-nowrap overflow-ellipsis">
        {name}
      </p>
    </div>
  );
};

const ThemePanel = () => {
  const { isDesktop } = useResponsive();
  const { checkedThemeItems, toggleCheckedThemeItem, themeList } =
    useSBTTheme();
  const { switchToPacific } = useSbtToken();
  const [defaultTheme, setDefaultTheme] = useState(themeList?.[0] ?? {});
  const {
    ModalWrapper: ThemeCheckModalWrapper,
    showModal: showThemeCheckModal,
    hideModal: hideThemeCheckModal
  } = useModal({
    closeOnBackdropClick: false
  });

  const {
    ModalWrapper: NoticeModalWrapper,
    showModal: showNoticeCheckModal,
    hideModal: hideNoticeCheckModal
  } = useModal({
    closeOnBackdropClick: false
  });

  const swiperRef = useRef<SwiperRef>(null);

  const btnDisabled = useMemo(() => {
    return (
      checkedThemeItems.size <= 0 || checkedThemeItems.size > MAX_THEME_LEN
    );
  }, [checkedThemeItems]);

  const handleGenerate = async () => {
    if (btnDisabled) {
      return;
    }
    showNoticeCheckModal();
  };

  const toggleCheckImg = (index: number) => {
    const currentTheme = themeList[index];
    const { name, url } = currentTheme;
    if (checkedThemeItems.has(name)) {
      checkedThemeItems.delete(name);
    } else {
      if (checkedThemeItems.size >= MAX_THEME_LEN) {
        return;
      }
      checkedThemeItems.set(name, {
        name,
        url
      });
      setTimeout(() => {
        if (swiperRef?.current) {
          swiperRef.current?.slideTo(checkedThemeItems.size - 1);
        }
      }, 100);
    }
    const newMap = new Map(checkedThemeItems);
    const values = Array.from(newMap.values());
    if (values.length === 1) setDefaultTheme(values[0]);

    toggleCheckedThemeItem(newMap);
  };

  useEffect(() => {
    if (themeList) setDefaultTheme(themeList[0]);
  }, [themeList]);

  return (
    <div
      className={classNames(
        'flex flex-col mx-auto p-6 pt-4 w-full relative',
        'lg:w-75 lg:pt-6 lg:mt-6'
      )}>
      <h1 className="text-2xl lg:text-3xl mb-2 lg:my-6 lg:mb-auto">
        Select Themes
      </h1>
      <p className="text-sm text-opacity-60 text-white">
        You can select up to {MAX_THEME_LEN} types of themes
      </p>
      {!themeList.length ? (
        <LoadingDots />
      ) : (
        <div
          className={classNames(
            'flex flex-col pb-48 mt-4',
            'lg:flex-row lg:mt-6'
          )}>
          <ThemeChecked
            swiperRef={swiperRef}
            defaultThemeItem={defaultTheme}
            button={
              isDesktop && (
                <ButtonWithWallet
                  btnComponent="Generate"
                  onClick={handleGenerate}
                  disabled={btnDisabled}
                  className="min-w-45 absolute h-10 unselectable-text text-center text-white rounded-lg gradient-button filter -bottom-21 left-1/2 -translate-x-1/2 transform"
                />
              )
            }
          />
          <div
            className={classNames(
              'grid gap-1 grid-cols-3 grid-rows-3 mt-4',
              'lg:gap-8 lg:grid-cols-4 lg:ml-6 lg:mt-auto'
            )}>
            {themeList.map((themeItem, index) => {
              return (
                <Theme
                  key={index}
                  themeItem={themeItem}
                  index={index}
                  toggleCheckImg={toggleCheckImg}
                />
              );
            })}
          </div>
          {!isDesktop && (
            <div className="fixed left-0 w-full py-4 px-6 flex justify-center items-center bg-sider bottom-0">
              <ButtonWithWallet
                btnComponent="Generate"
                onClick={handleGenerate}
                disabled={btnDisabled}
                className="w-full h-10 unselectable-text text-center text-white rounded-lg gradient-button filter "
              />
            </div>
          )}
        </div>
      )}
      <NoticeModalWrapper>
        <NoticeCheckModal
          hideModal={async () => {
            await switchToPacific();
            hideNoticeCheckModal();
            showThemeCheckModal();
          }}
        />
      </NoticeModalWrapper>
      <ThemeCheckModalWrapper>
        <ThemeCheckModal hideModal={hideThemeCheckModal} />
      </ThemeCheckModalWrapper>
    </div>
  );
};

export default ThemePanel;

import classNames from 'classnames';
import ConnectWallet from 'components/Accounts/ConnectWallet';
import Icon from 'components/Icon';
import { useModal, usePublicAddress } from 'hooks';
import { useSbtCommon } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import {
  MINT_CATEGORY,
  VALID_STATUS,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useEffect, useMemo } from 'react';
import { useAccount } from 'wagmi';
import SbtTokenMintCheckModal from '../SbtTokenMintCheckModal';
import SbtTokenMintedModal from '../SbtTokenMintedModal';
import TokenValidation from '../TokenValidation';
import MintButton from './MintButton';
import { zkTokenMap } from './ProjectConstant';
import { ZK_CATERGORY } from './type';
import { useSBT } from 'pages/SBTPage/SBTContext';
import CategoryButton from '../CategoryButton';

const BottomTipMsg = () => {
  const { tokenValidStatus: tokenValidStatus } = useSbtToken();
  const { address } = useAccount();

  // if (tokenValidStatus === VALID_STATUS.success && !address) {
  //   return (
  //     <div className="mt-2 leading-4 flex">
  //       <p className="flex text-white text-opacity-60 text-xss leading-4">
  //         <Icon name="information" className="mr-2" />
  //         Connect Manta Wallet to receive free $KMA gas fee coverage.
  //       </p>
  //       <a
  //         href="https://docs.manta.network/docs/guides/MantaWallet"
  //         target="_blank"
  //         className="text-check text-xss ml-2"
  //         rel="noreferrer">
  //         Click here to learn more about the Manta Wallet
  //       </a>
  //     </div>
  //   );
  // }
  return null;
};

const SbtToken = () => {
  const { getOnGoingTask } = useSBT();
  const {
    tokenType,
    tokenValidStatus,
    validateToken,
    setMintDefault,
    fees,
    category: categoryId
  } = useSbtToken();
  const {
    title,
    category: tokenCategory,
    content,
    imgUrl,
    detectedMsg,
    noSbtInEvmAddressMsg,
    logo1,
    logo2,
    shouldShowMintedModal = true,
    isExpired,
    customMintButton
  } = zkTokenMap[tokenType] || {};

  const { address, isConnected } = useAccount();
  const mintCategory = MINT_CATEGORY[tokenType];
  const publicAddress = usePublicAddress();
  const isZkAsMatchAigc = MINT_CATEGORY.zkAsMatchAigc === categoryId;

  useEffect(() => {
    if (isZkAsMatchAigc && address) {
      getOnGoingTask(); // zkAsMatchAigc onGoingTask status
    }
  }, [isZkAsMatchAigc, address]);

  useEffect(() => {
    if (isConnected && address && publicAddress) {
      validateToken(address, publicAddress, mintCategory);
    }
  }, [address, publicAddress, tokenType]);

  const { tokenCountList } = useSbtCommon();
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeOnBackdropClick: false,
    closeCallback: setMintDefault
  });
  const { ModalWrapper: MintedModalWrapper, showModal: showMintedModal } =
    useModal({
      closeOnBackdropClick: false,
      closeCallback: setMintDefault
    });

  const totalMinted = useMemo(() => {
    let currentCount = 0;
    for (const { token, count } of tokenCountList) {
      if (token === tokenType) {
        currentCount = count;
        break;
      }
    }
    return currentCount;
  }, [tokenType, tokenCountList]);

  return (
    <div
      className={classNames(
        'flex flex-col justify-between align-bottom mt-4 bg-white bg-opacity-5 rounded-md px-2 pt-6',
        'lg:flex-row lg:p-6'
      )}>
      <div className={classNames('relative m-auto w-full', 'lg:w-3/5')}>
        <div
          className={classNames(
            'm-auto flex flex-col justify-center items-center font-red-hat-mono px-6',
            'lg:h-91 lg:w-91 lg:px-auto'
          )}>
          <img src={imgUrl} className="rounded-lg" />
        </div>
        {shouldShowMintedModal && (
          <div
            className={classNames(
              'text-white text-opacity-80 mt-4 text-sm absolute left-9 bottom-2 select-none',
              'lg:left-8 lg:bottom-4 lg:top-64 lg:mt-6'
            )}>
            Total Minted: {totalMinted}
          </div>
        )}
      </div>
      <div className="ml-5 relative">
        <div
          className={classNames(
            'flex items-center pb-4 text-2xl mt-6',
            'lg:mt-auto'
          )}>
          {title}{' '}
          {logo1 && logo2 && (
            <div className="ml-5 flex items-center text-xss text-white bg-black px-3 py-0.5">
              <Icon name={logo1} className="mr-3" /> x{' '}
              <Icon name={logo2} className="ml-3" />
            </div>
          )}
        </div>
        <CategoryButton category={tokenCategory} />
        {content}
        {customMintButton ? (
          customMintButton()
        ) : (
          <>
            {!isExpired ? (
              <>
                <div className="flex flex-col mt-3.5  mb-4 ">
                  <span className="text-sm">Price:</span>
                  <span className="text-2xl font-semibold">
                    <span className={classNames('text-connect-price')}>
                      {fees?.aigcFee
                        ? `${
                            Number(fees?.aigcFee)
                          }ETH`
                        : 'Free Mint'}
                    </span>
                  </span>
                </div>
                {!address ? (
                  <ConnectWallet
                    isButtonShape={true}
                    className={
                      'bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg'
                    }
                  />
                ) : (
                  <div>
                    <TokenValidation
                      successMsg={detectedMsg}
                      errorMsg={noSbtInEvmAddressMsg}
                    />
                  </div>
                )}
              </>
            ) : (
              <button className="mt-6 w-44 px-4 py-2 leading-5  border border-white border-opacity-50 rounded-md text-white text-opacity-50 cursor-not-allowed">
                Expired
              </button>
            )}
            <div
              className={classNames(
                'flex items-start mt-4 mb-4',
                'lg:justify-end lg:mb-auto'
              )}>
              {tokenValidStatus === VALID_STATUS.success && address && (
                <div className="flex w-full justify-between">
                  <MintButton showMintModal={showModal} leftToRight={true} />
                </div>
              )}
            </div>
            <BottomTipMsg />
          </>
        )}
      </div>

      <ModalWrapper>
        <SbtTokenMintCheckModal
          hideModal={hideModal}
          showMintedModal={showMintedModal}
          title={title}
        />
      </ModalWrapper>
      <MintedModalWrapper>
        <SbtTokenMintedModal />
      </MintedModalWrapper>
    </div>
  );
};

export default SbtToken;

// @ts-nocheck
import * as Sentry from '@sentry/react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

export default function initAxios(config) {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['X-API-Key'] = config.SUBSCAN_API_KEY;
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );
  axiosRetry(axios, {
    retries: 10,
    retryDelay: () => 600,
    retryCondition: (error) => error.response?.status === 429
  });
}

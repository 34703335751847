import axios from 'axios';
import BN from 'bn.js';
import { MantaUtilities } from 'manta.js';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useConfig } from 'contexts/configContext';
import { useSubstrate } from 'contexts/substrateContext';
import { useZkSbtSdk } from 'contexts/zkSbtContext';
import AssetType from 'types/AssetType';
import Balance from 'types/Balance';
import { useAccount, useSignMessage } from 'wagmi';
import { MINT_CATEGORY } from './sbtTokenContext';
import { useNavigate } from 'react-router-dom';
import { MINT_ID_KEY } from '../const';
import { showInfo } from 'utils/ui/Notifications';

export enum Step {
  Home,
  Upload,
  Theme,
  Mint,
  Generating,
  Generated
}

export type UploadFile = {
  file?: File;
  name?: string;
  url?: string;
  image?: string;
  success?: boolean;
};

export type GeneratedImg = string;
export type GeneratedImgModel = { style: string; urls: GeneratedImg[] };

export type OnGoingTaskResult = {
  status: boolean;
  status_str: string;
  urls: GeneratedImgModel[];
  token_id: string;
  model_id: string;
};

export type SBTContextValue = {
  currentStep: Step;
  setCurrentStep: (nextStep: Step) => void;
  imgList: Array<UploadFile>;
  setImgList: (imgList: Array<UploadFile>) => void;
  uploadImgs: (files: File[]) => void;
  onGoingTask: OnGoingTaskResult | null;
  setOnGoingTask: (onGoingTask: OnGoingTaskResult | null) => void;
  showOnGoingTask: boolean;
  getPublicBalance: () => void;
  nativeTokenBalance: Balance | null;
  skippedStep: boolean;
  toggleSkippedStep: (skippedStep: boolean) => void;
  hintStatus: boolean;
  updateHintStatus: () => void;
  setHintStatus: (hintStatus: boolean) => void;
  getSalt: (args: any) => Promise<string>;
  getOnGoingTask: () => void;
  cleanAigc: () => Promise<boolean>;
  modelId: string;
  setModelId: (modelId: string) => void;
};

const SBTContext = createContext<SBTContextValue | null>(null);

export const SBTContextProvider = (props: { children: ReactElement }) => {
  const [currentStep, setCurrentStep] = useState(Step.Home);
  const [imgList, setImgList] = useState([] as Array<UploadFile>);
  const [onGoingTask, setOnGoingTask] = useState<OnGoingTaskResult | null>(
    null
  );
  const [nativeTokenBalance, setNativeTokenBalance] = useState<Balance | null>(
    null
  );
  const [skippedStep, toggleSkippedStep] = useState(false);
  const [hintStatus, setHintStatus] = useState(false);
  const [modelId, setModelId] = useState<string>('');

  const navigate = useNavigate();
  const externalAccount = useAccount();
  const config = useConfig();
  const { api } = useSubstrate();
  const { zkSbtSdk } = useZkSbtSdk();
  const NFT_ABANDON_MESSAGE = 'ABANDON TO MINT AIGC ZKSBT';
  const NFT_ABANDON_MESSAGE_KEY = `${externalAccount?.address}-${NFT_ABANDON_MESSAGE}`;
  const nftAbandonSignature = sessionStorage.getItem(NFT_ABANDON_MESSAGE_KEY);
  const { signMessageAsync: nftAbandonSignMessage } = useSignMessage({
    message: NFT_ABANDON_MESSAGE,
    onSuccess(signature) {
      sessionStorage.setItem(NFT_ABANDON_MESSAGE_KEY, signature);
      cleanAigc();
      console.log('success sign NFT_ABANDON_MESSAGE_KEY:', signature);
    }
  });

  const uploadImgs = useCallback(
    async (files: File[]) => {
      const address = externalAccount?.address ?? '';
      if (!address) {
        showInfo('No Address, Please retry again.');
        return;
      }
      const formData = new FormData();
      formData.append('address', address);
      files.forEach((file) => {
        formData.append('files', file);
      });
      const fileUploadUrl = `${config.SBT_NODE_SERVICE}/npo/aigc/files`;
      try {
        const ret = await axios.post<UploadFile[]>(fileUploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (ret.status === 200 || ret.status === 201) {
          const addedImgList = ret?.data?.map((data, index) => ({
            ...data,
            file: files[index]
          }));
          const newImgList = [...imgList, ...addedImgList];
          setImgList(newImgList);
        }
      } catch (e: any) {
        const addedImgList = files.map((file) => ({
          file,
          success: false,
          name: file.name
        }));
        const newImgList = [...imgList, ...addedImgList];
        setImgList(newImgList);
      }
    },
    [config?.SBT_NODE_SERVICE, externalAccount?.address, imgList]
  );

  const cleanAigc = useCallback(async () => {
    if (externalAccount?.address && zkSbtSdk) {
      try {
        if (!nftAbandonSignature) {
          await nftAbandonSignMessage();
          return false;
        }
        const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
        const url = `${config.SBT_NODE_SERVICE}/npo/aigc/clean`;
        const ret = await axios.post<{ data: OnGoingTaskResult }>(url, {
          sig: nftAbandonSignature,
          mintId: String(aigcCategory || MINT_CATEGORY.zkAsMatchAigc),
          modelId: modelId
        });
        if (ret.status === 200 || ret.status === 201) {
          setCurrentStep(Step.Home);
          navigate('/evm/sbt');
          return true;
        }
      } catch (error) {
        console.log(error, '/aigc/clean error');
        return false;
      }
    }
    return false;
  }, [
    config.SBT_NODE_SERVICE,
    externalAccount?.address,
    modelId,
    onGoingTask?.model_id,
    zkSbtSdk,
    nftAbandonSignature
  ]);

  const getOnGoingTask = useCallback(async () => {
    if (externalAccount?.address) {
      const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
      const url = `${config.SBT_NODE_SERVICE}/npo/aigc/ongoing`;
      const ret = await axios.post<{ data: OnGoingTaskResult }>(url, {
        address: externalAccount.address,
        category: aigcCategory || MINT_CATEGORY.zkAsMatchAigc
      });
      if (ret.status === 200 || ret.status === 201) {
        setOnGoingTask(ret?.data?.data);
        // set ModelId
      }
    }
  }, [config.SBT_NODE_SERVICE, externalAccount?.address]);

  useEffect(() => {
    if (currentStep === Step.Home || currentStep === Step.Upload) {
      !onGoingTask && getOnGoingTask();
    }
  }, [
    config.SBT_NODE_SERVICE,
    currentStep,
    externalAccount?.address,
    onGoingTask
  ]);

  useEffect(() => {
    const getHintStatus = async () => {
      if (externalAccount?.address) {
        const url = `${config.SBT_NODE_SERVICE}/npo/aigc/hint`;
        const data = {
          address: externalAccount?.address
        };
        const ret = await axios.post<{ status: boolean }>(url, data);
        if (ret.status === 200 || ret.status === 201) {
          setHintStatus(ret.data.status);
        }
      }
    };
    getHintStatus();
  }, [config.SBT_NODE_SERVICE, externalAccount?.address]);

  const updateHintStatus = useCallback(() => {
    if (externalAccount?.address) {
      const url = `${config.SBT_NODE_SERVICE}/npo/aigc/hints`;
      const data = {
        address: externalAccount.address
      };
      axios.post<{ status: boolean }>(url, data);
      setHintStatus(false);
    }
  }, [config.SBT_NODE_SERVICE, externalAccount?.address]);

  const getSalt = useCallback(async (args) => {
    const saltUrl = `${config.SBT_NODE_SERVICE}/npo/aigc/salt`;
    try {
      const res = await axios.post<{ data: { salt: string } }>(saltUrl, {
        ...args
      });
      return res?.data?.data?.salt || '';
    } catch (error) {
      console.log('/npo/eth/getSalt', error);
      return '';
    }
  }, []);

  const showOnGoingTask = useMemo(() => {
    return (
      (currentStep === Step.Home || currentStep === Step.Upload) &&
      !!onGoingTask &&
      !!Object.keys(onGoingTask)?.length &&
      !!onGoingTask.urls.length
    );
  }, [currentStep, onGoingTask]);

  const nativeAsset = AssetType.Native(config);

  const getPublicBalance = useCallback(async () => {
    const address = externalAccount?.address;
    if (!api?.isConnected || !address) {
      return null;
    }

    const balanceRaw = await MantaUtilities.getPublicBalance(
      api,
      new BN(nativeAsset.assetId),
      address
    );
    const balance = balanceRaw ? new Balance(nativeAsset, balanceRaw) : null;
    setNativeTokenBalance(balance);
  }, [api, externalAccount?.address, nativeAsset]);

  const value: SBTContextValue = useMemo(() => {
    return {
      currentStep,
      setCurrentStep,
      imgList,
      setImgList,
      uploadImgs,
      onGoingTask,
      showOnGoingTask,
      setOnGoingTask,
      getPublicBalance,
      nativeTokenBalance,
      toggleSkippedStep,
      skippedStep,
      hintStatus,
      updateHintStatus,
      setHintStatus,
      getSalt,
      getOnGoingTask,
      cleanAigc,
      modelId,
      setModelId
    };
  }, [
    currentStep,
    imgList,
    uploadImgs,
    onGoingTask,
    showOnGoingTask,
    getPublicBalance,
    nativeTokenBalance,
    skippedStep,
    hintStatus,
    updateHintStatus,
    getSalt,
    getOnGoingTask,
    cleanAigc,
    modelId,
    setModelId
  ]);

  return (
    <SBTContext.Provider value={value}>{props.children}</SBTContext.Provider>
  );
};

export const useSBT = () => {
  const data = useContext(SBTContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSBT() can only be used inside of <SBTContext />, please declare it at a higher level.'
    );
  }
  return data;
};
import classNames from 'classnames';
import { useConnectMetamask, useResponsive } from 'hooks';
import { SbtTokenContextProvider } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useNavigate, useParams } from 'react-router-dom';
import { randomArray } from 'utils/array/randomArray';
import { onGoingProjectTypes } from '../Home/ProjectConstant';
import SbtToken from '../Home/SbtToken';
import { MINT_TYPES } from '../Home/type';
import OnGoingProjects from '../OngoingProjects';
import { useEffect } from 'react';

const ProjectDetail = () => {
  const params = useParams();
  const tokenType = params.projectType as MINT_TYPES;
  const randomProjectList = randomArray(
    onGoingProjectTypes.filter((type) => type != tokenType)
  ).slice(0, 4);
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();

  const tokenTypeExist = onGoingProjectTypes.indexOf(tokenType) !== -1;

  // auto connect metamask
  useConnectMetamask({ autoConnect: true });

  useEffect(() => {
    if (tokenType && !tokenTypeExist) navigate('/');
  }, [tokenType, tokenTypeExist, onGoingProjectTypes]);

  return (
    <div className={classNames('lg:w-75 lg:mx-auto')}>
      <SbtTokenContextProvider propTokenType={tokenType}>
        <SbtToken />
      </SbtTokenContextProvider>
      <div className={classNames('py-8 px-8', 'lg:px-0 lg:mb-auto')}>
        <OnGoingProjects
          title="You might also like"
          projectList={randomProjectList}
          gridClassName={isDesktop ? 'grid-cols-5 gap-6' : ''}
        />
      </div>
    </div>
  );
};
export default ProjectDetail;

export enum ZK_CATERGORY {
  zkKYC = 'zkKYC',
  zkCredential = 'zkCredential',
  zkPortrait = 'zkPortrait'
}

export enum MINT_TYPES {
  zkBAB = 'zkBAB',
  zkGalxe = 'zkGalxe',
  zkCelebratory = 'zkCelebratory',
  zkOmni = 'zkOmni',
  zkAsMatchAigc = 'zkAsMatchAigc'
}

export type HomeCountData = {
  today: number;
  total: number;
  holders: number;
};

export type TokenCountResult = {
  token: MINT_TYPES;
  count: number;
};

export type CountResult = {
  count: number;
  types: Array<TokenCountResult>;
  holders: number;
  todayCount: number;
};

import { hexToBn, hexToU8a, u8aToHex } from '@polkadot/util';
import { base58Decode } from '@polkadot/util-crypto';
import axios from 'axios';
import { WalletErrorCode } from 'constants/ErrorCode';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useZkSbtSdk } from 'contexts/zkSbtContext';
import { useEthersSigner, usePublicAddress } from 'hooks';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { showCustomSuccess, showInfo } from 'utils/ui/Notifications';
import { useAccount, useSignMessage } from 'wagmi';
import { GeneratedImg } from '.';
import { zkTokenMap } from '../components/Home/ProjectConstant';
import { MINT_TYPES } from '../components/Home/type';
import { mintResponseCode } from '../const';

type GeneratedImgType = GeneratedImg & {
  proof_id?: string;
  asset_id?: string;
  token_type?: MINT_TYPES;
};

export type MintedMap = Record<string, GeneratedImgType[]>;

export enum RestoreStatus {
  Default,
  Loading,
  NoData,
  HasData,
  NoProofs,
  DetectFail,
  Fail,
  Success
}
export const NFT_QUERY_MESSAGE = 'CLAIM TO QUERY NPO ZK NFT LIST';

const PROOF_KEY_MESSAGE = 'CLAIM TO QUERY NPO ZK NFT PROOF KEY';

export type MintListContextValue = {
  tipMessage: string;
  mintedMap: MintedMap;
  loading: boolean;
  getMintedMap: () => Promise<void | string>;
  saveData: () => Promise<void>;
  postList: Array<any>;
  mintedTypeList: Array<MINT_TYPES>;
  restoreStatus: RestoreStatus;
  createProofTag: (
    proofKey?: string,
    tag?: string,
    assetId?: string,
    token_type?: string
  ) => any;
  getProofKeyTags: (asset_id: string, token_type?: string) => Promise<string[]>;
  getProofKey: (
    tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<Project>;
  deleteProofKey: (
    tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<boolean>;
  updateProofKey: (
    tag: string,
    old_tag: string,
    asset_id: string,
    token_type?: string
  ) => Promise<boolean>;
  nftQuerySignature: string;
};

export enum PROOF_STATUS_TYPE {
  VACANT = 'Vacant',
  INUSE = 'In Use'
}
export type Project = {
  createAt: number;
  address: string;
  assetId: string;
  project: string;
  proofKey: string;
  status: PROOF_STATUS_TYPE;
};

type MintExtrinsicResult = {
  address: string;
  mintType: MINT_TYPES;
  extrinsicIndex: string;
  post: any;
};

type VirtualAssetResult = {
  valid: boolean;
  message?: string;
  data: {
    asset: any;
    identifier: any;
  };
};

type MintResult = {
  code: number;
  message?: string;
};

const MintedListContext = createContext<MintListContextValue | null>(null);

// eslint-disable-next-line quotes
export const EMPTY_MESSAGE = "You don't currently have any zkPortrait";
const DEFAULT_TIP_MESSAGE = 'Please connect wallet first';

export const MintedListContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [mintedMap, setMintedMap] = useState<MintedMap>({});
  const [loading, toggleLoading] = useState(false);
  const [tipMessage, setTipMessage] = useState(DEFAULT_TIP_MESSAGE);
  const [postList, setPostList] = useState<Array<any>>([]);
  const [mintedTypeList, setMintedTypeList] = useState<MINT_TYPES[]>([]);
  const [restoreStatus, setRestoreStatus] = useState<RestoreStatus>(
    RestoreStatus.Default
  );
  const ethSigner = useEthersSigner();

  const { SBT_NODE_SERVICE, NETWORK_NAME } = useConfig();
  const { externalAccount, privateWallet } = useMantaWallet();
  const { address, isDisconnected } = useAccount();
  const publicAddress = usePublicAddress();
  const { zkSbtSdk } = useZkSbtSdk();

  const NFT_QUERY_MESSAGE_KEY = `${address}-${NFT_QUERY_MESSAGE}`;
  const nftQuerySignature = sessionStorage.getItem(NFT_QUERY_MESSAGE_KEY);
  const { signMessage: nftQuerySignMessage } = useSignMessage({
    message: NFT_QUERY_MESSAGE,
    onSuccess(signature) {
      sessionStorage.setItem(NFT_QUERY_MESSAGE_KEY, signature);
      console.log('success sign NFT_QUERY_MESSAGE_KEY:', signature);
    }
  });

  const PROOF_KEY_MESSAGE_KEY = `${address}-${PROOF_KEY_MESSAGE}`;
  const proofKeySignature = sessionStorage.getItem(PROOF_KEY_MESSAGE_KEY);
  const { signMessage: proofKeySignMessage } = useSignMessage({
    message: PROOF_KEY_MESSAGE,
    onSuccess(signature) {
      sessionStorage.setItem(PROOF_KEY_MESSAGE_KEY, signature);
      console.log('success sign PROOF_KEY_MESSAGE_KEY:', signature);
    }
  });

  useEffect(() => {
    if (!address || nftQuerySignature) {
      return;
    }
    nftQuerySignMessage(); // Fetch NFT LIST Query Signature
  }, [address, nftQuerySignMessage]);

  useEffect(() => {
    if (!address || proofKeySignature) {
      return;
    }
    proofKeySignMessage(); // Fetch Proof Key Management Signature
  }, [address, proofKeySignature]);

  useEffect(() => {
    if (isDisconnected && tipMessage !== DEFAULT_TIP_MESSAGE)
      setTipMessage(DEFAULT_TIP_MESSAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  const getMintedMap = useCallback(async () => {
    if (!nftQuerySignature) return;
    toggleLoading(true);
    if (!address) {
      setTipMessage('Please connect wallet first');
      toggleLoading(false);
      return;
    }
    setTipMessage('');
    try {
      const signature = nftQuerySignature;
      const url = `${SBT_NODE_SERVICE}/npo/eth/ethSbtList`;
      const data = {
        message: NFT_QUERY_MESSAGE,
        signature
      };
      const ret = await axios.post<MintedMap>(url, data);

      if (ret.status === 200 || ret.status === 201) {
        setMintedMap(ret.data);
        if (!Object.keys(ret.data).length) {
          setTipMessage(EMPTY_MESSAGE);
        } else {
          setTipMessage('');
        }
        toggleLoading(false);
        return 'success';
      } else {
        toggleLoading(false);
        setTipMessage('Unknown Error, please refresh the page');
      }
    } catch (e: any) {
      console.log('query nft list error: ', e);
      if (e.code === WalletErrorCode.rejected) {
        setTipMessage(
          'Please approve to the signature to view your zkPortrait list'
        );
      } else {
        setTipMessage(e.code);
        console.log(`getMintedMap Error : ${e}`);
      }
      toggleLoading(false);
    }
  }, [SBT_NODE_SERVICE, address, nftQuerySignature]);

  const getEncodePostList = useCallback((postObjList: Array<any>) => {
    return postObjList?.map((postObj: any) => {
      const sources = postObj?.sources?.map((item: any) =>
        Array.from(hexToU8a(item, 16 * 8))
      );
      const receiverPosts = postObj?.receiverPosts?.map((receiverPost: any) => {
        const utxo = receiverPost.utxo;
        const fullIncomingNote = receiverPost.fullIncomingNote;

        const incomingNote = {} as any;
        incomingNote.ephemeral_public_key = Array.from(
          hexToU8a(fullIncomingNote?.incomingNote?.ephemeralPublicKey, 32 * 8)
        );
        incomingNote.tag = Array.from(
          hexToU8a(fullIncomingNote?.incomingNote?.tag, 32 * 8)
        );
        incomingNote.ciphertext =
          fullIncomingNote?.incomingNote?.ciphertext.map((item: any) =>
            Array.from(hexToU8a(item, 32 * 8))
          );

        const lightIncomingNote = {} as any;
        lightIncomingNote.ephemeral_public_key = Array.from(
          hexToU8a(
            fullIncomingNote?.lightIncomingNote?.ephemeralPublicKey,
            32 * 8
          )
        );
        lightIncomingNote.ciphertext =
          fullIncomingNote?.lightIncomingNote?.ciphertext.map((item: any) => {
            return Array.from(hexToU8a(item, 32 * 8));
          });
        const encodeReceiverPost = {
          utxo: {
            is_transparent: utxo?.isTransparent,
            public_asset: {
              id: Array.from(hexToU8a(utxo?.publicAsset?.id, 32 * 8)),
              value: Array.from(hexToU8a(utxo?.publicAsset?.value, 16 * 8))
            },
            commitment: Array.from(hexToU8a(utxo?.commitment, 32 * 8))
          },
          full_incoming_note: {
            address_partition: fullIncomingNote.addressPartition || [],
            incoming_note: incomingNote,
            light_incoming_note: lightIncomingNote
          }
        };
        return encodeReceiverPost;
      });

      return {
        sink_accounts: postObj?.sinkAccounts ?? [],
        sinks: postObj?.sinks ?? [],
        sender_posts: postObj?.senderPosts ?? [],
        sources,
        asset_id: Array.from(hexToU8a(postObj?.assetId, 32 * 8)),
        authorization_signature: postObj?.authorizationSignature || [],
        receiver_posts: receiverPosts,
        proof: Array.from(hexToU8a(postObj?.proof, 128 * 8))
      };
    });
  }, []);

  const checkRestoreData = useCallback(async () => {
    if (loading || !address || !externalAccount?.address) {
      return;
    }
    setRestoreStatus(RestoreStatus.Loading);
    const url = `${SBT_NODE_SERVICE}/npo/mintExtrinsic`;
    const data = {
      address: address.toLowerCase()
    };
    try {
      const ret = await axios.post<{ data: MintExtrinsicResult[] }>(url, data);

      if (ret.status === 200 || ret.status === 201) {
        const extrinsicList = ret?.data?.data;
        if (!extrinsicList?.length) {
          setRestoreStatus(RestoreStatus.NoData);
          return;
        }

        const postList: any[] = [];
        const mintedTypeList: MINT_TYPES[] = [];
        const mintedList = Object.values(mintedMap).flat();
        for (let i = 0; i < extrinsicList.length; i++) {
          const { mintType, post } = extrinsicList[i];
          const alreadyHasMintedData = mintedList.some(
            (mintedData) => mintedData.token_type === mintType
          );
          if (!alreadyHasMintedData) {
            mintedTypeList.push(mintType as MINT_TYPES);
            postList.push(post);
          }
        }
        setPostList(postList);
        setMintedTypeList(mintedTypeList);
        if (postList.length && mintedTypeList.length) {
          setRestoreStatus(RestoreStatus.HasData);
        } else {
          setRestoreStatus(RestoreStatus.NoData);
        }
      }
    } catch (e) {
      console.error('check restore data error: ', e);
      setRestoreStatus(RestoreStatus.DetectFail);
    }
  }, [loading, address, externalAccount?.address, SBT_NODE_SERVICE, mintedMap]);

  useEffect(() => {
    checkRestoreData();
  }, [checkRestoreData]);

  const saveData = useCallback(async () => {
    const encodePostList = getEncodePostList(postList);
    const arrayedPostList = encodePostList.map((post) => [post]);

    if (privateWallet?.getSbtTransactionDatas) {
      setRestoreStatus(RestoreStatus.Loading);
      try {
        const transactionDatas = await privateWallet.getSbtTransactionDatas({
          posts: arrayedPostList,
          network: NETWORK_NAME
        });

        const proofsUrl = `${SBT_NODE_SERVICE}/npo/proofs`;

        const bytes = base58Decode(
          (externalAccount?.meta?.zkAddress as string) ?? ''
        );
        const addressBytes = Array.from(bytes);
        const proofInfos: Array<any> = [];
        transactionDatas?.forEach((tx: any, index) => {
          if (!tx?.[0]) {
            return;
          }
          const proofId = u8aToHex(
            tx[0].ToPrivate[0]['utxo_commitment_randomness']
          );
          const identifier = tx[0].ToPrivate[0];
          const asset_info = tx[0].ToPrivate[1];
          proofInfos.push({
            transaction_data: {
              asset_info: {
                id: asset_info.id,
                value: Number(asset_info.value)
              },
              identifier,
              zk_address: {
                receiving_key: addressBytes
              }
            },
            proof_id: proofId,
            asset_id: hexToBn(postList[index].assetId, {
              isLe: true
            }).toString(),
            blur_url:
              zkTokenMap[mintedTypeList[index] as keyof typeof MINT_TYPES]
                .imgUrl,
            eth_address: address,
            token_type: mintedTypeList[index]
          });
        });
        if (!proofInfos.length) {
          setRestoreStatus(RestoreStatus.NoProofs);
          return;
        }
        const proofsData = {
          proof_info: proofInfos,
          address: externalAccount?.address,
          model_id: '',
          token_type: mintedTypeList[0]
        };

        await axios.post<{ status: boolean }>(proofsUrl, proofsData);
        setRestoreStatus(RestoreStatus.Success);
        getMintedMap();
      } catch (e) {
        console.error('save data error: ', e);
        setRestoreStatus(RestoreStatus.Fail);
      }
    } else {
      console.error('Manta Wallet old version');
      showInfo('Manta Wallet version is low');
    }
  }, [
    NETWORK_NAME,
    SBT_NODE_SERVICE,
    address,
    externalAccount?.address,
    externalAccount?.meta?.zkAddress,
    getEncodePostList,
    mintedTypeList,
    postList,
    privateWallet,
    getMintedMap
  ]);

  const getSalt = useCallback(async (asset_id: string) => {
    const saltUrl = `${SBT_NODE_SERVICE}/npo/eth/salt`;
    try {
      const res = await axios.post<{ data: { salt: string } }>(saltUrl, {
        asset_id
      });
      return res?.data?.data?.salt || '';
    } catch (error) {
      console.log('/npo/eth/getProofKey', error);
      return '';
    }
  }, []);

  const getProofKeyTags = useCallback(
    async (asset_id, token_type) => {
      const proofKeysURL = `${SBT_NODE_SERVICE}/npo/eth/getProofKeyTags `;
      try {
        const signature = proofKeySignature;
        const res = await axios.post<{ count: number; data: string[] }>(
          proofKeysURL,
          {
            address,
            message: PROOF_KEY_MESSAGE,
            signature,
            asset_id,
            token_type
          }
        );
        return res?.data?.data || [];
      } catch (error) {
        console.log('/npo/eth/getProofKeyTags ', error);
        return [''];
      }
    },
    [SBT_NODE_SERVICE, address, proofKeySignature]
  );

  const getProofKey = useCallback(
    async (asset_id, tag, token_type) => {
      const address = externalAccount?.address;
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/eth/getProofKey`;
      try {
        const signature = proofKeySignature;
        if (!signature) {
          return {} as Project;
        }
        const res = await axios.post<Project>(proofKeyURL, {
          address,
          message: PROOF_KEY_MESSAGE,
          signature,
          asset_id,
          project: tag,
          token_type
        });
        return res?.data || {};
      } catch (error) {
        console.log('/npo/eth/getProofKey', error);
        return {} as Project;
      }
    },
    [SBT_NODE_SERVICE, address, proofKeySignature]
  );

  const deleteProofKey = useCallback(
    async (tag, asset_id, token_type) => {
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/eth/deleteProofKey`;
      try {
        const signature = proofKeySignature;
        if (!signature) {
          return false;
        }
        const res = await axios.post<{ code: number; message: string }>(
          proofKeyURL,
          {
            asset_id,
            project: tag,
            address,
            message: PROOF_KEY_MESSAGE,
            signature,
            token_type
          }
        );
        const success = res?.data?.code === mintResponseCode.OK.code;
        const reason = res?.data?.message;
        if (success) {
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>successfully deleted</div>
            </div>,
            2000
          );
        } else {
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>{reason}</div>
            </div>,
            2000
          );
        }
        return success;
      } catch (error) {
        console.log('/npo/deleteProofKey', error);
        return false;
      }
    },
    [SBT_NODE_SERVICE, address, proofKeySignature]
  );

  const updateProofKey = useCallback(
    async (tag, old_tag, asset_id, token_type) => {
      const proofKeyURL = `${SBT_NODE_SERVICE}/npo/eth/updateProofKeyTag`;
      try {
        const signature = proofKeySignature;
        if (!signature) {
          return false;
        }
        const res = await axios.post<{ code: number; message: string }>(
          proofKeyURL,
          {
            signature,
            address,
            asset_id,
            project: tag,
            project_old: old_tag
          }
        );
        const success = res?.data?.code === mintResponseCode.OK.code || false;
        const reason = res?.data?.message;
        if (success) {
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>successfully updated</div>
            </div>,
            2000
          );
        } else {
          showCustomSuccess(
            <div className="flex flex-col -mt-3">
              <div>Proof Key</div>
              <div>{reason}</div>
            </div>,
            2000
          );
        }
        return success;
      } catch (error) {
        console.log('/npo/updateProofKey', error);
        return false;
      }
    },
    [SBT_NODE_SERVICE, address, proofKeySignature]
  );

  const createProofTag = useDebouncedCallback(
    useCallback(
      async (proofKey, tag, asset_id, token_type) => {
        const createPostURL = `${SBT_NODE_SERVICE}/npo/eth/newProofKeyTag`;
        const salt = await getSalt(asset_id);
        const proof = await zkSbtSdk?.generateProof(BigInt(salt));
        try {
          const signature = proofKeySignature;
          const res = await axios.post<MintResult>(createPostURL, {
            signature,
            asset_id,
            project: tag,
            proof: proof?.proof,
            salt,
            nullifierHash: proof?.publicSignals?.nullifierHash
          });
          const { code, message } = res?.data || {};
          if (code === mintResponseCode.OK.code) {
            showCustomSuccess(
              <div className="flex flex-col -mt-3">
                <div>New Proof Key</div>
                <div>successfully created</div>
              </div>,
              2000
            );
          } else {
            showInfo(message);
          }
        } catch (error) {
          console.log('createProofTag', error);
          return;
        }
      },
      [
        NETWORK_NAME,
        SBT_NODE_SERVICE,
        address,
        publicAddress,
        zkSbtSdk,
        proofKeySignature
      ]
    ),
    1000
  );

  const value = useMemo(
    () => ({
      mintedMap,
      loading,
      tipMessage,
      getMintedMap,
      postList,
      mintedTypeList,
      saveData,
      restoreStatus,
      createProofTag,
      getProofKeyTags,
      getProofKey,
      updateProofKey,
      deleteProofKey,
      nftQuerySignature
    }),
    [
      mintedMap,
      loading,
      tipMessage,
      getMintedMap,
      postList,
      mintedTypeList,
      saveData,
      restoreStatus,
      createProofTag,
      getProofKeyTags,
      getProofKey,
      updateProofKey,
      deleteProofKey,
      nftQuerySignature
    ]
  );

  return (
    <MintedListContext.Provider value={value}>
      {children}
    </MintedListContext.Provider>
  );
};

export const useMintedList = () => {
  const data = useContext(MintedListContext);
  if (!data || !Object.keys(data).length) {
    throw new Error(
      'useMintedList can only be used inside of <MintedListContext />, please declare it at a higher level.'
    );
  }
  return data;
};

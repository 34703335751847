import AsmatchBanner from './components/AsmatchBanner';
import Generated from './components/Generated';
import Generating from './components/Generating';
import Home from './components/Home/AIGC';
import ThemePanel from './components/ThemePanel';
import UploadPanel from './components/UploadPanel';
import { Step, useSBT } from './SBTContext';

const AsmatchBannerWapper = ({ children }: { children: React.ReactNode }) => (
  <div>
    <AsmatchBanner />
    {children}
  </div>
);

const Main = () => {
  const { currentStep } = useSBT();
  if (currentStep === Step.Home) {
    return <Home />;
  }
  if (currentStep === Step.Upload) {
    return (
      <AsmatchBannerWapper>
        <UploadPanel />
      </AsmatchBannerWapper>
    );
  }
  if (currentStep === Step.Theme) {
    return (
      <AsmatchBannerWapper>
        <ThemePanel />
      </AsmatchBannerWapper>
    );
  }
  if (currentStep === Step.Generating) {
    return (
      <AsmatchBannerWapper>
        <Generating />
      </AsmatchBannerWapper>
    );
  }
  if (currentStep === Step.Generated) {
    return (
      <AsmatchBannerWapper>
        <Generated />
      </AsmatchBannerWapper>
    );
  }
  return <Home />;
};

export default Main;

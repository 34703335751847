export const MINT_ID_KEY = 'mintId';
export const TAG_ORIGINAL_NAME = 'original';

export const mintResponseCode = {
  OK: {
    code: 0,
    message: 'success',
    data: {}
  },
  ADDRESS_NOT_EXISTS: {
    code: -1,
    message: 'Not in the allowlist'
  },
  ADDRESS_IS_MINTED: {
    code: -2,
    message: 'Already minted SBT'
  },
  INVALID_PARAMS: {
    code: -3,
    message: 'Parameters invalid'
  },
  SERVER_ERROR: {
    code: -4,
    message: 'Server Error'
  },
  SIGNATURE_MATCH_FAILED: {
    code: -5,
    message: 'Signature invalid'
  },
  TRANSACTION_FEE_TOO_HIGH: {
    code: -6,
    message: 'Current gas fee too high'
  },
  ADDRESS_IS_MINTING: {
    code: -7,
    message: 'Current address is minting'
  },
  CHAIN_ID_IS_NOT_SUPPORTED: {
    code: -8,
    message: 'The chain id is not supported'
  },
  INVALID_ASSET_RANGE: {
    code: -9,
    message: 'Invalid asset range'
  },
  ASSET_TYPE_IS_NOT_SUPPORTED: {
    code: -10,
    message: 'The asset type is not supported'
  },
  INVALID_EXACT_ASSET: {
    code: -11,
    message: 'Invalid exact asset'
  },
  ASSET_ID_IS_NOT_MATCHED: {
    code: -12,
    message: 'Asset id is not matched'
  },
  RECORD_NOT_EXIST: {
    code: -13,
    message: 'The record is not exists'
  },
  MINT_ONCE_ON_HOUR: {
    code: -14,
    message: 'You can only mint zkCertificate once an hour'
  },
  FREE_MINT_IS_NOT_SUPPORTED: {
    code: -15,
    message: 'The free mint is not supported'
  },
  RELAY_EXTRINSIC__FAILED: {
    code: -16,
    message: 'Relay POMP extrinsic failed'
  },
  RESERVE_NEW_ASSET_ID_FAILED: {
    code: -17,
    message: 'Reserve new asset id failed'
  },
  TOKEN_TYPE_IS_NOT_SUPPORTED: {
    code: -18,
    message: 'The token type is not supported'
  },
  CHECK_NFT_OWNER_FAILED: {
    code: -19,
    message: 'Verify user NFT ownership failed'
  },
  CHAIN_TYPE_IS_NOT_SUPPORTED: {
    code: -20,
    message: 'The chain type is not supported'
  },
  POMP_TRY_AGAIN_TIPS: {
    code: -21,
    message: 'We are working on your previous minting, please try again later'
  },
  MINT_FAILED: {
    code: -22,
    message: 'Mint zkSBT failed'
  },
  MINT_DUPLICATED: {
    code: -23,
    message: 'Duplicate zkSBTs'
  },
  MINT_NOT_FOUND: {
    code: -24,
    message: 'Mint info not found'
  },
  INELIGIBLE: {
    code: -25,
    message: 'User is ineligible to mint'
  },
  INELIGIBLE_BALANCE_ZERO: {
    code: -26,
    message: 'User balance is zero'
  },
  ASSET_ID_ALLOCATE_FAILED: {
    code: -27,
    message: 'Asset id allocate failed'
  },
  ADDRESS_IS_MINTED_CAL: {
    code: -28,
    message: 'Already minted SBT on Atlantic'
  },
  ADDRESS_IS_MINTED_ETH: {
    code: -29,
    message: 'Already minted SBT on Pacific'
  },
  TRANSACTION_FAILED: {
    code: -30,
    message: 'Transaction failed on Pacific'
  }
};

export const enum ProcessingStatus {
  GENERATING = 'Generating',
  GENERATED = 'Generated',
  PREPAREMINTED = 'PrepareMinted',
  MINTED = 'Minted'
}

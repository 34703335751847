import Icon, { IconName } from 'components/Icon';
import { ReactNode } from 'react';

import CampaignLink from '../CampaignLink';
import { useNavigate } from 'react-router-dom';
import { MINT_TYPES, ZK_CATERGORY } from './type';

export const onGoingProjectTypes = [
  MINT_TYPES.zkAsMatchAigc,
  MINT_TYPES.zkOmni,
  MINT_TYPES.zkCelebratory,
  MINT_TYPES.zkGalxe,
  MINT_TYPES.zkBAB
];

const zkBABContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    The zkBAB provides Binance users who have completed Identity Verification
    with a digital verification experience akin to the BAB Token, with the added
    benefit of an additional layer of privacy. This enhanced privacy feature
    enables users to verify their Binance KYC without exposing any sensitive
    information such as their wallet address, assets held within the wallet, or
    transactions conducted using the wallet address. By using zkBABs, users can
    fulfill the Binance identity verification requirements in Web3 while keeping
    their information completely confidential. To mint your zkBAB, kindly refer
    to the link below. For further details on BAB Token, please refer to the
    information page provided on{' '}
    <a
      href="https://www.binance.com/en/BABT?source=header"
      className="text-check"
      target="_blank"
      rel="noopener noreferrer">
      Binance.
    </a>
  </div>
);

const zkGalxeContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Galxe zkPassport is a cutting-edge digital identity solution that combines
    the security and anonymity of zero-knowledge (zk) technology with the
    convenience and universality of Galxe Passport. By leveraging zk technology,
    you can authenticate themselves and prove ownership of Galxe Passport
    without revealing any additional information about themselves or your
    assets. This makes zkPassport a more secure and private way of managing
    digital identities in both Web3&Web2 spaces, while also providing a seamless
    user experience.
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/passport',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Galxe Passport</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);

const zkCelebratoryContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    The Pacific Celebratory zkSBT is a limited-time zkSBT available to anyone
    and everyone representing the Manta Pacific ecosystem at an early stage. The
    growth of the ecosystem depends on your involvement, and the Manta community
    is honored to have you join on this adventure. For a period of two months
    (ending Nov 24, 2023), you will be able to mint this zkSBT to demonstrate
    immutably and verifiability that you are an early supporter of the Manta
    Pacific community.
  </div>
);

const zkOmniContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    Manta is partnering with Omni Network to launch zkOmni for Omni Network
    community members. zkOmni allows Omni Network members to have a zkDID when
    enjoying Web3 products and experiences. By minting a zkOmni, you will be
    able to use it in Manta ecosystem projects with privacy, and, furthermore,
    with your long-time-built valuable identity without disclosing your exact
    address. With Manta's zk innovation-proof key, Manta's zkSBT will be able to
    integrate with any mobile application in Web2 & Web3 and also integrate with
    any chain and ecosystem. Who is eligible: You will be eligible to this
    minting event if you ever have these two OATs of Omni Network, Click here to
    see if you have one of these OATs:
    <div className="flex">
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/OmniNetwork/campaign/GC4YwUL9f4',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>DripVerse Protocol</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
      <div
        onClick={() => {
          window.open(
            'https://galxe.com/OmniNetwork/campaign/GCqGbUA5yA',
            '_blank',
            'noopener,noreferrer'
          );
        }}
        className="ml-6 mt-4 flex items-center text-campaign-link cursor-pointer">
        <span>Polkastarter</span>
        <Icon name="arrowRightCircle" className="text-campaign-link ml-2.5" />
      </div>
    </div>
  </div>
);
const zkAsMatchAigcContent = (
  <div className="text-sm text-white text-opacity-80 leading-4.5">
    <p>
      The zkPortrait is an AI-generated content (AIGC) zero-knowledge soul-bound
      token (zkSBT) that can be bonded to your AsMatch profile. The zkPortrait
      also serves as your AI-generated profile picture (PFP) and as a
      privacy-enhanced decentralized identity (DID) as it is soul-bound and
      cannot be traded amongst other users. Choose from an array of themes to
      customize your unique AIGC PFP.
    </p>
    <p>Bonding the zkPortrait to your AsMatch profile enables you to:</p>
    <p>- Unlock your earned $ASM Airdrops for usage.</p>
    <p>- Level up your profile.</p>
    <p>- Boost your Match Battery.</p>
    <p>- Access future features to improve your social & earning experience.</p>
  </div>
);

type ZkToken = {
  title: string;
  category: ZK_CATERGORY;
  imgUrl: string;
  imgUrlHorizontal: string;
  content: ReactNode;
  contentHorizontal: ReactNode;
  noSbtInEvmAddressMsg: string;
  detectedMsg: string;
  logo1?: IconName;
  logo2?: IconName;
  shouldShowMintedModal?: boolean;
  isExpired?: boolean;
  customMintButton?: () => React.ReactNode;
};
export const zkTokenMap: Record<MINT_TYPES, ZkToken> = {
  [MINT_TYPES.zkBAB]: {
    title: MINT_TYPES.zkBAB,
    category: ZK_CATERGORY.zkKYC,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkBAB-570.jpeg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkBAB-570.jpeg',
    content: zkBABContent,
    contentHorizontal: (
      <div className="lg:mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        The zkBAB provides Binance users who have completed Identity
        Verification with a digital verification experience akin to the BAB
        Token, with the added benefit of an additional layer of privacy. This
        enhanced privacy feature enables users to verify their Binance KYC
        without exposing any sensitive information such as their wallet address,
        assets held within the wallet, or transactions conducted using the
        wallet address. By using zkBABs, users can fulfill the Binance identity
        verification requirements in Web3 while keeping their information
        completely confidential.
      </div>
    ),
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible'
  },
  [MINT_TYPES.zkGalxe]: {
    title: 'zkGalxe Passport',
    category: ZK_CATERGORY.zkKYC,
    imgUrl: 'https://npo-cdn.asmatch.xyz/Galxe-zkPassport-FINAL.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zk-passport.jpeg',
    content: zkGalxeContent,
    contentHorizontal: (
      <div className="lg:mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Galxe zkPassport is a cutting-edge digital identity solution that
        combines the security and anonymity of zero-knowledge (zk) technology
        with the convenience and universality of Galxe Passport. By leveraging
        zk technology, you can authenticate themselves and prove ownership of
        Galxe Passport without revealing any additional information about
        themselves or your assets. This makes zkPassport a more secure and
        private way of managing digital identities in both Web3&Web2 spaces,
        while also providing a seamless user experience.
      </div>
    ),
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible'
  },
  [MINT_TYPES.zkCelebratory]: {
    title: 'Pacific Celebratory zkSBT',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkCelebratry.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkCelebratry2.jpg',
    content: zkCelebratoryContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="lg:mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        The Pacific Celebratory zkSBT is a limited-time zkSBT available to
        anyone and everyone representing the Manta Pacific ecosystem at an early
        stage. The growth of the ecosystem depends on your involvement, and the
        Manta community is honored to have you join on this adventure. For a
        period of two months (ending Nov 24, 2023), you will be able to mint
        this zkSBT to demonstrate immutably and verifiability that you are an
        early supporter of the Manta Pacific community.
      </div>
    )
  },
  [MINT_TYPES.zkOmni]: {
    title: 'Omni Network zkOmni',
    category: ZK_CATERGORY.zkCredential,
    imgUrl: 'https://npo-cdn.asmatch.xyz/zkOmni.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/zkOmni2.jpg',
    content: zkOmniContent,
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    contentHorizontal: (
      <div className="lg:mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        Manta is partnering with Omni Network to launch zkOmni for Omni Network
        community members. zkOmni allows Omni Network members to have a zkDID
        when enjoying Web3 products and experiences. By minting a zkOmni, you
        will be able to use it in Manta ecosystem projects with privacy, and,
        furthermore, with your long-time-built valuable identity without
        disclosing your exact address. With Manta's zk innovation-proof key,
        Manta's zkSBT will be able to integrate with any mobile application in
        Web2 & Web3 and also integrate with any chain and ecosystem.
      </div>
    )
  },
  [MINT_TYPES.zkAsMatchAigc]: {
    title: 'AsMatch AIGC zkPortrait',
    category: ZK_CATERGORY.zkPortrait,
    imgUrl: 'https://npo-cdn.asmatch.xyz/asmatch20.jpg',
    imgUrlHorizontal: 'https://npo-cdn.asmatch.xyz/asmatch21.jpg',
    content: zkAsMatchAigcContent,
    contentHorizontal: (
      <div className="lg:mr-40 text-white text-opacity-80 text-sm text-mutilple-overflow">
        The zkPortrait is an AI-generated content (AIGC) zero-knowledge
        soul-bound token (zkSBT) that can be bonded to your AsMatch profile. The
        zkPortrait also serves as your AI-generated profile picture (PFP) and as
        a privacy-enhanced decentralized identity (DID) as it is soul-bound and
        cannot be traded amongst other users. Choose from an array of themes to
        customize your unique AIGC PFP. Bonding the zkPortrait to your AsMatch
        profile enables you to: - Unlock your earned $ASM Airdrops for usage . -
        Level up your profile. - Boost your Match Battery. - Access future
        features to improve your social & earning experience.
      </div>
    ),
    noSbtInEvmAddressMsg: 'Not Eligible',
    detectedMsg: 'Eligible',
    shouldShowMintedModal: false
  }
};

export const zkCredentialsProjectTypes = Object.entries(zkTokenMap)
  .filter((item) => item[1].category === ZK_CATERGORY.zkCredential)
  .map((item) => item[0])
  .reverse() as MINT_TYPES[];

export const zkKYCProjectTypes = Object.entries(zkTokenMap)
  .filter((item) => item[1].category === ZK_CATERGORY.zkKYC)
  .map((item) => item[0])
  .reverse() as MINT_TYPES[];

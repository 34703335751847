import { ReactComponent as dolphin } from 'resources/images/dolphin.svg';
import { ReactComponent as dolphinLogoCombined } from 'resources/images/dolphinLogoCombined.svg';
import { ReactComponent as calamariWhite } from 'resources/images/calamariWhite.svg';
import { ReactComponent as calamari } from 'resources/images/calamari.svg';
import { ReactComponent as calamariLogo } from 'resources/images/calamariLogo.svg';
import { ReactComponent as calamariLogoCombined } from 'resources/images/calamariLogoCombined.svg';
import { ReactComponent as manta } from 'resources/images/manta.svg';
import { ReactComponent as mantaLogo } from 'resources/images/mantaLogo.svg';
import { ReactComponent as mantaLogoWhite } from 'resources/images/mantaLogoWhite.svg';
import { ReactComponent as mantaAtlantic } from 'resources/images/manta-atlantic.svg';
import { ReactComponent as mantaPacific } from 'resources/images/manta-pacific.svg';
import { ReactComponent as talisman } from 'resources/icons/talisman.svg';
import { ReactComponent as metamask } from 'resources/icons/metamask.svg';
import { ReactComponent as walletconnect } from 'resources/icons/wallet_connect.svg';
// coin icons
import { ReactComponent as ethereum } from 'resources/icons/coins/ethereum-icon.svg';
import { ReactComponent as kusama } from 'resources/icons/coins/kusama-icon.svg';

// sidebar icons
import { ReactComponent as close } from 'resources/icons/close.svg';
import { ReactComponent as mobileMenu } from 'resources/icons/mobile-menu.svg';
import { ReactComponent as mobileClose } from 'resources/icons/mobile-close.svg';

// widesite icons
import { ReactComponent as token } from 'resources/icons/token-icon.svg';
import { ReactComponent as sun } from 'resources/icons/sun.svg';
import { ReactComponent as moon } from 'resources/icons/moon.svg';
import { ReactComponent as greenCheck } from 'resources/icons/green-check-solid.svg';
import { ReactComponent as information } from 'resources/icons/information-solid.svg';
import { ReactComponent as qrCode } from 'resources/icons/qr-code.svg';
import { ReactComponent as threeRightArrow } from 'resources/icons/three-right-arrow.svg';
import { ReactComponent as grayThinLine } from 'resources/icons/gray-thin-line.svg';
import { ReactComponent as blueSolidLine } from 'resources/icons/blue-solid-line.svg';
import { ReactComponent as unfilledCircle } from 'resources/icons/unfilled-circle.svg';

// chain data icons

// extrinsic icons
import { ReactComponent as copySquare } from 'resources/icons/copy-square.svg';
import { ReactComponent as polkadot } from 'resources/icons/polkadot.svg';
import { ReactComponent as dropDown } from 'resources/icons/drop-down.svg';
import { ReactComponent as warning } from 'resources/icons/warning.svg';
import { ReactComponent as arrowRight } from 'resources/icons/arrow-right.svg';
import { ReactComponent as arrowRightCircle } from 'resources/icons/arrow-right-circle.svg';
import { ReactComponent as cancel } from 'resources/icons/cancel.svg';
import { ReactComponent as txSuccess } from 'resources/icons/tx-success.svg';
import { ReactComponent as txFailed } from 'resources/icons/tx-failed.svg';
import { ReactComponent as txPending } from 'resources/icons/tx-pending.svg';

// links icons
import { ReactComponent as CalamariWebsite } from 'resources/icons/links/CalamariWebsite.svg';
import { ReactComponent as twitter } from 'resources/icons/links/Twitter.svg';
import { ReactComponent as Discord } from 'resources/icons/links/Discord.svg';
import { ReactComponent as Telegram } from 'resources/icons/links/Telegram.svg';
import { ReactComponent as Medium } from 'resources/icons/links/Medium.svg';
import { ReactComponent as Docs } from 'resources/icons/links/Docs.svg';
import { ReactComponent as BugReport } from 'resources/icons/links/BugReport.svg';

import { ReactComponent as defaultImg } from 'resources/icons/default-img.svg';
import { ReactComponent as invalid } from 'resources/icons/invalid.svg';
import { ReactComponent as circleArrow } from 'resources/icons/circle-arrow.svg';
import { ReactComponent as question } from 'resources/icons/question.svg';

// sbt
import { ReactComponent as search } from 'resources/icons/search.svg';
import { ReactComponent as edit } from 'resources/icons/edit.svg';
import { ReactComponent as editSelect } from 'resources/icons/edit-select.svg';
import { ReactComponent as asMatch } from 'resources/icons/as-match.svg';
import { ReactComponent as singleColorManta } from 'resources/icons/single-color-manta.svg';
import { ReactComponent as fingerPrint } from 'resources/icons/finger-print.svg';

// ethereum tokens
import { ReactComponent as avax } from 'resources/icons/coins/avax.svg';
import { ReactComponent as bnb } from 'resources/icons/coins/bnb.svg';
import { ReactComponent as dot } from 'resources/icons/coins/dot.svg';
import { ReactComponent as usdc } from 'resources/icons/coins/usdc.svg';
import { ReactComponent as uni } from 'resources/icons/coins/uni.svg';
import { ReactComponent as wbtc } from 'resources/icons/coins/wbtc.svg';
import { ReactComponent as link } from 'resources/icons/coins/link.svg';
import { ReactComponent as matic } from 'resources/icons/coins/matic.svg';
import { ReactComponent as usdt } from 'resources/icons/coins/usdt.svg';
import { ReactComponent as shib } from 'resources/icons/coins/shib.svg';
import { ReactComponent as ldo } from 'resources/icons/coins/ldo.svg';
import { ReactComponent as op } from 'resources/icons/coins/op.svg';
import { ReactComponent as eth } from 'resources/icons/coins/eth.svg';

import { ReactComponent as activityRightArrow } from 'resources/icons/activity-right-arrow.svg';
import { ReactComponent as galxe } from 'resources/icons/galxe.svg';
import { ReactComponent as mantaWithName } from 'resources/icons/manta-with-name.svg';

const icons = {
  dolphin,
  dolphinLogoCombined,
  calamari,
  calamariWhite,
  calamariLogo,
  calamariLogoCombined,
  manta,
  mantaLogo,
  mantaLogoWhite,
  mantaAtlantic,
  mantaPacific,
  talisman,
  metamask,
  ethereum,
  kusama,
  close,
  mobileMenu,
  mobileClose,
  token,
  sun,
  moon,
  greenCheck,
  information,
  qrCode,
  unfilledCircle,
  copySquare,
  polkadot,
  dropDown,
  warning,
  arrowRight,
  arrowRightCircle,
  cancel,
  defaultImg,
  invalid,
  circleArrow,
  question,
  eth,
  usdc,
  uni,
  wbtc,
  link,
  matic,
  bnb,
  usdt,
  shib,
  ldo,
  op,
  avax,
  dot,
  ksm: kusama,
  txSuccess,
  txFailed,
  txPending,
  grayThinLine,
  blueSolidLine,
  threeRightArrow,
  CalamariWebsite,
  Twitter: twitter,
  Discord,
  Telegram,
  Medium,
  Docs,
  BugReport,
  asMatch,
  singleColorManta,
  activityRightArrow,
  walletconnect,
  galxe,
  mantaWithName,
  edit,
  editSelect,
  search,
  fingerPrint
};
export default icons;

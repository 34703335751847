import { AIGC, SBT } from 'pages';
import { EthereumBasePage } from 'pages/BasePage';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

const RedirectRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/evm/sbt" replace />} />
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <RedirectRoutes />
      <EthereumBasePage>
        <Routes>
          <Route path="evm">
            <Route index element={<Navigate to="sbt" />} />
            <Route path="sbt/*" element={<SBT />} />
          </Route>
        </Routes>
      </EthereumBasePage>
    </Router>
  );
};

export default AppRouter;

import axios from 'axios';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useConfig } from 'contexts/configContext';
import { useAccount } from 'wagmi';
import { GeneratedImgModel, Step, useSBT } from '.';
import { GeneratedImg } from './index';
import { MINT_CATEGORY } from './sbtTokenContext';
import { MINT_ID_KEY, ProcessingStatus } from '../const';

export enum GenerateStatus {
  finished,
  doing
}

type GeneratingContextValue = {
  generatedImgModels: GeneratedImgModel[];
  generatedImgThemes: { [key: string]: string };
  generateStatus: GenerateStatus;
  setGenerateStatus: (generateStatus: GenerateStatus) => void;
  setGeneratedImgModels: (generatedImgs: GeneratedImgModel[]) => void;
  setGeneratedImgThemes: (generatedImgs: { [key: string]: string }) => void;
  handleGeneratedImgThemes: (generatedImgThemes: GeneratedImgModel[]) => void;
  queryGenerateResult: () => boolean;
};

const GeneratingContext = createContext<GeneratingContextValue | null>(null);

const INTERVAL_MILLISECONDS = 120000 / 2;

export const GeneratingContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [generatedImgModels, setGeneratedImgModels] = useState<
    GeneratedImgModel[]
  >([]);
  const [generatedImgThemes, setGeneratedImgThemes] = useState<{
    [key: string]: string;
  }>({});
  const [generateStatus, setGenerateStatus] = useState<GenerateStatus>(
    GenerateStatus.doing
  );

  const [queryGenerateResultSuccessFlag, setQueryGenerateResultSuccessFlag] =
    useState(false);
  const config = useConfig();
  const { address } = useAccount();
  const { currentStep, setCurrentStep, onGoingTask, modelId, setModelId } =
    useSBT();

  const handleGeneratedImgThemes = (onGoingTaskUrls: GeneratedImgModel[]) => {
    if (!onGoingTaskUrls.length) {
      setGeneratedImgThemes({});
      return;
    }
    let imgThemeMap: { [key: string]: string } = {};
    for (let index = 0; index < onGoingTaskUrls.length; index++) {
      const { style, urls } = onGoingTaskUrls[index];
      for (let u_index = 0; u_index < urls.length; u_index++) {
        const u = urls[u_index];
        imgThemeMap[u] = style;
      }
    }
    setGeneratedImgThemes(imgThemeMap);
  };

  const queryGenerateResult = useCallback(async () => {
    const url = `${config.SBT_NODE_SERVICE}/npo/aigc/model/compress`;
    const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
    const data = {
      category: aigcCategory || MINT_CATEGORY.zkAsMatchAigc,
      model_id: modelId,
      address
    };
    const ret = await axios.post<{
      data: GeneratedImgModel[];
    }>(url, data);
    if (ret.status === 200 || ret.status === 201) {
      if (ret.data?.data?.length) {
        const models = ret.data?.data || [];
        if (models.length) {
          setGeneratedImgModels(models);
          handleGeneratedImgThemes(models);
          setGenerateStatus(GenerateStatus.finished);
          return true;
        }
      }
    }
    return false;
  }, [config.SBT_NODE_SERVICE, address, modelId, onGoingTask?.model_id]);

  const value = useMemo(
    () => ({
      generatedImgModels,
      generateStatus,
      queryGenerateResult,
      setGeneratedImgModels,
      setGeneratedImgThemes,
      setGenerateStatus,
      generatedImgThemes,
      handleGeneratedImgThemes
    }),
    [generateStatus, generatedImgModels, queryGenerateResult]
  );

  useEffect(() => {
    const modeId = onGoingTask?.model_id || '';
    setModelId(modeId);
    const models = onGoingTask?.urls || [];
    if (
      models.length &&
      onGoingTask?.status_str !== ProcessingStatus.GENERATING
    ) {
      setGeneratedImgModels(models);
      handleGeneratedImgThemes(models);
    }
  }, [onGoingTask]);

  useEffect(() => {
    let timer: any = null;

    const queryGenereateResultInAnalyzingPage = () => {
      if (
        (currentStep === Step.Generating || currentStep === Step.Generated) &&
        timer === null
      ) {
        timer = setInterval(async () => {
          if (!queryGenerateResultSuccessFlag) {
            const success = await queryGenerateResult();
            if (success) setQueryGenerateResultSuccessFlag(true);
          }
        }, INTERVAL_MILLISECONDS);
      }
    };
    queryGenereateResultInAnalyzingPage();

    return () => clearInterval(timer);
  }, [currentStep, queryGenerateResult]);

  return (
    <GeneratingContext.Provider value={value}>
      {children}
    </GeneratingContext.Provider>
  );
};

export const useGenerating = () => {
  const data = useContext(GeneratingContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useGenerating can only be used inside of <GeneratingContext />, please declare it at a higher level.'
    );
  }
  return data;
};

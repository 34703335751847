import { useMemo } from 'react';

import { BN } from 'bn.js';
import Decimal from 'decimal.js';
import { useAccount, useBalance } from 'wagmi';

import AssetType, { AssetIds } from 'types/AssetType';
import Balance from 'types/Balance';

export const useNativeBalance = () => {
  const { address } = useAccount();
  const { data } = useBalance({
    address,
    watch: true
  });

  const nativePublicBalance = useMemo(() => {
    Decimal.set({ toExpPos: 9e15, precision: 1e9, toExpNeg: -9e15 });
    if (!data?.decimals) {
      return null;
    }
    return new Balance(
      new AssetType(
        AssetIds.OFF_CHAIN,
        'manta pacific',
        'ETH',
        'eth',
        data?.decimals ?? 18,
        new BN('100000000000000000'),
        false,
        '', // coingeckoId useless by now
        false
      ),
      new BN(
        new Decimal(data?.formatted ?? '')
          .mul(Math.pow(10, data?.decimals ?? 18))
          .toString()
      )
    );
  }, [data?.decimals, data?.formatted]);
  return nativePublicBalance;
};

import DotLoader from 'components/Loaders/DotLoader';
import ButtonWithWallet from '../ButtonWithWallet';

const BtnComponent = ({ loading }: { loading: boolean }) => {
  return (
    <>
      Confirm
      {loading && <DotLoader />}
    </>
  );
};
const NoticeCheckModal = ({  confirm }: { confirm: () => void }) => {
  return (
    <div className="text-white text-center">
      <h2 className="text-2xl text-left font-bold">Notice</h2>
      <div className="flex flex-col justify-between pt-4 pb-4 lg:pb-6 text-left">
        <p>
          Are you sure you don't want to choose any pictures? Once you click
          "Confirm", you will not be able go back to this page again.
        </p>
      </div>
      <ButtonWithWallet
        btnComponent={<BtnComponent loading={false} />}
        onClick={confirm}
        className="w-full lg:w-auto px-16 lg:px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter"
      />
    </div>
  );
};

export default NoticeCheckModal;

import { createRef } from 'react';

import classNames from 'classnames';
import Icon from 'components/Icon';
import { useConfig } from 'contexts/configContext';
import PropTypes from 'prop-types';
import styles from './MobileNavs.module.css';
import { NavLink } from 'react-router-dom';
import { NAVLINKPATH } from './NavLinks';

const MenuItem = ({ link, icon }) => (
  <a
    className="flex mx-1 items-center justify-between text-sm font-mono font-medium cursor-pointer hover:font-bold  hover:bg-green-light-half rounded"
    href={link}
    target="_blank"
    rel="noopener noreferrer">
    <Icon
      name={icon}
      className="w-8 h-8 items-center text-xl text-black hover:text-link dark:text-white dark:hover:text-link"
    />
  </a>
);

MenuItem.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.any
};

export const DotMenuContent = () => {
  const config = useConfig();
  const links = {
    DISCORD_URL: 'https://discord.gg/PRDBTChSsF',
    TELEGRAM_URL: 'https://t.me/mantanetworkofficial',
    MEDIUM_URL: 'https://mantanetwork.medium.com/',
    CALAMARI_URL: 'https://npo.manta.network/calamari/sbt',
    CALAMARI_GUIDE_URL: 'https://docs.manta.network/docs/Introduction',
    CALAMARI_BUG_REPORT: 'https://discord.gg/PRDBTChSsF',
    DOLPHIN_GUIDE_URL: 'https://docs.manta.network/docs/Introduction',
    DOLPHIN_BUG_REPORT:
      'https://docs.google.com/forms/d/e/1FAIpQLScAIuDuztZoKHOlpJNC-9FlVdYJpMBLcztQCvT3IQ0FGe7GaA/viewform',
    DOLPHIN_FAUCET_URL:
      'https://docs.manta.network/docs/guides/MantaPay#get-testnet-tokens'
  };
  return (
    <div className="flex flex-row flex-wrap p-4 pb-8">
      <MenuItem
        link={config.PACIFIC_URL}
        name={'Manta Pacific'}
        icon="mantaLogoWhite"
      />
      <MenuItem
        link={links.CALAMARI_URL}
        name={'Calamari'}
        icon="calamariWhite"
      />
      <MenuItem link={config.TWITTER_URL} name={'Twitter'} icon="Twitter" />
      <MenuItem link={links.DISCORD_URL} name={'Discord'} icon="Discord" />
      <MenuItem link={links.TELEGRAM_URL} name={'Telegram'} icon="Telegram" />
      <MenuItem link={links.MEDIUM_URL} name={'Medium'} icon="Medium" />
      <MenuItem link={links.CALAMARI_GUIDE_URL} name={'Docs'} icon="Docs" />
      <MenuItem
        link={links.CALAMARI_BUG_REPORT}
        name={'Bug Report'}
        icon="BugReport"
      />
    </div>
  );
};

export const MobileNavbar = ({
  links,
  float
}: {
  links: { text: string; link: string }[];
  float?: boolean;
}) => {
  const styled = {
    barStyles: {},
    linkStyles: {},
    sidebarStyles: {}
  };
  const cross = createRef<HTMLInputElement>();
  const onOpen = () => {
    if (cross.current) cross.current.style.width = '200px';
  };
  const onClose = () => {
    if (cross.current) cross.current.style.width = '0';
  };
  return (
    <div
      className={classNames(styles.navbar_wrapper)}
      style={{
        ...styled.barStyles,
        position: float ? 'fixed' : undefined
      }}>
      <div className={styles.nav_elements}>
        {links.map(({ text, link }, indx) => {
          return (
            <a href={link} key={indx} style={{ ...styled.linkStyles }}>
              {text}
            </a>
          );
        })}
      </div>
      <div className={styles.mob_nav}>
        <Icon name="mobileMenu" onClick={onOpen} />
        <div className={styles.sidebar_wrapper} ref={cross}>
          <div
            className={classNames('flex flex-col p-4', styles.links_wrapper)}>
            <div className="flex justify-end pt-4 pr-4">
              <Icon name="mobileClose" onClick={onClose} />
            </div>
            <NavLink
              to={`/evm${NAVLINKPATH.NPO}`}
              style={{
                ...styled.linkStyles
              }}>
              NPO
            </NavLink>
            {links.map(({ text, link }, indx) => {
              return (
                <a
                  href={link}
                  key={indx}
                  style={{
                    ...styled.linkStyles
                  }}>
                  {text}
                </a>
              );
            })}
          </div>
          <DotMenuContent />
        </div>
      </div>
    </div>
  );
};

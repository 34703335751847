import classNames from 'classnames';
import ConnectWallet from 'components/Accounts/ConnectWallet';
import Icon from 'components/Icon';
import { useModal, usePublicAddress, useResponsive } from 'hooks';
import { useSbtCommon } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import {
  MINT_CATEGORY,
  VALID_STATUS,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import SbtTokenMintCheckModal from '../SbtTokenMintCheckModal';
import SbtTokenMintedModal from '../SbtTokenMintedModal';
import TokenValidation from '../TokenValidation';
import MintButton from './MintButton';
import { zkTokenMap } from './ProjectConstant';
import { MINT_TYPES } from './type';
import { useSBT } from 'pages/SBTPage/SBTContext';
import CategoryButton from '../CategoryButton';

export interface SbtTokenHorizontalProps {
  tokenType: MINT_TYPES;
}

const SbtTokenHorizontal = () => {
  const { getOnGoingTask } = useSBT();
  const {
    validateToken,
    checkAddressMinted,
    tokenValidStatus,
    tokenType,
    setMintDefault,
    fees
  } = useSbtToken();
  const {
    title,
    contentHorizontal,
    imgUrl,
    imgUrlHorizontal,
    detectedMsg,
    noSbtInEvmAddressMsg,
    logo1,
    logo2,
    shouldShowMintedModal = true,
    isExpired,
    customMintButton,
    category: tokenCategory
  } = zkTokenMap[tokenType] || {};

  const { isDesktop } = useResponsive();
  const { tokenCountList } = useSbtCommon();
  const { address = '', isConnected } = useAccount();
  const category = MINT_CATEGORY[tokenType];
  const publicAddress = usePublicAddress();
  const isZkAsMatchAigc = MINT_CATEGORY.zkAsMatchAigc === category;

  useEffect(() => {
    if (isZkAsMatchAigc && address) {
      getOnGoingTask(); // zkAsMatchAigc onGoingTask status
    }
  }, [isZkAsMatchAigc, address]);

  useEffect(() => {
    if (isConnected && address && publicAddress) {
      validateToken(address, publicAddress, category);
    }
  }, [address, publicAddress, tokenType]);

  const { ModalWrapper, showModal, hideModal } = useModal({
    closeOnBackdropClick: false,
    closeCallback: setMintDefault
  });
  const { ModalWrapper: MintedModalWrapper, showModal: showMintedModal } =
    useModal({
      closeOnBackdropClick: false
    });

  const totalMinted = useMemo(() => {
    let currentCount = 0;
    for (const { token, count } of tokenCountList) {
      if (token === tokenType) {
        currentCount = count;
        break;
      }
    }
    return currentCount;
  }, [tokenType, tokenCountList]);

  return (
    <div
      className={classNames(
        'flex flex-col bg-white bg-opacity-5 rounded-md',
        'lg:flex-row'
      )}>
      <div className="relative">
        <div
          className={classNames(
            'lg:m-auto lg:h-129 lg:w-229 lg:flex lg:flex-col lg:justify-center lg:items-center lg:font-red-hat-mono'
          )}>
          <img src={isDesktop ? imgUrlHorizontal : imgUrl} className="img-bg" />
        </div>
        {shouldShowMintedModal && (
          <div
            className={classNames(
              'left-5 text-white text-opacity-80 mt-4 text-sm absolute bottom-6 select-none',
              'lg:left-32'
            )}>
            Total Minted: {totalMinted}
          </div>
        )}
      </div>
      <div
        className={classNames(
          'ml-5 mr-5 relative pt-4 pl-5 pr-5 pb-6',
          'lg:mr-0 lg:pt-6 lg:pb-0 lg:pr-0'
        )}>
        <div className="flex items-center pb-3.5 text-2xl">
          {title}{' '}
          {logo1 && logo2 && (
            <div className="ml-5 flex items-center text-xss text-white bg-black px-3 py-0.5">
              <Icon name={logo1} className="mr-3" /> x{' '}
              <Icon name={logo2} className="ml-3" />
            </div>
          )}
        </div>
        <CategoryButton category={tokenCategory} />
        {contentHorizontal}
        <Link
          to={`/evm/sbt/projects/${tokenType}`}
          className="flex text-white hover:text-white items-center text-sm mt-2 lg:mt-4">
          Click to know more{' '}
          <Icon
            className="w-4 h-4 cursor-pointer text-white ml-2"
            name="activityRightArrow"
          />
        </Link>
        {customMintButton ? (
          customMintButton()
        ) : (
          <>
            {!isExpired ? (
              <>
                <div className="flex flex-col mt-3.5  mb-4 ">
                  <span className="text-sm">Price:</span>
                  <span className="text-2xl font-semibold">
                    <span className={classNames('text-connect-price')}>
                      {fees?.aigcFee
                        ? `${Number(fees?.aigcFee)}ETH`
                        : 'Free Mint'}
                    </span>
                  </span>
                </div>
                {!address ? (
                  <ConnectWallet
                    isButtonShape={true}
                    className={
                      'bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg'
                    }
                  />
                ) : (
                  <div>
                    <TokenValidation
                      successMsg={detectedMsg}
                      errorMsg={noSbtInEvmAddressMsg}
                    />
                  </div>
                )}
              </>
            ) : (
              <button className="mt-6 w-44 px-4 py-2 leading-5  border border-white border-opacity-50 rounded-md text-white text-opacity-50 cursor-not-allowed">
                Expired
              </button>
            )}
            {tokenValidStatus === VALID_STATUS.success && address && (
              <div className="flex justify-between mt-4 lg:mt-6">
                <MintButton
                  disable
                  showMintModal={showModal}
                  leftToRight={true}
                />
              </div>
            )}
          </>
        )}
      </div>

      <ModalWrapper>
        <SbtTokenMintCheckModal
          hideModal={hideModal}
          showMintedModal={showMintedModal}
          title={title}
        />
      </ModalWrapper>
      <MintedModalWrapper>
        <SbtTokenMintedModal />
      </MintedModalWrapper>
    </div>
  );
};

export default SbtTokenHorizontal;

import { useState, useEffect } from 'react';

import { useSbtToken } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import MintedImg from '../MintedImg';

const SbtTokenMintedModal = () => {
  const [copied, toggleCopied] = useState(false);

  const { proofInfos } = useSbtToken();

  useEffect(() => {
    const timer = setTimeout(() => copied && toggleCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div className="text-white w-204">
      <h2 className="text-2xl">MINTED！</h2>
      <p className="text-white text-opacity-60 text-xs mb-2 mt-6">
        Your zkSBTs should appear in "My NFTs". You can also check all your
        zkSBTs and Proof Keys in "My NFTs".
        <br />
        You can also bond your zkSBTs with other applications or websites
        supporting Proof Keys without connecting wallet.
        <br />
        Enjoy your zkSBTs!
      </p>
      <div className="grid w-full gap-6 grid-cols-4 pb-12 mt-6">
        {proofInfos?.map(({ blur_url, proof_id, asset_id }, index) => {
          return (
            <MintedImg
              blur_url={blur_url}
              proofId={proof_id}
              assetId={`#${asset_id}`}
              style={''}
              url={''}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SbtTokenMintedModal;

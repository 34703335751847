import { ReactNode } from 'react';
import { KeyringContextProvider } from './keyringContext';
import { ThemeProvider } from './themeContext';

const GlobalContexts = ({ children }: { children: ReactNode }) => {
  return (
    <KeyringContextProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </KeyringContextProvider>
  );
};

export default GlobalContexts;

import { useMediaQuery } from 'react-responsive';

export const isServer = typeof window === 'undefined';

export const isMetaMask = !isServer && window?.ethereum?.isMetaMask;

export function useResponsive() {
  if (isServer) {
    return {
      isMobile: false,
      isIPad: false,
      is13Desk: false,
      isLeastWebSize: false,
      isScreenDesk: false
    };
  }

  const isMobile = useMediaQuery({ query: 'screen and (max-width: 767px)' });

  const isTablet = useMediaQuery({ query: 'screen and (max-width: 1023px)' });

  const isDesktop = !isMobile && !isTablet;

  const isLeastWebSize = useMediaQuery({
    query: 'screen and (max-width: 1279px)'
  });

  const is13Desk = useMediaQuery({ query: 'screen and (max-width: 1439px)' });

  const isScreenDesk = useMediaQuery({
    query: 'screen and (max-width: 1920px)'
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
    is13Desk,
    isLeastWebSize,
    isScreenDesk
  };
}

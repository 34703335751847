
import DotLoader from 'components/Loaders/DotLoader';
import ButtonWithWallet from '../ButtonWithWallet';

const BtnComponent = ({ loading }: { loading: boolean }) => {
  return (
    <>
      Confirm
      {loading && <DotLoader />}
    </>
  );
};
const NoticeCheckModal = ({ hideModal }: { hideModal: () => void }) => {

  return (
    <div className="text-white text-center">
      <h2 className="text-2xl text-left font-bold">Notice</h2>
        <div className="flex flex-col justify-between pt-4 pb-4 lg:pb-6 text-left">
          <p>
            Please notice that each NFT Pass can only be used once for the mint.
          </p>
          <p className='py-4'>
            When you click the "Confirm" button, it means you will use the NFT
            Pass and enter the AIGC process. Even if you don't complete the Mint
            process, you will not be able to use this NFT Pass again.
          </p>
          <p>
            The entire process will take 10-20 minutes. We recommend you start
            the mint when you have ample time.
          </p>
      </div>
      <ButtonWithWallet
        btnComponent={<BtnComponent loading={false} />}
        onClick={hideModal}
        className="w-full lg:w-auto px-16 lg:px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter"
      />
    </div>
  );
};

export default NoticeCheckModal;

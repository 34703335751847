// @ts-nocheck
import AppRouter from 'AppRouter';
import GlobalContexts from 'contexts/globalContexts';
import { isDev } from 'index';

function App() {
  if (isDev) window && new window.VConsole();

  return (
    <div className="main-app bg-primary">
      <div className="flex flex-col m-auto">
        <div className="min-h-screen">
          <GlobalContexts>
            <AppRouter />
          </GlobalContexts>
        </div>
      </div>
    </div>
  );
}

export default App;

import classNames from 'classnames';
import Icon from 'components/Icon';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useConnectMetamask } from 'hooks';
import { SbtCommonContext } from 'pages/SBTPage/SBTContext/sbtCommonContext';
import { SbtTokenContextProvider } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import homeBanner from 'resources/images/sbt/home-banner.jpg';
import pompBanner from 'resources/images/sbt/pomp-banner.jpg';
import { useContextSelector } from 'use-context-selector';
import { UpdateVersionNotification } from 'utils/ui/Notifications';
import versionLargerThan from 'utils/versionLarger';
import OnGoingProjects from '../OngoingProjects';
import AboutNPO from './AboutNPO';
import {
  onGoingProjectTypes,
  zkCredentialsProjectTypes,
  zkKYCProjectTypes
} from './ProjectConstant';
import SbtTokenHorizontal from './SbtTokenHorizontal';
import { HomeCountData, MINT_TYPES } from './type';
import { SBTContextProvider } from 'pages/SBTPage/SBTContext';
import OnGoingTaskNotification from '../OnGoingTaskModal';

const Home = () => {
  const homeCountData = useContextSelector(
    SbtCommonContext,
    (v) => v?.homeCountData
  ) as HomeCountData;
  const { today, total, holders } = homeCountData;

  const { MIN_REQUIRED_WALLET_VERSION } = useConfig();
  const params = useParams();
  const paramsProjectType = params.projectType as MINT_TYPES;
  const latestProjectType = onGoingProjectTypes[0];
  const tokenType = paramsProjectType || latestProjectType;
  const link = 'https://manta.network';
  const handleNavigate = useCallback(() => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }, [link]);

  const { mantaWalletVersion } = useMantaWallet();
  const outdated = versionLargerThan(
    MIN_REQUIRED_WALLET_VERSION,
    mantaWalletVersion
  );
  const navigate = useNavigate();

  // auto connect metamask
  useConnectMetamask({ autoConnect: true });

  useEffect(() => {
    if (outdated) UpdateVersionNotification();
  }, [outdated]);

  return (
    <>
      <div
        className="relative h-36 z-10 cursor-pointer"
        onClick={handleNavigate}>
        <img src={homeBanner} className="h-36 w-full" />
        <div className="flex absolute top-0 h-36 w-full">
          <div className="m-auto">
            <div
              className={classNames(
                'font-semibold select-none cursor-pointer text-white text-center text-2xl',
                'lg:text-3xl'
              )}>
              Manta’s NFT Private Offering Platform
            </div>
            <div className="flex flex-row justify-center">
              <div className="font-semibold select-none cursor-pointer text-white text-md text-center">
                Learn More About Manta Network
              </div>
              <Icon name="arrowRightCircle" className="ml-2" />
            </div>
          </div>
        </div>
      </div>

      <SbtTokenContextProvider propTokenType={tokenType}>
        <SbtTokenHorizontal />
        <OnGoingTaskNotification navigate={navigate} />
      </SbtTokenContextProvider>

      <div className="flex justify-around my-12 text-center">
        <div>
          <p
            className={classNames(
              'mb-2 text-xl text-gridient font-semibold',
              'lg:text-4xl '
            )}>
            {today}
          </p>
          <p
            className={classNames(
              'text-white text-xss font-semibold',
              'lg:text-base '
            )}>
            Newly minted today
          </p>
        </div>
        <div>
          <p
            className={classNames(
              'mb-2 text-xl text-gridient font-semibold',
              'lg:text-4xl '
            )}>
            {total}
          </p>
          <p
            className={classNames(
              'text-white text-xss font-semibold',
              'lg:text-base '
            )}>
            Total zkNFT minted
          </p>
        </div>
        <div>
          <p
            className={classNames(
              'mb-2 text-xl text-gridient font-semibold',
              'lg:text-4xl '
            )}>
            {holders}
          </p>
          <p
            className={classNames(
              'text-white text-xss font-semibold',
              'lg:text-base '
            )}>
            Activated Addresses
          </p>
        </div>
      </div>
      <div
        className="cursor-pointer"
        onClick={() => {
          window.open('https://pomp.money/', '_blank', 'noopener,noreferrer');
        }}>
        <img src={pompBanner} className="w-full" />
      </div>

      <div className={classNames('mt-12 flex px-8', 'lg:px-32')}>
        <OnGoingProjects title="zkKYC" projectList={zkKYCProjectTypes} />
      </div>

      <div
        className={classNames(
          'mt-6 flex flex-col items-center mx-auto px-6 pb-8',
          'lg:mt-12 lg:px-32'
        )}>
        <OnGoingProjects
          title="zkCredentials"
          projectList={zkCredentialsProjectTypes}
          gridClassName={classNames('grid-cols-2 gap-4', 'lg:grid-cols-4')}
          className="px-2 lg:px-0"
        />
        <AboutNPO />
      </div>
    </>
  );
};

export default Home;

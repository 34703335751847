import { getNetwork, switchNetwork } from '@wagmi/core';
import BN from 'bn.js';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAccount } from 'wagmi';

import { HexString } from '@polkadot/util/types';
import axios from 'axios';
import { useConfig } from 'contexts/configContext';
import { PostType } from 'contexts/mantaWalletType';
import { useSubstrate } from 'contexts/substrateContext';
import { usePublicAddress } from 'hooks';
import { useParams } from 'react-router-dom';
import Balance from 'types/Balance';
import { showInfo } from 'utils/ui/Notifications';
import { useSBT } from '.';
import { onGoingProjectTypes } from '../components/Home/ProjectConstant';
import { MINT_TYPES } from '../components/Home/type';
import { MINT_ID_KEY, ProcessingStatus, mintResponseCode } from '../const';
import { useSbtCommon } from './sbtCommonContext';

type ProofInfo = {
  transaction_data: {
    identifier: any;
    asset_info: any;
    zk_address: { receiving_key: Array<any> };
  };
  proof_id: HexString;
  blur_url: string;
  asset_id: string;
};

export const DEFAULT_ATTRIBUTE = '';

export enum MINT_CATEGORY {
  zkBAB = 1,
  zkGalxe = 2,
  zkCelebratory = 106,
  zkAsMatchAigc = 107,
  zkOmni = 108
}

export type HasNFTRes = {
  status: boolean;
  count: boolean;
};

export enum VALID_STATUS {
  default,
  networkError,
  noSbtInEvmAddress = 'zero-balance-observed',
  duplicate = 'prior-allow-observed',
  success = 'allow-success',
  fail = 'allow-fail',
  notEligible = 'Not Eligible'
}

export enum MINT_STATUS_CODE {
  success = 0,
  notAllow = -1,
  alreadyMinted = -2,
  minting = -7,
  failed = -22,
  duplicated = -23
}

export enum FAUCET_BUTTON_STATUS {
  default,
  disable = 'dripped',
  able = 'non-dripped'
}

export enum FAUCET_STATUS {
  default,
  error,
  success = 'drip-success'
}

type Fee = {
  aigcFee: string;
  discount: string;
  actualFeeDecimal: string;
  actualFeeBalance: string;
};

type SbtTokenContextValue = {
  tokenType: MINT_TYPES;
  tokenValidStatus: VALID_STATUS;
  validateToken: (
    address: string,
    publicAddress: string,
    category?: MINT_CATEGORY,
    force?: boolean
  ) => void;
  checkAddressMinted: (
    ethAddress: string,
    publicAddress: string,
    category: MINT_CATEGORY
  ) => void;
  isMinted: boolean;
  gasFee: Balance | null;
  getMintGasFee: (options: {
    signature: string;
    signedChainId: string;
  }) => void;
  proofInfos: ProofInfo[] | null;
  saveMintData: () => void;
  mintErrMsg: string;
  mintStatusMsg: string;
  category: MINT_CATEGORY;
  setMintDefault: () => void;
  setMinting: () => void;
  setMinted: () => void;
  fees: Fee | null;
  nftPass: string;
  switchToPacific: () => void;
};

enum paramsKey {
  zkBAB = 'bab',
  zkGalxe = 'galxe'
}
enum MINT_ID_MAP {
  zkBAB = 1,
  zkGalxe,
  zkAsMatchAigc = 107
}

const SbtTokenContext = createContext<SbtTokenContextValue | null>(null);

export enum MintStatus {
  INIT = 0, // 0:Not Sending to blockchain yet. will be scheduled to send to blockchain.
  SENDING, // 1:Sending tx to blokchain
  OK, // 2:execute success
  FAILED, // 3:execute failed
  ERROR_CAN_RETRY // 4:send failed, will automatically resend
  // PROCESSING,
}

export const enum MintStatusText {
  MINT = 'Mint',
  MINTING = 'Minting',
  MINTED = 'Minted',
  GENERATING = 'Generating',
  GENERATED = 'Generated'
}

export const SbtTokenContextProvider = ({
  children,
  propTokenType
}: {
  children: ReactNode;
  propTokenType?: MINT_TYPES;
}) => {
  const { onGoingTask, setCurrentStep } = useSBT();
  const [tokenValidStatus, setTokenValidStatus] = useState<VALID_STATUS>(
    VALID_STATUS.default
  );
  const [isMinted, toggleIsMinted] = useState<boolean>(true);
  const [mintStatusMsg, setMintStatusMsg] = useState<string>('');
  const validateTokenLoadingRef = useRef<boolean>(false);
  const validateTokenLoading = validateTokenLoadingRef.current;
  const [assetId, setAssetId] = useState<string>('');
  const [gasFee, setGasFee] = useState<Balance | null>(null);
  const [fees, setFees] = useState<Fee | null>(null);
  const [nftPass, setNftPass] = useState('');
  const [proofInfos, setProofInfos] = useState<ProofInfo[] | null>(null);
  const [post, setPost] = useState<PostType | null>(null);
  const [sbtTokenId, setSbtTokenId] = useState<string>('');
  const [mintErrMsg, setMintErrMsg] = useState('');
  const publicAddress = usePublicAddress();

  const ethAddressRef = useRef<string | undefined>('');

  const { api } = useSubstrate();
  const { NETWORK_NAME, IS_TESTNET, SBT_NODE_SERVICE, EVM_CHAIN } = useConfig();
  const externalAccount = useAccount();
  const { address } = useAccount();
  const ethAddress = address?.toLowerCase() ?? '';
  const { getTokenCountList } = useSbtCommon();
  const params = useParams();
  const paramsProjectType = params.projectType as MINT_TYPES;
  const latestProjectType = onGoingProjectTypes[0];
  const tokenType = propTokenType || paramsProjectType || latestProjectType;
  const category = MINT_CATEGORY[tokenType];
  const [validateShortlistOnce, setValidateShortlist] = useState<
    Record<string, boolean>
  >({});

  const { chain } = getNetwork();

  const setMintDefault = useCallback(() => {
    setMintStatusMsg(MintStatusText.MINT);
    toggleIsMinted(false);
  }, [isMinted]);

  const setMinting = useCallback(() => {
    setMintStatusMsg(MintStatusText.MINTING);
    toggleIsMinted(false);
  }, []);

  const setMinted = useCallback(() => {
    setMintStatusMsg(MintStatusText.MINTED);
    toggleIsMinted(true);
  }, []);

  const checkAddressMinted = useCallback(
    async (
      ethAddress: string,
      publicAddress: string,
      category: MINT_CATEGORY
    ) => {
      const isZkAsmatchAiGc = category === MINT_CATEGORY.zkAsMatchAigc;
      const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
      const url = isZkAsmatchAiGc
        ? `${SBT_NODE_SERVICE}/npo/aigc/mintStatus`
        : `${SBT_NODE_SERVICE}/npo/eth/mintStatus`;
      const data = {
        publicAddress,
        address: ethAddress,
        category: isZkAsmatchAiGc ? String(aigcCategory || category) : String(category),
        attribute: DEFAULT_ATTRIBUTE
      };
      try {
        const ret = await axios.post<{ code: number; message: string }>(
          url,
          data
        );
        const code = ret?.data?.code;

        if (isZkAsmatchAiGc) {
          // GENERATING & GENERATED is only for zkAsMatchAigc
          if (onGoingTask?.status_str === ProcessingStatus.GENERATING) {
            setMintStatusMsg(MintStatusText.GENERATING);
            toggleIsMinted(false);
            return false;
          }

          if (
            onGoingTask?.status_str === ProcessingStatus.PREPAREMINTED ||
            onGoingTask?.status_str === ProcessingStatus.GENERATED
          ) {
            setMintStatusMsg(MintStatusText.GENERATED);
            toggleIsMinted(false);
            return false;
          }
        }

        const isAlreadyMinted =
          code === mintResponseCode.ADDRESS_IS_MINTED.code ||
          code === mintResponseCode.ADDRESS_IS_MINTED_ETH.code;

        const isMinting = code === mintResponseCode.ADDRESS_IS_MINTING.code;

        if (isMinting || code === mintResponseCode.MINT_NOT_FOUND.code) {
          setMintStatusMsg(MintStatusText.MINT);
          toggleIsMinted(false);
          return false;
        }

        if (isAlreadyMinted) {
          setMintStatusMsg(MintStatusText.MINTED);
          toggleIsMinted(true);
          return true;
        } else {
          toggleIsMinted(false);
          if (code !== mintResponseCode.OK.code) {
            showInfo(ret?.data?.message);
          }
          return false;
        }
      } catch (error) {
        showInfo('checkAddressMinted error: ' + error);
      }
    },
    [SBT_NODE_SERVICE, onGoingTask]
  );

  useEffect(() => {
    // recheck mint status when onGoingTask changed
    if (SBT_NODE_SERVICE && onGoingTask) {
      checkAddressMinted(
        address || '',
        publicAddress,
        MINT_CATEGORY[tokenType]
      );
    }
  }, [SBT_NODE_SERVICE, onGoingTask?.urls?.length]);

  const resetData = useCallback(() => {
    setPost(null);
    setProofInfos(null);
    setGasFee(null);
  }, []);

  const switchToPacific = useCallback(async () => {
    if (chain?.id !== EVM_CHAIN.id) {
      await switchNetwork({ chainId: EVM_CHAIN.id });
    }
  }, [chain, EVM_CHAIN]);

  const validateToken = useCallback(
    async (address, publicAddress, _category, force = false) => {
      await switchToPacific();
      ethAddressRef.current = ethAddress;
      const validateShortlistOnceKey = `${ethAddressRef.current}-${tokenType}`;
      const validateShortlistOnceValue =
        validateShortlistOnce[validateShortlistOnceKey];
      if (!force) {
        if (
          validateTokenLoading ||
          validateShortlistOnceValue ||
          !ethAddressRef.current
        ) {
          return;
        }
      }
      resetData();
      const category = _category || MINT_CATEGORY[tokenType];
      const isZkAsmatchAiGc = category === MINT_CATEGORY.zkAsMatchAigc;
      const url = isZkAsmatchAiGc
        ? `${SBT_NODE_SERVICE}/npo/aigc/eligible`
        : `${SBT_NODE_SERVICE}/npo/eth/eligible`;
      validateTokenLoadingRef.current = true;
      const data = { publicAddress, address, category: String(category) };
      try {
        setTokenValidStatus(VALID_STATUS.default);
        const ret = await axios.post<{
          status: VALID_STATUS;
          code: any;
          token: string;
          message: string;
          data?: any;
        }>(url, data);
        if (ret.status === 200 || ret.status === 201) {
          const { code } = ret.data ?? {};
          if (isZkAsmatchAiGc) {
            const { tokenId: nftPass, mintId, fees } = ret.data?.data || {};
            setNftPass(nftPass);
            setFees(fees);
            sessionStorage.setItem(MINT_ID_KEY, mintId);
          }
          if (!isZkAsmatchAiGc && code === mintResponseCode.INELIGIBLE.code) {
            setTokenValidStatus(VALID_STATUS.notEligible);
          } else {
            // mintButton status judge by api /mintStatus
            setTokenValidStatus(VALID_STATUS.success);
          }
        }
        setValidateShortlist({ [validateShortlistOnceKey]: true });
        checkAddressMinted(ethAddressRef.current, publicAddress, category);
        validateTokenLoadingRef.current = false;
      } catch (e) {
        setTokenValidStatus(VALID_STATUS.networkError);
        console.log('valid evm address error: ', e);
        validateTokenLoadingRef.current = false;
      }
    },
    [
      SBT_NODE_SERVICE,
      checkAddressMinted,
      ethAddress,
      resetData,
      tokenType,
      validateShortlistOnce,
      publicAddress
    ]
  );

  const getMintTx = useCallback(
    ({
      signature,
      signedChainId
    }: {
      signature: string;
      signedChainId: string;
    }) => {
      if (!ethAddress || !signature || !post || !signedChainId) {
        return;
      }

      return api?.tx.mantaSbt.mintSbtEth(
        post,
        signedChainId,
        signature,
        MINT_ID_MAP[tokenType],
        null,
        null,
        sbtTokenId
      );
    },
    [ethAddress, post, tokenType, api?.tx.mantaSbt, sbtTokenId]
  );

  const getMintGasFee = useCallback(
    async ({
      signature,
      signedChainId
    }: {
      signature: string;
      signedChainId: string;
    }) => {
      const tx = getMintTx({
        signature,
        signedChainId
      });
      if (tx) {
        const paymentInfo = await tx?.paymentInfo(
          externalAccount?.address ?? ''
        );
        setGasFee(
          Balance.Native(
            { IS_TESTNET, NETWORK_NAME },
            new BN(paymentInfo?.partialFee.toString() ?? '')
          )
        );
      }
    },
    [IS_TESTNET, NETWORK_NAME, externalAccount?.address, getMintTx]
  );

  const saveMintData = useCallback(async () => {
    checkAddressMinted(ethAddress, publicAddress, category);
    getTokenCountList();
  }, [checkAddressMinted, ethAddress, tokenType, getTokenCountList]);

  const value = useMemo(
    () => ({
      tokenValidStatus,
      validateToken,
      checkAddressMinted,
      isMinted,
      gasFee,
      getMintGasFee,
      saveMintData,
      proofInfos,
      mintErrMsg,
      mintStatusMsg,
      setMintDefault,
      setMinting,
      setMinted,
      tokenType,
      category,
      fees,
      nftPass,
      switchToPacific
    }),
    [
      tokenValidStatus,
      validateToken,
      checkAddressMinted,
      isMinted,
      gasFee,
      getMintGasFee,
      saveMintData,
      proofInfos,
      mintErrMsg,
      mintStatusMsg,
      setMintDefault,
      setMinting,
      setMinted,
      tokenType,
      category,
      fees,
      nftPass,
      switchToPacific
    ]
  );
  return (
    <SbtTokenContext.Provider value={value}>
      {children}
    </SbtTokenContext.Provider>
  );
};

export const useSbtToken = () => {
  const data = useContext(SbtTokenContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSbtToken can only be used inside of <SbtTokenContext />, please declare it at a higher level.'
    );
  }
  return data;
};

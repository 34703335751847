import classNames from 'classnames';
import Icon from 'components/Icon';

const AsmatchBanner = () => {
  return (
    <div
      onClick={() => {
        window.open(
          'https://www.asmatch.app/',
          '_blank',
          'noopener,noreferrer'
        );
      }}
      className={classNames('flex justify-center w-full h-20 cursor-pointer bg-asmatch')}>
      <div className="flex items-center lg:w-75 px-6">
        <Icon name="asMatch"  className='w-10 h-10'/>
        <div className='ml-5 text-xl'>AsMatch AIGC zkPortrait</div>
      </div>
    </div>
  );
};

export default AsmatchBanner;

import Identicon from '@polkadot/react-identicon';
import CopyPasteIcon from 'components/CopyPasteIcon';
import { usePublicAddress } from 'hooks';
import getAbbreviatedName from 'utils/display/getAbbreviatedName';
import { useAccount } from 'wagmi';

const SingleAccountDisplay = ({
  accountName,
  accountAddress,
  zkAddress
}: {
  accountName: string;
  accountAddress: string;
  zkAddress: string;
}) => {
  const succinctAddress = getAbbreviatedName(accountAddress, 5, 5);
  const newZkAddress = getAbbreviatedName(zkAddress, 5, 5);

  const succinctAccountName =
    accountName.length > 12 ? `${accountName?.slice(0, 12)}...` : accountName;

  return (
    <div key={accountAddress} className="text-white text-sm">
      <div className="flex mb-4">
        <Identicon
          value={accountAddress}
          size={24}
          theme="polkadot"
          className="px-1 mr-2"
        />
        <div className="text-base">{succinctAccountName}</div>
      </div>
      <div className="bg-white bg-opacity-5 border border-white-light rounded-lg p-4 mb-4">
        <p>zkAddress</p>
        <div className="font-red-hat-mono text-sm flex flex-row items-center gap-2 text-white text-opacity-60 text-sm">
          {newZkAddress}
          <CopyPasteIcon iconClassName="w-5 h-5" textToCopy={zkAddress} />
        </div>
      </div>
      <div className="bg-white bg-opacity-5 border border-white-light rounded-lg p-4">
        <p>Public Address</p>
        <div className="font-red-hat-mono text-sm flex flex-row items-center gap-2 text-white text-opacity-60 text-sm">
          {succinctAddress}
          <CopyPasteIcon iconClassName="w-5 h-5" textToCopy={accountAddress} />
        </div>
      </div>
    </div>
  );
};

const AccountSelectDropdown = () => {
  const { address } = useAccount();
  const publicAddress = usePublicAddress();

  return (
    <div className="flex flex-col gap-5">
      <SingleAccountDisplay
        key={address}
        accountName={publicAddress as string}
        accountAddress={publicAddress}
        zkAddress={address as string}
      />
    </div>
  );
};

export default AccountSelectDropdown;

import '@polkadot/types-augment';
import * as Sentry from '@sentry/react';
import 'element-theme-default';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './styles/index.scss';

export const isDev =
  !process?.env?.REACT_APP_ENV ||
  process?.env?.REACT_APP_ENV === 'development' ||
  process?.env?.REACT_APP_ENV === 'staging';

Sentry.init({
  environment: process?.env?.REACT_APP_ENV,
  dsn: "https://740ffac43d681cdde1e75431af271f99@sentry.ops.p0xlabs.systems/37",
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [
        'https://prod.asmatch-api-npo.asmatch.xyz/npo/aigc/files',
        'https://prod.asmatch-api-npo.asmatch.xyz/npo/eth/getProofKeyTags',
        'https://prod.asmatch-api-npo.asmatch.xyz/npo/aigc/servicePayment'
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header']
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: isDev ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

import CopyPasteIcon from 'components/CopyPasteIcon';
import Icon from 'components/Icon';
import { Tooltip } from 'element-react';
import {
  PROOF_STATUS_TYPE,
  Project
} from 'pages/SBTPage/SBTContext/mintedListContext';
import { TAG_ORIGINAL_NAME } from 'pages/SBTPage/const';
import { useState } from 'react';
import getAbbreviatedName from 'utils/display/getAbbreviatedName';
import QRCode from 'react-qr-code';
import classNames from 'classnames';
import { MINT_CATEGORY } from 'pages/SBTPage/SBTContext/sbtTokenContext';

const ProofKeyModalContent = ({
  project,
  token_type,
  url,
  handleBack,
  handleDelete,
  handleEdit
}: {
  project: Project;
  token_type: MINT_CATEGORY | string;
  url: string;
  handleBack: () => void;
  handleDelete: (tag?: string) => void;
  handleEdit: (tag: string) => void;
}) => {
  const {
    assetId,
    project: project_name,
    proofKey = '',
    status
  } = project || {};

  const [edit, setEdit] = useState(false);
  const [tag, setTag] = useState('');
  const [showQrCode, toggleQrCode] = useState(false);
  const isOriginal = project_name.toLowerCase() === TAG_ORIGINAL_NAME;

  const updateEdit = async () => {
    if (!tag) {
      setEdit(false);
      return;
    }
    await handleEdit(tag);
    setEdit(false);
  };

  const displayTokenType =
    token_type === MINT_CATEGORY.zkAsMatchAigc ? 'zkPortrait' : token_type;

  return (
    <>
      <span className="absolute z-10 top-5 left-6 font-semibold leading-6 text-white text-xl">
        {displayTokenType} Details
      </span>
      <div className="flex flex-col p-6">
        <div className="mt-8 flex text-white">
          <div
            className={classNames(
              'mt-6 p-2 flex flex-col items-center bg-white bg-opacity-5 rounded-xl',
              'lg:flex-row lg:p-4'
            )}>
            <div className="relative flex items-center">
              <img
                title={url}
                className={classNames(
                  'rounded-lg w-48 h-48',
                  'lg:w-32 lg:h-32'
                )}
                src={url}
              />
              <div className="absolute bottom-0">
                {!showQrCode && (
                  <Icon
                    name="qrCode"
                    onClick={() => toggleQrCode(true)}
                    className="cursor-pointer"
                  />
                )}
              </div>
              <div>
                {showQrCode && (
                  <div
                    onClick={() => toggleQrCode(false)}
                    className="absolute flex justify-center w-full h-full top-0 left-0 cursor-pointer bg-white rounded-lg">
                    <QRCode value={proofKey || ''} size={100} />
                  </div>
                )}
              </div>
            </div>
            <div className={classNames('ml-2 flex flex-col w-64', 'lg:ml-8')}>
              <div className="flex items-center justify-between pt-3.5 pb-2 border-b border-opacity-10">
                <div className="text-sm text-white text-opacity-60 flex-shrink-0">
                  Tag Name
                </div>
                {!edit ? (
                  <div className="flex flex-row">
                    <div className="font-red-hat-mono text-sm font-normal leading-8 text-white text-opacity-70">
                      {project_name}
                    </div>
                    {!isOriginal && (
                      <Icon
                        onClick={() => setEdit(true)}
                        className="ml-2 cursor-pointer"
                        name="edit"
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-row">
                    <input
                      className="w-32 font-red-hat-mono font-normal leading-8 text-opacity-100 bg-transparent border-none outline-none flex-shrink placeholder-white text-sm"
                      placeholder={project_name}
                      onChange={(e) => setTag(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') updateEdit();
                      }}
                    />
                    {!isOriginal && (
                      <Icon
                        onClick={() => updateEdit()}
                        className="cursor-pointer"
                        name="editSelect"
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between py-2 border-b border-opacity-10">
                <div className="text-sm text-white text-opacity-60">Id#</div>
                <div className="flex flex-row">
                  <div className="font-red-hat-mono leading-8 text-sm font-normal text-white text-opacity-70 ">
                    {assetId}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between py-2 border-b border-opacity-10">
                <div className="text-sm text-white text-opacity-60">
                  Proof Key
                </div>
                <div className="flex flex-row">
                  <div className="font-red-hat-mono leading-8 text-sm font-normal text-white text-opacity-70 ">
                    {getAbbreviatedName(proofKey, 5, 5)}
                  </div>
                  <CopyPasteIcon
                    btnClassName="ml-2 rounded-lg cursor-pointer"
                    iconClassName="w-8 h-8"
                    textToCopy={proofKey}
                    selectedIcon={<Icon name="editSelect" />}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between py-2">
                <div className="flex flex-row items-center">
                  <div className="text-sm text-white text-opacity-60">
                    Status
                  </div>
                  <Tooltip
                    className="item ml-2"
                    effect="dark"
                    content="Please note each Proof key can only been bonded with one account"
                    placement="right-end">
                    <Icon name="information" />
                  </Tooltip>
                </div>
                {status === PROOF_STATUS_TYPE.VACANT ? (
                  <div className="flex flex-col text-right font-red-hat-mono font-normal">
                    <div className="font-red-hat-mono text-checked text-sm">
                      {status}
                    </div>
                    <div className="font-red-hat-mono text-checked text-opacity-60 text-xss">
                      Available to be bonded
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col text-right font-red-hat-mono font-normal">
                    <div className="font-red-hat-mono text-white text-sm">
                      {status}
                    </div>
                    <div className="font-red-hat-mono text-white text-opacity-60 text-xs">
                      Bonded with {displayTokenType}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => handleDelete(tag)}
          className="mr-4 mt-6 h-10 w-full bg-error bg-opacity-20 border border-error text-error font-red-hat-text text-sm cursor-pointer rounded-lg">
          Delete Proof Key
        </button>
        <button
          onClick={() => handleBack()}
          className="mr-4 mt-4 h-10 w-full text-white border border-white font-red-hat-text text-sm cursor-pointer rounded-lg">
          Back
        </button>
      </div>
    </>
  );
};

export default ProofKeyModalContent;

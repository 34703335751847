import BN from 'bn.js';
import { ConfigType } from 'config';
import NETWORK from 'constants/NetworkConstants';
import Chain from './Chain';

const CalamariAssetIds = {
  KMA: 1,
  KAR: 8,
  AUSD: 9,
  LKSM: 10,
  MOVR: 11,
  KSM: 12,
  BNB: 14 // Fake asset id, it's just to initialize an BNB Asset
};

const DolphinAssetIds = {
  DOL: 1,
  KAR: 8,
  AUSD: 9,
  LKSM: 10,
  MOVR: 11,
  KSM: 12,
  DOT: 13 // Asset id is not correct, it's just to initialize an Dot Asset
};

const MantaAssetIds = {
  MANTA: 1,
  BNB: 21,
};

const EthereumAssetIds = {
  ETH: 27,
  BNB: 21,
};

export const getAssetIds = (config: Pick<ConfigType, 'NETWORK_NAME'>) => {
  switch (config.NETWORK_NAME) {
    case NETWORK.DOLPHIN:
      return DolphinAssetIds;
    case NETWORK.CALAMARI:
      return CalamariAssetIds;
    case NETWORK.MANTA:
      return MantaAssetIds;
    default:
      return EthereumAssetIds;
  }
};

export const AssetIds = {
  MANTA: 0,
  KMA: 1,
  BNB: 2, // mock data
  ETH: 3, // mock data
  ETH_USDT: 5, // mock data
  ETH_USDC: 6, // mock data
  ETH_DAI: 7, // mock data
  ETH_HARRY: 18, // mock data
  ETH_LDO: 19, // mock data
  ETH_MKR: 20, // mock data
  ETH_UNI: 21, // mock data
  ETH_RETH: 22, // mock data
  ETH_StETH: 23, // mock data
  ETH_WstETH: 24, // mock data
  BNB_ETH: 8, // mock data
  BNB_USDT: 9, // mock data
  BNB_USDC: 10, // mock data
  BNB_BUSD: 11, // mock data
  BNB_DAI: 12, // mock data,
  BNB_CAKE: 25, // mock data,
  BNB_XVS: 26, // mock data,
  BNB_BIFI: 27, // mock data,
  BNB_DODO: 28, // mock data,
  BNB_CHEEL: 33, // mock data,
  BNB_MDAO: 34, // mock data,
  OP: 13, // mock data,
  OP_ETH: 14, // mock data,
  OP_USDT: 15, // mock data,
  OP_USDC: 16, // mock data,
  OP_DAI: 17, // mock data,
  ARB: 29,
  ARB_USDT: 30,
  ARB_USDC: 31,
  ARB_DAI: 32,
  OFF_CHAIN: 35
};

export default class AssetType {
  assetId: number;
  baseName: string;
  name: string;
  baseTicker: string;
  ticker: string;
  logicalTicker: string;
  icon: string;
  numberOfDecimals: number;
  publicExistentialDeposit: BN;
  existentialDeposit: BN;
  isPrivate: boolean;
  isTestnet: boolean;
  isNativeToken: boolean;
  coingeckoId: string;

  constructor(
    assetId: number,
    baseName: string,
    baseTicker: string,
    icon: string,
    numberOfDecimals: number,
    publicExistentialDeposit: BN,
    isPrivate: boolean,
    coingeckoId: string,
    isTestnet: boolean,
    isNativeToken = false,
    logicalTicker: string | null = null
  ) {
    this.assetId = assetId;
    this.baseName = baseName;
    this.baseTicker = baseTicker;
    this.logicalTicker = logicalTicker || baseTicker;
    this.name = AssetType._getFullName(baseName, isPrivate, isTestnet);
    this.ticker = AssetType._getFullTicker(baseTicker, isPrivate);
    this.icon = icon;
    this.numberOfDecimals = numberOfDecimals;
    this.publicExistentialDeposit = publicExistentialDeposit;
    this.existentialDeposit = isPrivate ? new BN(0) : publicExistentialDeposit;
    this.isPrivate = isPrivate;
    this.isTestnet = isTestnet;
    this.isNativeToken = isNativeToken;
    this.coingeckoId = coingeckoId;
  }

  static Native(config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>) {
    switch (config.NETWORK_NAME) {
      case NETWORK.DOLPHIN:
        return AssetType.DolphinSkinnedCalamari(config, false);
      case NETWORK.CALAMARI:
        return AssetType.Ethereum(config, false);
      case NETWORK.MANTA:
        return AssetType.Manta(config, false);
      default:
        return AssetType.Ethereum(config, false);
    }
  }

  static DolphinSkinnedCalamari(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.DOL,
      'Dolphin',
      'DOL',
      'dolphin',
      12,
      new BN('100000000000'),
      isPrivate,
      'dolphin',
      config?.IS_TESTNET,
      true,
      'KMA'
    );
  }

  static Calamari(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.KMA,
      'Calamari',
      'KMA',
      'calamari',
      12,
      new BN('100000000000'),
      isPrivate,
      'calamari-network',
      config.IS_TESTNET,
      true
    );
  }

  static Manta(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.MANTA,
      'Manta',
      'MANTA',
      'manta',
      18,
      new BN('100000000000000000'),
      isPrivate,
      'manta-network',
      config.IS_TESTNET,
      true
    );
  }

  static Karura(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.KAR,
      'Karura',
      'KAR',
      'kar',
      12,
      new BN('100000000000'),
      isPrivate,
      'karura',
      config.IS_TESTNET
    );
  }
  static Kusama(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.KSM,
      'Kusama',
      'KSM',
      'kusama',
      12,
      new BN('500000000'),
      isPrivate,
      'kusama',
      config.IS_TESTNET
    );
  }

  static Rococo(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.ROC,
      'Rococo',
      'ROC',
      'roc',
      12,
      new BN('1'),
      isPrivate,
      'rococo',
      config.IS_TESTNET
    );
  }

  static KintsugiBTC(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.KBTC,
      'Kintsugi BTC',
      'kBTC',
      'kbtc',
      8,
      new BN('1'),
      isPrivate,
      'bitcoin',
      config.IS_TESTNET
    );
  }

  static Moonriver(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate: boolean
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.MOVR,
      'Moonriver',
      'MOVR',
      'movr',
      18,
      new BN('100000000000000000'),
      isPrivate,
      'moonriver',
      config.IS_TESTNET
    );
  }

  static BNB(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate = false
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.BNB,
      'bnb',
      'BNB',
      'bnb',
      18,
      new BN('100000000000000000'),
      isPrivate,
      'bnb',
      config.IS_TESTNET
    );
  }

  static Dot(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate = false
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.DOT,
      'Polkadot',
      'DOT',
      'dot',
      10,
      new BN('100000000'),
      isPrivate,
      'polkadot',
      true
    );
  }

  static Ethereum(
    config: Pick<ConfigType, 'IS_TESTNET' | 'NETWORK_NAME'>,
    isPrivate = false
  ) {
    return new AssetType(
      (getAssetIds(config) as any)?.ETH,
      'Ethereum',
      'ETH',
      'eth',
      18,
      new BN('100000000000000000'),
      false,
      'ethereum',
      false
    );
  }

  static Pacific() {
    return new AssetType(
      AssetIds.OFF_CHAIN,
      'manta pacific',
      'ETH',
      'eth',
      18,
      new BN('100000000000000000'),
      false,
      '', // coingeckoId useless by now
      false
    );
  }

  static AllCurrencies(
    config: Pick<ConfigType, 'NETWORK_NAME' | 'IS_TESTNET'>,
    isPrivate: boolean
  ) {
    switch (config.NETWORK_NAME) {
      case NETWORK.DOLPHIN:
        return [
          AssetType.DolphinSkinnedCalamari(config, isPrivate),
          AssetType.Karura(config, isPrivate),
          AssetType.Kusama(config, isPrivate),
          AssetType.Moonriver(config, isPrivate),
          AssetType.Dot(config, isPrivate)
        ];
      case NETWORK.CALAMARI:
        return [
          AssetType.Calamari(config, isPrivate),
          // AssetType.Karura(config, isPrivate),
          AssetType.Kusama(config, isPrivate),
          AssetType.Moonriver(config, isPrivate),
        ];
      default:
        return [AssetType.Ethereum(config, isPrivate)];
    }
  }

  static _getFullName(
    baseName: string,
    isPrivate: boolean,
    isTestnet: boolean
  ) {
    let name = isTestnet ? 'Test ' : '';
    if (isPrivate) {
      name += 'zk';
    }
    return name + baseName;
  }

  static _getFullTicker(baseTicker: string, isPrivate: boolean) {
    return isPrivate ? `zk${baseTicker}` : baseTicker;
  }

  toPrivate() {
    return new AssetType(
      this.assetId,
      this.baseName,
      this.baseTicker,
      this.icon,
      this.numberOfDecimals,
      this.publicExistentialDeposit,
      true,
      this.coingeckoId,
      this.isTestnet,
      this.isNativeToken,
      this.logicalTicker
    );
  }

  toPublic() {
    return new AssetType(
      this.assetId,
      this.baseName,
      this.baseTicker,
      this.icon,
      this.numberOfDecimals,
      this.publicExistentialDeposit,
      false,
      this.coingeckoId,
      this.isTestnet,
      this.isNativeToken,
      this.logicalTicker
    );
  }

  toggleIsPrivate() {
    if (this.isPrivate) {
      return this.toPublic();
    } else {
      return this.toPrivate();
    }
  }

  canTransferXcm = (originChain: Chain, destinationChain: Chain) => {
    return (
      originChain.xcmAssets.find(
        (asset: AssetType) => asset.name === this.name
      ) &&
      destinationChain.xcmAssets.find(
        (asset: AssetType) => asset.name === this.name
      )
    );
  };
}

export enum NETWORK {
  ETHEREUM = 'Ethereum',
  CALAMARI = 'Calamari',
  DOLPHIN = 'Dolphin',
  MANTA = 'Manta'
}

export enum SS58 {
  DOLPHIN = 42,
  CALAMARI = 78,
  MANTA = 77,
}

export default NETWORK;

import { useMemo } from 'react';

import classNames from 'classnames';
import Icon from 'components/Icon';
import { useSBT } from 'pages/SBTPage/SBTContext';
import { useAccount } from 'wagmi';
import Progress from '../Progress';
import ButtonWithWallet from '../ButtonWithWallet';
import { useResponsive } from 'hooks';

const UploadedImg = ({ url }: { url: string }) => {
  return <img src={url} className={'w-24 h-24 rounded-lg'} />;
};

const Generating = () => {
  const { isDesktop } = useResponsive();
  const { imgList } = useSBT();

  const externalAccount = useAccount();

  const bothSignerAndWalletConnected = useMemo(() => {
    if (!externalAccount) {
      return false;
    }
    return true;
  }, [externalAccount]);

  return (
    <div
      className={classNames(
        'flex-1 flex flex-col mx-auto p-4 w-full relative  z-0',
        'lg:w-75 lg:mt-6 lg:p-6',
        !isDesktop ? 'items-center' : ''
      )}>
      <h1 className="w-full justify-end text-2xl lg:text-3xl my-2 lg:my-6">
        Analyzing...
      </h1>
      <p className="text-sm text-opacity-60 text-white">
        It will normally take 20 mins. Please wait patiently.Your AI profile
        will be ready soon!
      </p>
      <Progress externalAddress={externalAccount?.address ?? ''} />
      <div
        className={classNames(
          'relative mt-8 w-full grid grid-cols-3 gap-2',
          'lg:grid-cols-10 lg:gap-4 lg:mt-12'
        )}>
        {imgList?.map(({ image, url }, index) => {
          return <UploadedImg url={(image || url) ?? ''} key={index} />;
        })}
      </div>
      <div className="mt-8">
        {isDesktop ? (
          <ButtonWithWallet
            btnComponent="Processing"
            disabled={true}
            className={
              'min-w-45 flex justify-center items-center py-2 unselectable-text text-center text-white rounded-lg gradient-button filter bottom-6 '
            }
          />
        ) : (
          <div className="fixed left-0 w-full py-4 px-6 flex justify-center items-center bg-sider bottom-0">
            <ButtonWithWallet
              btnComponent="Processing"
              disabled={true}
              className={
                'w-full flex justify-center items-center  py-2 unselectable-text text-center text-white rounded-lg gradient-button filter bottom-6 '
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Generating;

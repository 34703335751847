import { useMemo } from 'react';


import { useZkSbtSdk } from 'contexts/zkSbtContext';

export const usePublicAddress = () => {
  const { zkSbtSdk } = useZkSbtSdk();

  const publicAddress = useMemo(() => {
    const _address = zkSbtSdk?.getPublicAddress();
    return _address ? _address.toString() : '';
  }, [zkSbtSdk]);

  return publicAddress;
};

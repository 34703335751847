import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAccount } from 'wagmi';
import { GeneratedImg, UploadFile, useSBT } from '.';
import { useGenerating } from './generatingContext';
import { ProcessingStatus } from '../const';

type GeneratedContextValue = {
  mintSet: Set<GeneratedImg>;
  setMintSet: (mintSet: Set<GeneratedImg>) => void;
};

const GeneratedContext = createContext<GeneratedContextValue | null>(null);

export const GeneratedContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [mintSet, setMintSet] = useState<Set<GeneratedImg>>(new Set());

  const { skippedStep, onGoingTask, toggleSkippedStep } = useSBT();
  const { setGeneratedImgModels, handleGeneratedImgThemes } = useGenerating();
  const { setImgList, setModelId } = useSBT();
  const externalAccount = useAccount();

  useEffect(() => {
    const checkSkipStep = () => {
      if (skippedStep) {
        setModelId(onGoingTask?.model_id ?? '');
        const onGoingTaskUrls = onGoingTask?.urls || [];
        if (
          onGoingTask?.status &&
          onGoingTask?.status_str !== ProcessingStatus.GENERATING &&
          onGoingTaskUrls.length
        ) {
          setGeneratedImgModels(onGoingTaskUrls);
          handleGeneratedImgThemes(onGoingTaskUrls);
        } else {
          onGoingTaskUrls.length &&
            setImgList((onGoingTaskUrls as UploadFile[]) || []);
        }
        toggleSkippedStep(false);
      }
    };
    checkSkipStep();
  }, [
    externalAccount,
    onGoingTask,
    setGeneratedImgModels,
    setImgList,
    setModelId,
    skippedStep,
    toggleSkippedStep
  ]);

  const value = useMemo(
    () => ({
      mintSet,
      setMintSet
    }),
    [mintSet]
  );

  return (
    <GeneratedContext.Provider value={value}>
      {children}
    </GeneratedContext.Provider>
  );
};

export const useGenerated = () => {
  const data = useContext(GeneratedContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useGenerated can only be used inside of <GeneratedContext />, please declare it at a higher level.'
    );
  }
  return data;
};

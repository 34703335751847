import EVMWalletModal from 'components/Modal/EVMWalletModal';
import { useModal } from 'hooks';
import { useAccount } from 'wagmi';
import loadingGif from 'resources/images/loading.gif';
import getAbbreviatedName from 'utils/display/getAbbreviatedName';
import { Popover } from 'element-react';
import Icon from 'components/Icon';
import {
  RestoreStatus,
  useMintedList
} from 'pages/SBTPage/SBTContext/mintedListContext';
import { showInfo } from 'utils/ui/Notifications';
import { ReactNode } from 'react';
import { DisconnectComponent } from '../TokenValidation';

const StatusCom: Record<RestoreStatus, ReactNode> = {
  [RestoreStatus.HasData]: (
    <>
      <Icon name="warning" className="w-5 h-5 text-loading" />
      <span className="font-semibold mx-2 text-loading">
        Unsaved Data Detected
      </span>
    </>
  ),
  [RestoreStatus.Success]: (
    <>
      <Icon name="greenCheck" className="w-5 h-5 text-check" />
      <span className="font-semibold mx-2 text-check">
        Data has been successfully restored
      </span>
    </>
  ),
  [RestoreStatus.DetectFail]: (
    <>
      <Icon name="warning" className="w-5 h-5 text-error" />
      <span className="font-semibold mx-2 text-error">
        Failed to detect Unsaved Data
      </span>
    </>
  ),
  [RestoreStatus.Fail]: (
    <>
      <Icon name="warning" className="w-5 h-5 text-error" />
      <span className="font-semibold mx-2 text-error">
        Failed to restore Data
      </span>
    </>
  ),
  [RestoreStatus.Loading]: (
    <>
      <img src={loadingGif} className="w-5 h-5 text-loading" />
      <span className="font-semibold mx-2 text-loading">loading</span>
    </>
  ),
  [RestoreStatus.Default]: null,
  [RestoreStatus.NoData]: (
    <>
      <Icon name="greenCheck" className="w-5 h-5 text-check" />
      <span className="font-semibold mx-2 text-check">
        Unsaved data not detected
      </span>
    </>
  ),
  [RestoreStatus.NoProofs]: null
};

const StatusCls = {
  [RestoreStatus.Loading]: 'bg-loading',
  [RestoreStatus.Fail]: 'bg-error',
  [RestoreStatus.DetectFail]: 'bg-error',
  [RestoreStatus.Success]: 'bg-checked',
  [RestoreStatus.Default]: 'bg-loading',
  [RestoreStatus.HasData]: 'bg-loading',
  [RestoreStatus.NoData]: 'bg-checked',
  [RestoreStatus.NoProofs]: ''
};

const RestoreData = () => {
  const { showModal, hideModal, ModalWrapper } = useModal();
  const { address } = useAccount();
  const { saveData, restoreStatus } = useMintedList();
  if (restoreStatus === RestoreStatus.NoProofs) {
    showInfo(
      'No restorable data was detected. Please use the Manta Address associated with the missing zkPortrait and retry.'
    );
    return null;
  }

  if (restoreStatus === RestoreStatus.Default || !address) {
    return (
      <div className="mt-3 pb-4">
        {/* <p className="text-white text-opacity-80 text-sm">
          If you cannot find your minted zkPortrait, please{' '}
          <span className="text-check cursor-pointer" onClick={showModal}>
            Connect Wallet
          </span>{' '}
          to restore data.
        </p> */}
        <ModalWrapper>
          <EVMWalletModal hideModal={hideModal} />
        </ModalWrapper>
      </div>
    );
  }

  return (
    <div className="flex items-center mt-3">
      <div
        className={`w-content flex items-center h-10 px-3.5 rounded-lg ${StatusCls[restoreStatus]} bg-opacity-20 text-loading text-sm`}>
        {restoreStatus === RestoreStatus.NoData ? (
          <div className="flex items-center" onClick={showModal}>
            {StatusCom[restoreStatus] as Element}
          </div>
        ) : (
          (StatusCom[restoreStatus] as Element)
        )}
        <div className="text-white text-xsss text-center">
          Connected as {getAbbreviatedName(address ?? '', 4, 4)}
        </div>
        <Popover
          trigger="hover"
          placement="right"
          content={<DisconnectComponent />}
          width="177"
          className="disconnect-pop">
          <div>
            <Icon name="information" className="w-2.5 h-2.5 text-white ml-2" />
          </div>
        </Popover>
      </div>
      {restoreStatus === RestoreStatus.HasData && (
        <button
          onClick={saveData}
          className="ml-4 flex items-center justify-center unselectable-text text-center text-white rounded-lg gradient-button w-60 h-10 filter">
          Restore Data
        </button>
      )}
      <ModalWrapper>
        <EVMWalletModal hideModal={hideModal} />
      </ModalWrapper>
    </div>
  );
};
export default RestoreData;

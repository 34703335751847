import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { createContext, useContext } from 'use-context-selector';

import { useConfig } from 'contexts/configContext';
import { useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect, useWalletClient } from 'wagmi';
import {
  CountResult,
  HomeCountData,
  TokenCountResult
} from '../components/Home/type';

type SbtCommonContextValue = {
  tokenCountList: TokenCountResult[];
  getTokenCountList: () => void;
  homeCountData: HomeCountData;
  serverAssetId: string;
  setServerAssetId: (asset_id: string) => void;
  serverSignature: string;
  setServerSignature: (signature: string) => void;
  hasAutoConnectMetamask: boolean;
  setHasAutoConnectMetamask: (connect: boolean) => void;
};

export const SbtCommonContext = createContext<SbtCommonContextValue | null>(
  null
);

export const SbtCommonContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const { address, isConnected, connector } = useAccount();
  const { data: walletClient, isLoading: isLoadingWalletClient } =
    useWalletClient();
  const { disconnect } = useDisconnect();
  const currentAddressRef = useRef<string>('');
  const [tokenCountList, setTokenCountList] = useState<TokenCountResult[]>([]);
  const [homeCountData, setHomeCountData] = useState<HomeCountData>(
    {} as HomeCountData
  );
  const [serverAssetId, setServerAssetId] = useState<string>('');
  const [serverSignature, setServerSignature] = useState<string>('');

  const [hasAutoConnectMetamask, setHasAutoConnectMetamask] =
    useState<boolean>(false);

  const { SBT_NODE_SERVICE } = useConfig();

  const getTokenCountList = useCallback(async () => {
    const url = `${SBT_NODE_SERVICE}/npo/count`;
    try {
      const res = await axios.post<CountResult>(url);
      if (res.status === 200 || res.status === 201) {
        const { types, count, holders, todayCount } = res.data;
        setTokenCountList(types);
        setHomeCountData({
          today: todayCount,
          total: count,
          holders
        });
      }
    } catch (error) {
      console.error('get total minted count error: ', error);
    }
  }, [SBT_NODE_SERVICE]);

  useEffect(() => {
    if (window?.MetaCRMWidget?.manualConnectWallet) {
      window.MetaCRMWidget.manualConnectWallet(address);
    }

    const handleConnectWidget = () => {
      window.MetaCRMWidget.manualConnectWallet(address);
    };
    document.addEventListener('MetaCRMLoaded', handleConnectWidget);

    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
    };
  }, [address]);

  useEffect(() => {
    if (
      isConnected &&
      !connector?.name &&
      !isLoadingWalletClient &&
      !walletClient
    ) {
      // walletClient null https://github.com/wevm/wagmi/issues/2784
      disconnect();
    }
  }, [isConnected, connector, isLoadingWalletClient, walletClient]);

  useEffect(() => {
    getTokenCountList();
  }, [getTokenCountList]);

  useEffect(() => {
    if (address) {
      Sentry.setTag('user_id', address);
      if (currentAddressRef.current && address !== currentAddressRef.current) {
        navigate('/');
      }
      currentAddressRef.current = address;
    }
  }, [address]);

  const value = useMemo(
    () => ({
      tokenCountList,
      getTokenCountList,
      homeCountData,
      serverAssetId,
      setServerAssetId,
      serverSignature,
      setServerSignature,
      hasAutoConnectMetamask,
      setHasAutoConnectMetamask
    }),
    [
      tokenCountList,
      getTokenCountList,
      homeCountData,
      hasAutoConnectMetamask,
      setHasAutoConnectMetamask
    ]
  );

  return (
    <SbtCommonContext.Provider value={value}>
      {children}
    </SbtCommonContext.Provider>
  );
};

export const useSbtCommon = () => {
  const data = useContext(SbtCommonContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSbtCommon can only be used inside of <SbtCommonContext />, please declare it at a higher level.'
    );
  }
  return data;
};

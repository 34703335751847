import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { ZKSbtSDK } from '@zksbt/jssdk';

import { WalletErrorCode } from 'constants/ErrorCode';
import { useEthersSigner } from 'hooks/useEthersSigner';
import { showError } from 'utils/ui/Notifications';
import { useConfig } from './configContext';

interface ZkSbtSdkContextValue {
  zkSbtSdk: ZKSbtSDK | null;
}

const ZkSbtSdkContext = createContext<ZkSbtSdkContextValue | null>(null);
export const ZkSbtSdkContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [zkSbtSdk, setZkSbtSdk] = useState<ZKSbtSDK | null>(null);
  const signer = useEthersSigner();
  const { CONTRACT_ADDRESS } = useConfig();

  useEffect(() => {
    const initSdk = async () => {
      if (!signer) {
        return;
      }
      try {
        const sdk = await ZKSbtSDK.create(
          signer,
          CONTRACT_ADDRESS,
          'https://p0x-labs.s3.amazonaws.com/pomp/wasm/pomp.wasm',
          'https://p0x-labs.s3.amazonaws.com/pomp/zkey/pomp.zkey'
        );
        setZkSbtSdk(sdk);
      } catch (e: any) {
        // Not Auth means no account is connected
        if (e?.code === WalletErrorCode.notAuth) {
          return;
        }
        const message =
          e?.code === WalletErrorCode.rejected
            ? 'Please confirm the action to continue'
            : e?.message;
        showError(message ?? 'Create sdk error');
        setTimeout(() => initSdk(), 1500);
      }
    };

    initSdk();
  }, [signer, CONTRACT_ADDRESS]);

  return (
    <ZkSbtSdkContext.Provider value={{ zkSbtSdk }}>
      {children}
    </ZkSbtSdkContext.Provider>
  );
};

export const useZkSbtSdk = () => {
  const data = useContext(ZkSbtSdkContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useZkSbtSdk can only be used inside of <ZkSbtSdkContext />, please declare it at a higher level.'
    );
  }
  return data;
};

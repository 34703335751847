import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { onGoingProjectTypes, zkTokenMap } from '../Home/ProjectConstant';
import { MINT_TYPES } from '../Home/type';

const OnGoingProjects = ({
  title = 'Ongoing Projects',
  projectList = onGoingProjectTypes,
  className = '',
  gridClassName = ''
}: {
  title?: string;
  projectList?: MINT_TYPES[];
  className?: string;
  gridClassName?: string;
}) => {
  return (
    <div className={classNames(`w-full ${className}` )}>
      <h1 className="text-xl lg:text-2xl font-semibold">{title}</h1>
      <div
        className={`grid ${
          gridClassName
            ? gridClassName
            : classNames('grid-cols-2 gap-4', 'lg:grid-cols-4')
        } mt-6`}>
        {projectList.map((onGoingProjectType) => {
          return (
            <Link
              to={`/evm/sbt/projects/${onGoingProjectType}`}
              key={onGoingProjectType}
              className="relative">
              <img
                className="rounded-lg"
                src={zkTokenMap[onGoingProjectType].imgUrl}
              />
              <div className="absolute flex bg-black bg-opacity-0 w-full h-full top-0 font-red-hat-text  text-transparent hover:bg-opacity-80 hover:text-white rounded-lg">
                <div className="m-auto w-4/5 text-base text-center font-semibold">
                  {zkTokenMap[onGoingProjectType]?.title}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default OnGoingProjects;

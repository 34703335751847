// @ts-nocheck

const FINALIZED = 'finalized';
const FAILED = 'failed';
const PROCESSING = 'processing';
const DISCONNECTED = 'disconnected';

export default class TxStatus {
  public extrinsic;
  constructor(
    status,
    extrinsic = null,
    subscanUrl = null,
    message = null,
    customToast = false
  ) {
    this.status = status;
    this.extrinsic = extrinsic;
    this.subscanUrl = subscanUrl;
    this.message = message;
    this.batchNum = null;
    this.totalBatches = null;
    this.customToast = customToast;
  }

  static processing(message = null, extrinsic = null) {
    return new TxStatus(PROCESSING, extrinsic, null, message);
  }

  // Block explorer URL provided only for transactions on non-manta chains
  static finalized(
    extrinsic,
    subscanUrl: string | null = null,
    message = '',
    customToast = false
  ) {
    return new TxStatus(FINALIZED, extrinsic, subscanUrl, message, customToast);
  }

  static failed(message) {
    return new TxStatus(FAILED, null, null, message);
  }

  static disconnected(extrinsic = null) {
    return new TxStatus(DISCONNECTED, extrinsic);
  }

  isProcessing() {
    return this.status === PROCESSING;
  }

  isFinalized() {
    return this.status === FINALIZED;
  }

  isFailed() {
    return this.status === FAILED;
  }

  isDisconnected() {
    return this.status === DISCONNECTED;
  }

  toString() {
    let message = this.status;
    if (this.message) {
      message += `;\n ${this.message}`;
    }
    return message;
  }
}

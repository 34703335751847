import { ZK_CATERGORY } from './Home/type';

const CategoryButton = ({ category }: { category: ZK_CATERGORY }) => {
  switch (category) {
    case ZK_CATERGORY.zkKYC:
      return (
        <div className="flex mb-4">
          <div className="bg-zk-kyc-button border border-zk-kyc leading-4 px-6 py-1 text-white font-red-hat-text text-xs rounded-3xl">
            {category}
          </div>
        </div>
      );

    case ZK_CATERGORY.zkPortrait:
      return (
        <div className="flex mb-4">
          <div className="bg-zk-portrait-button border border-zk-portrait leading-4 px-6 py-1 text-white font-red-hat-text text-xs rounded-3xl">
            {category}
          </div>
        </div>
      );
    default:
      return (
        <div className="flex mb-4">
          <div className="bg-zk-credential-button border border-zk-credential leading-4 px-6 py-1 text-white font-red-hat-text text-xs rounded-3xl">
            {category}
          </div>
        </div>
      );
  }
};

export default CategoryButton;

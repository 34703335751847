import BN from 'bn.js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getNetwork } from '@wagmi/core';
import axios from 'axios';
import classNames from 'classnames';
import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import { ServiceReturnedCode, TX_SUCCESS } from 'constants/ErrorCode';
import { useConfig } from 'contexts/configContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useUsdPrices } from 'contexts/usdPricesContext';
import { useZkSbtSdk } from 'contexts/zkSbtContext';
import { useEthersProvider, useNativeBalance } from 'hooks';
import { useSBT } from 'pages/SBTPage/SBTContext';
import { useGenerated } from 'pages/SBTPage/SBTContext/generatedContext';
import { useGenerating } from 'pages/SBTPage/SBTContext/generatingContext';
import { useSbtToken } from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { WatermarkMapType, watermarkMap } from 'resources/images/sbt';
import AssetType from 'types/AssetType';
import Balance from 'types/Balance';
import TxStatus from 'types/TxStatus';
import { firstUpperCase } from 'utils/string';
import { showError, showSuccess } from 'utils/ui/Notifications';
import ButtonWithWallet from '../ButtonWithWallet';
import { LevelType, TokenType } from '../TokenButton';
import { MINT_ID_KEY } from 'pages/SBTPage/const';

export type MintGasType = {
  attribute: string;
  asset_id: string;
  verifyTimestamp: string;
  signature: string;
};

const MintImg = ({
  url,
  token,
  level
}: {
  url: string;
  token?: TokenType | null;
  level?: LevelType | null;
}) => {
  const watermarkName = token + firstUpperCase(level ?? '');
  return (
    <div className="relative w-18 h-18 rounded-lg">
      <img src={url} className="w-18 h-18 rounded-lg" />
      {watermarkName && (
        <img
          src={watermarkMap[watermarkName as WatermarkMapType]}
          className="absolute top-0 left-0 w-18 h-18 rounded-lg"
        />
      )}
    </div>
  );
};

const MintImgs = () => {
  const { mintSet } = useGenerated();
  return (
    <div className="p-4 grid gap-2 grid-cols-3 lg:w-108 lg:grid-cols-4">
      {[...mintSet].map((url, index) => {
        return <MintImg url={url} key={index} />;
      })}
    </div>
  );
};

const BtnComponent = ({
  gasFee,
  loading
}: {
  gasFee: Balance | null;
  loading: boolean;
}) => {
  return (
    <>
      {!gasFee ? 'Premint...' : 'Confirm'}
      {loading && <DotLoader />}
    </>
  );
};

const MintCheckModal = ({
  hideModal,
  showMintedModal,
  open
}: {
  hideModal: () => void;
  showMintedModal: () => void;
  open: boolean;
}) => {
  const [loading, toggleLoading] = useState(false);

  const { SBT_NODE_SERVICE, EVM_CHAIN } = useConfig();
  const { setGeneratedImgModels, setGeneratedImgThemes } = useGenerating();
  const { mintSet } = useGenerated();
  const nativeBalance = useNativeBalance();
  const {
    category: defaultCategory,
    setMinted,
    switchToPacific
  } = useSbtToken();
  const { setTxStatus } = useTxStatus();
  const { onGoingTask, modelId } = useSBT();
  const { zkSbtSdk } = useZkSbtSdk();
  const BLOCK_EXPLORER_URL = `${EVM_CHAIN?.blockExplorers?.etherscan?.url}`;
  const provider = useEthersProvider();
  const [batchParams, setBatchParams] = useState<MintGasType[][]>();
  const [gasFee, setGasfee] = useState<Balance | null>(null);
  const { nativeUsdPrice } = useUsdPrices();
  const { chain } = getNetwork();
  const isSameNetwork = chain?.id === EVM_CHAIN?.id;

  const mintSBTConfirm = useCallback(async () => {
    toggleLoading(true);
    try {
      await switchToPacific();
      // mint
      const res = await zkSbtSdk?.batchMint(...batchParams);
      if (res.status === TX_SUCCESS) {
        const hash = res.transactionHash;
        hideModal();
        showSuccess(BLOCK_EXPLORER_URL, hash);
        // clear data after minted
        setMinted();
        setGeneratedImgModels([]);
        setGeneratedImgThemes({});
        toggleLoading(false);
        const updateMintEventUrl = `${SBT_NODE_SERVICE}/npo/aigc/updateMintEvent`; // update mint event
        await axios.post(updateMintEventUrl, { hash });
        setTimeout(() => {
          showMintedModal();
        }, 100);
      } else {
        console.error(res, 'mint error');
        setTxStatus(TxStatus.failed(''));
      }
    } catch (e) {
      setTxStatus(TxStatus.failed(''));
      console.error('Mint Progress Wrong happens: ', e);
    }
  }, [batchParams, mintSet, setTxStatus]);

  useEffect(() => {
    const preMint = async () => {
      try {
        const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
        const category = aigcCategory || defaultCategory;
        if (!category) {
          return;
        }
        const attribute = modelId;
        const signature = await zkSbtSdk?.claimSbtSignature(
          BigInt(category),
          attribute
        );

        const url = `${SBT_NODE_SERVICE}/npo/aigc/premints`;
        const data = {
          sig: signature,
          publicAddress: zkSbtSdk?.getPublicAddress().toString(),
          category: String(category),
          attribute,
          urls: [...mintSet]
        };
        const ret = await axios.post<{
          code: ServiceReturnedCode;
          message: string;
          data: {
            exist: boolean;
            results: {
              asset_id: string;
              attribute: string;
              certificate_msg: string;
              eth_address: string;
              sbt_url: string;
              sig_address: string;
              signature: string;
              verifyTimestamp: string;
            }[];
          };
        }>(url, data);
        if (ret?.data?.code === ServiceReturnedCode.OK) {
          const res = ret?.data?.data?.results;
          const _batchParams = [
            Array(res?.length).fill(BigInt(category)),
            res.map((r) => r.attribute),
            res.map((r) => BigInt(r.asset_id)),
            res.map((r) => BigInt(r.verifyTimestamp)),
            res.map((r) => r.signature)
          ] as MintGasType[][];
          setBatchParams(_batchParams);
          const estimateGas = await zkSbtSdk?.estimate_batch_mint_gas(
            ..._batchParams
          );
          const feeData = await provider.getFeeData();
          const gasPrice = feeData.maxPriorityFeePerGas;
          if (gasPrice) {
            const gasFee = new Balance(
              AssetType.Pacific(),
              new BN(estimateGas?.mul(gasPrice).toString() ?? '')
            );
            setGasfee(gasFee);
          }
        } else {
          setTxStatus(TxStatus.failed(''));
          showError(ret?.data?.message ?? 'get asset id error');
        }
      } catch (e) {
        setTxStatus(TxStatus.failed(''));
        console.error('something wrong happens: ', e);
      } finally {
      }
    };
    if (zkSbtSdk && open) preMint();
  }, [zkSbtSdk, open]);

  const errorMsg = useMemo(() => {
    return '';
  }, [nativeBalance]);

  const disabled = useMemo(() => {
    return loading || gasFee == null || !!errorMsg;
  }, [errorMsg, loading, gasFee]);

  return (
    <div className="text-white text-center">
      <h2 className="text-2xl text-left font-bold">Checkout</h2>
      <div className="bg-secondary rounded-lg mt-6 mb-2 lg:mb-3 text-left">
        <MintImgs />
        <div className="flex justify-between border-b border-split px-4 pb-2 lg:py-4">
          <p>Gas Fee</p>
          <span className="text-white text-right">according to MetaMask</span>
        </div>
      </div>
      <p className="text-sm text-left">
        Balance: {nativeBalance?.toDisplayString(5) ?? '-'}
      </p>
      {errorMsg && (
        <p className="text-error mt-2 text-left absolute">
          <Icon name="information" className="mr-2 inline-block" />
          {errorMsg}
        </p>
      )}
      <ButtonWithWallet
        onClick={mintSBTConfirm}
        disabled={disabled}
        btnComponent={<BtnComponent gasFee={gasFee} loading={loading} />}
        className={classNames(
          'px-16 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter mt-4',
          'lg:px-36 lg:mt-12'
        )}
      />
    </div>
  );
};

export default MintCheckModal;

import { ServiceReturnedCode, TX_SUCCESS } from 'constants/ErrorCode';
import { useCallback, useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import { BN } from 'bn.js';
import classNames from 'classnames';
import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import { useConfig } from 'contexts/configContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useUsdPrices } from 'contexts/usdPricesContext';
import { useZkSbtSdk } from 'contexts/zkSbtContext';
import { ContractReceipt } from 'ethers';
import { useEthersProvider, useNativeBalance, usePublicAddress } from 'hooks';
import {
  DEFAULT_ATTRIBUTE,
  MINT_CATEGORY,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import AssetType from 'types/AssetType';
import Balance from 'types/Balance';
import TxStatus from 'types/TxStatus';
import { showError, showSuccess } from 'utils/ui/Notifications';
import { useAccount } from 'wagmi';
import ButtonWithWallet from '../ButtonWithWallet';

const BtnComponent = ({
  loading,
  gasFee
}: {
  loading: boolean;
  gasFee: Balance | null;
}) => {
  if (!gasFee) {
    return <>Estimating GasFee...</>;
  }
  return (
    <>
      Confirm
      {loading && <DotLoader />}
    </>
  );
};

const SbtTokenMintCheckModal = ({
  hideModal,
  showMintedModal,
  title,
  checkBalance = false
}: {
  hideModal: (triggerCloseCallback?: boolean) => void;
  showMintedModal: () => void;
  title: string;
  checkBalance?: boolean;
}) => {
  const [mintBtnLoading, toggleLoading] = useState(false);

  const { txStatus, setTxStatus } = useTxStatus();
  const { category, saveMintData, tokenType, isMinted, setMinted } =
    useSbtToken();
  const { nativeUsdPrice } = useUsdPrices();
  const { zkSbtSdk } = useZkSbtSdk();
  const { EVM_CHAIN, SBT_NODE_SERVICE } = useConfig();
  const [gasFee, setGasfee] = useState<Balance | null>(null);
  const [assetId, setAssetId] = useState<string>('');
  const [serverSignature, setSeverSignature] = useState<string>('');
  const [serverVerifyTimestamp, setVerifyTimestamp] = useState<string>('');
  const publicAddress = usePublicAddress();
  const { address = '' } = useAccount();
  const BLOCK_EXPLORER_URL = `${EVM_CHAIN?.blockExplorers?.etherscan?.url}`;

  const nativeBalance = useNativeBalance();
  const provider = useEthersProvider();

  const insufficient = useMemo(() => {
    if (gasFee && nativeBalance && gasFee?.gt(nativeBalance)) {
      return true;
    }
    if (!nativeBalance) {
      console.error('get native public balance error');
      return true;
    }
    return false;
  }, [nativeBalance, gasFee]);

  const mintSBTConfirm = useCallback(async () => {
    toggleLoading(true);
    try {
      // mint
      const res: ContractReceipt = await zkSbtSdk?.mint(
        BigInt(category),
        DEFAULT_ATTRIBUTE,
        BigInt(assetId),
        BigInt(serverVerifyTimestamp),
        serverSignature
      );
      if (res.status === TX_SUCCESS) {
        const hash = res.transactionHash;
        showSuccess(BLOCK_EXPLORER_URL, hash);
        setMinted();
        hideModal(false);
      } else {
        console.error(res, 'mint error');
        setTxStatus(TxStatus.failed(''));
      }
    } catch (e) {
      setTxStatus(TxStatus.failed(''));
      console.error('something wrong happens: ', e);
    } finally {
      toggleLoading(false);
    }
  }, [category, assetId, serverSignature, serverVerifyTimestamp, setTxStatus]);

  useEffect(() => {
    const preMint = async () => {
      try {
        const category = MINT_CATEGORY[tokenType];
        if (!category) {
          return;
        }
        const attribute = DEFAULT_ATTRIBUTE;
        const signature = await zkSbtSdk?.claimSbtSignature(
          BigInt(category),
          attribute
        );

        const url = `${SBT_NODE_SERVICE}/npo/eth/premint`;
        const data = {
          sig: signature,
          publicAddress: zkSbtSdk?.getPublicAddress().toString(),
          category: String(category)
        };
        const ret = await axios.post<{
          code: ServiceReturnedCode;
          message: string;
          data: {
            asset_id: string;
            signature: string;
            verifyTimestamp: string;
          };
        }>(url, data);
        if (ret?.data?.code === ServiceReturnedCode.OK) {
          const {
            asset_id,
            signature: tokenServerSignature,
            verifyTimestamp
          } = ret?.data?.data ?? {};
          setAssetId(asset_id);
          setSeverSignature(tokenServerSignature);
          setVerifyTimestamp(verifyTimestamp);
          const estimateGas = await zkSbtSdk?.estimate_mint_gas(
            BigInt(category),
            '',
            BigInt(asset_id),
            BigInt(verifyTimestamp),
            tokenServerSignature
          );
          const feeData = await provider.getFeeData();
          const gasPrice = feeData.maxPriorityFeePerGas;
          if (gasPrice) {
            const gasFee = new Balance(
              AssetType.Pacific(),
              new BN(estimateGas?.mul(gasPrice).toString() ?? '')
            );
            setGasfee(gasFee);
          }
        } else {
          setTxStatus(TxStatus.failed(''));
          showError(ret?.data?.message ?? 'get asset id error');
        }
      } catch (e) {
        setTxStatus(TxStatus.failed(''));
        console.error('something wrong happens: ', e);
      } finally {
      }
    };
    if (zkSbtSdk && !isMinted) preMint();
  }, [zkSbtSdk]);

  useEffect(() => {
    const handleTxFinalized = () => {
      if (txStatus?.isFinalized()) {
        toggleLoading(false);
        hideModal();
        setTimeout(() => {
          saveMintData();
        });
      } else if (txStatus?.isFailed()) {
        toggleLoading(false);
      }
    };
    handleTxFinalized();
  }, [hideModal, showMintedModal, txStatus, saveMintData]);

  const errorMsg = useMemo(() => {
    if (mintBtnLoading) {
      return '';
    }

    if (gasFee && nativeBalance && gasFee?.gt(nativeBalance)) {
      return 'Insufficient balance';
    }

    return '';
  }, [gasFee, mintBtnLoading, checkBalance]);

  const disabled = useMemo(
    () => isMinted || mintBtnLoading || !!errorMsg || insufficient,
    [isMinted, mintBtnLoading, errorMsg, insufficient]
  );

  return (
    <div className="text-white text-center">
      <h2 className="text-2xl text-left font-bold">Checkout</h2>
      <div className="bg-secondary rounded-lg mt-6 mb-2 lg:mb-3 text-left">
        <div className="flex justify-between pt-4 px-4">
          <p>1 {title}</p>
          <span className="font-bold text-check">Free</span>
        </div>
        <div className="flex justify-between border-b border-split px-4 pb-2 lg:py-4">
          <p>Gas Fee</p>
          <span className="ml-auto text-opacity-60 text-white mr-2">
            + approximately
          </span>
          <span className="text-white text-right font-bold">
            {gasFee?.toFeeDisplayString()}
          </span>
        </div>
        <div className="flex justify-between p-4">
          <p>Total</p>
          <div className="flex flex-col text-right">
            <span className="text-check font-bold">
              {gasFee?.toFeeDisplayString()}
            </span>
            <span className="text-white text-opacity-60">
              {nativeUsdPrice ? gasFee?.toUsd(nativeUsdPrice).toString() : '--'}{' '}
              USD
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm text-left">
          Balance: {nativeBalance?.toFeeDisplayString() ?? '-'}
        </p>
        {errorMsg && (
          <p className="text-error text-left">
            <Icon name="information" className="mr-2 inline-block" />
            {errorMsg}
          </p>
        )}
      </div>
      <ButtonWithWallet
        onClick={mintSBTConfirm}
        disabled={disabled}
        btnComponent={<BtnComponent loading={mintBtnLoading} gasFee={gasFee} />}
        className={classNames(
          'px-16 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter mt-4',
          'lg:px-36 lg:mt-12'
        )}
      />
    </div>
  );
};

export default SbtTokenMintCheckModal;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVLINKPATH } from './NavLinks';

const NavZkNFT = () => {
  const navigate = useNavigate();
  const list = `/evm${NAVLINKPATH.NPOList}`;

  const handleClick = useCallback(() => {
    navigate(list);
  }, [list, navigate]);

  return (
    <button
      onClick={handleClick}
      className="mr-4 gradient-button text-white font-red-hat-text text-sm h-10 w-24 cursor-pointer rounded-lg">
      My zkNFTs
    </button>
  );
};

export default NavZkNFT;

import classNames from 'classnames';
import Icon from 'components/Icon';
import { useState } from 'react';

const SearchProofKeyModalContent = ({
  tags = [],
  handleCreateNew,
  handleShowProofDetail
}: {
  tags: string[];
  handleCreateNew: () => void;
  handleShowProofDetail: (tag: string) => void;
}) => {
  const [search, setSearch] = useState('');
  const filteredList = tags.filter((item) =>
    item.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <span className="absolute z-10 top-5 left-6 text-white text-xl">
        Please select Proof Key Tag
      </span>
      <div
        className={classNames(
          'pt-16 flex flex-col text-white h-80 w-80',
          'lg:h-90 lg:w-90'
        )}>
        <div className="relative flex flex-row px-6 items-center">
          <Icon className="absolute ml-4" name={'search'} />
          <input
            id="tagName"
            className={classNames(
              'pl-14 h-11 flex-grow px-6 py-2 font-red-hat-text text-sm placeholder-gray-500 text-black dark:text-white bg-white bg-opacity-5 border border-white-light rounded-lg'
            )}
            placeholder="Search Name"
            value={search}
            autoComplete="off"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="mt-2 overflow-y-scroll">
          <div
            onClick={() => handleCreateNew()}
            className="ml-2 px-6 py-4 text-checked text-sm select-none cursor-pointer hover:bg-green-light-half border-b border-white border-opacity-10 rounded-lg">
            Create New
          </div>
          {filteredList.map((m, index) => (
            <div
              onClick={() => handleShowProofDetail(m)}
              key={index}
              className="ml-2 px-6 py-4 text-white text-sm select-none cursor-pointer hover:bg-green-light-half border-b border-white border-opacity-10 rounded-lg">
              {m}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchProofKeyModalContent;

// @ts-nocheck
import DeveloperConsole from 'components/Developer/DeveloperConsole';
import NETWORK from 'constants/NetworkConstants';
import { ConfigContextProvider, useConfig } from 'contexts/configContext';
import { MantaWalletContextProvider } from 'contexts/mantaWalletContext';
import { SubstrateContextProvider } from 'contexts/substrateContext';
import { TxStatusContextProvider, useTxStatus } from 'contexts/txStatusContext';
import { UsdPricesContextProvider } from 'contexts/usdPricesContext';
import { ReactNode, useEffect } from 'react';
import initAxios from 'utils/api/initAxios';
import {
  showCustomSuccess,
  showError,
  showInfo,
  showSuccess,
  showWarning
} from 'utils/ui/Notifications';

const TxStatusHandler = () => {
  const { txStatus, setTxStatus } = useTxStatus();

  useEffect(() => {
    if (txStatus?.isFinalized()) {
      if (txStatus.customToast) {
        showCustomSuccess(txStatus.message);
      } else {
        showSuccess(txStatus.subscanUrl, txStatus?.extrinsic);
      }
      setTxStatus(null);
    } else if (txStatus?.isFailed()) {
      showError(txStatus.message || 'Transaction failed');
      setTxStatus(null);
    } else if (txStatus?.isProcessing() && txStatus.message) {
      showInfo(txStatus.message);
    } else if (txStatus?.isDisconnected()) {
      showWarning('Network disconnected');
      setTxStatus(null);
    }
  }, [setTxStatus, txStatus]);

  return <div />;
};

const BasePage = ({ children }: { children: ReactNode }) => {
  const config = useConfig();

  useEffect(() => {
    initAxios(config);
  }, []);

  return (
    <TxStatusContextProvider>
      <SubstrateContextProvider>
        <MantaWalletContextProvider>
          <DeveloperConsole />
          <TxStatusHandler />
          {children}
        </MantaWalletContextProvider>
      </SubstrateContextProvider>
    </TxStatusContextProvider>
  );
};

export const EthereumBasePage = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigContextProvider network={NETWORK.ETHEREUM}>
      <BasePage>
       {children}
      </BasePage>
    </ConfigContextProvider>
  );
};

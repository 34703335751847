import classNames from 'classnames';
import Icon from 'components/Icon';
import { useModal, useResponsive } from 'hooks';
import { useSBT } from 'pages/SBTPage/SBTContext';
import { useGenerated } from 'pages/SBTPage/SBTContext/generatedContext';
import {
  MintStatusText,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useMemo } from 'react';
import ButtonWithWallet from '../ButtonWithWallet';
import GeneratedImgs from '../GeneratedImgs';
import MintCheckModal from '../MintCheckModal';
import MintedModal from '../MintedModal';
import NoticeCheckModal from './NoticeCheckModal';
import { useGenerating } from 'pages/SBTPage/SBTContext/generatingContext';
import DotLoader from 'components/Loaders/DotLoader';
import { useNavigate } from 'react-router-dom';
export const MAX_MINT_SIZE = 1;

const Generated = () => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { cleanAigc } = useSBT();
  const { mintSet } = useGenerated();
  const { generatedImgModels, setGeneratedImgModels, setGeneratedImgThemes } =
    useGenerating();
  const { setMintDefault, mintStatusMsg } = useSbtToken();
  const {
    ModalWrapper,
    showModal,
    hideModal,
    open: modalOpen
  } = useModal({
    closeOnBackdropClick: false,
    closeCallback: setMintDefault
  });

  const { ModalWrapper: MintedModalWrapper, showModal: showMintedModal } =
    useModal({
      closeOnBackdropClick: false,
      outLineCss: 'relative inline-block p-6 bg-primary rounded-xl text-black'
    });

  const {
    ModalWrapper: CleanModalWrapper,
    showModal: showCleanModal,
    hideModal: hideCleanModal
  } = useModal({
    closeOnBackdropClick: false,
    closeCallback: setMintDefault
  });

  const btnDisabled = mintSet.size > MAX_MINT_SIZE || mintSet.size <= 0;
  const isMinted = mintStatusMsg === MintStatusText.MINTED;

  const handleModal = () => {
    showModal();
  };

  const handleCleanConfirm = async () => {
    setMintDefault();
    const success = await cleanAigc();
    if (success) {
      setGeneratedImgModels([]);
      setGeneratedImgThemes({});
    }
    hideCleanModal();
  };

  const tipInfo = useMemo(() => {
    return `${mintSet.size} zkPortrait selected`;
  }, [mintSet.size]);

  if (!generatedImgModels.length && isMinted)
    return (
      <div
        className={classNames(
          'flex-1 flex flex-col mx-auto mb-10 p-6 py-4 pb-28 w-full relative z-0',
          'lg:w-75 lg:mt-6 lg:py-6'
        )}>
        <h1 className="text-2xl my-2 lg:text-3xl lg:my-6">{mintStatusMsg}!</h1>
        <button
          onClick={() => navigate('/')}
          className="mt-8 w-full lg:w-45 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter">
          Back Home
        </button>
        <MintedModalWrapper>
          <MintedModal />
        </MintedModalWrapper>
      </div>
    );

  return (
    <div
      className={classNames(
        'flex-1 flex flex-col mx-auto mb-10 p-6 py-4 pb-28 w-full relative z-0',
        'lg:w-75 lg:mt-6 lg:py-6'
      )}>
      <h1 className="text-2xl my-2 lg:text-3xl lg:my-6">Generated!</h1>
      <p className="text-sm text-opacity-60 text-white mb-4 lg:mb-6">
        Your AI-generated zkPortrait results are unique and the same images will
        not be generated again. Select one image to mint as your zkPortrait.
      </p>
      {generatedImgModels.map(({ style = '', urls = [] }, index) => {
        return <GeneratedImgs key={index} style={style} generatedImgs={urls} />;
      })}
      <div className="flex flex-col items-center w-auto lg:w-full unselectable-text text-center text-white rounded-lg mt-10">
        {mintSet.size > 0 ? (
          <span className="flex justify-center items-center text-xs text-white text-opacity-60 mb-3">
            <Icon name="information" className="mr-2" />
            {tipInfo}
          </span>
        ) : null}
        {isDesktop ? (
          <ButtonWithWallet
            disabled={btnDisabled}
            btnComponent="Mint"
            onClick={handleModal}
            className="w-full lg:w-auto px-16 lg:px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter"
          />
        ) : (
          <div className="fixed left-0 w-full py-4 px-6 flex justify-center items-center bg-sider bottom-0">
            <ButtonWithWallet
              disabled={btnDisabled}
              btnComponent="Mint"
              onClick={handleModal}
              className="w-full lg:w-auto px-16 lg:px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter"
            />
          </div>
        )}
      </div>
      <ModalWrapper>
        <MintCheckModal
          hideModal={hideModal}
          showMintedModal={showMintedModal}
          open={modalOpen}
        />
      </ModalWrapper>
      <CleanModalWrapper>
        <NoticeCheckModal confirm={handleCleanConfirm} />
      </CleanModalWrapper>
    </div>
  );
};

export default Generated;

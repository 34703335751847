import classNames from 'classnames';
import ConnectWallet from 'components/Accounts/ConnectWallet';
import Icon from 'components/Icon';
import { useConnectMetamask, usePublicAddress, useResponsive } from 'hooks';
import { Step, useSBT } from 'pages/SBTPage/SBTContext';
import {
  MINT_CATEGORY,
  VALID_STATUS,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { useEffect } from 'react';
import sbtImgs from 'resources/images/sbt';
import { useAccount } from 'wagmi';
import OnGoingTaskNotification from '../OnGoingTaskModal';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const { setCurrentStep } = useSBT();
  const { isDesktop } = useResponsive();
  const { tokenType, validateToken, tokenValidStatus } = useSbtToken();

  const { address = '', isConnected } = useAccount();
  const category = MINT_CATEGORY[tokenType];
  const publicAddress = usePublicAddress();
  const toUpload = () => {
    setCurrentStep(Step.Upload);
  };

  // auto connect metamask
  useConnectMetamask({ autoConnect: true });

  useEffect(() => {
    if (isConnected && address && publicAddress) {
      validateToken(address, publicAddress, category);
    }
  }, [address, publicAddress, tokenType]);

  const imgs = isDesktop ? sbtImgs : sbtImgs.slice(0, sbtImgs.length / 2);
  const notEligible = true;
  // const notEligible = tokenValidStatus === VALID_STATUS.notEligible;

  return (
    <div
      className={classNames(
        'flex flex-col items-center mx-auto bg-secondary rounded-xl p-4 w-full mt-6',
        'lg:w-75 lg:p-14'
      )}>
      <Icon className="w-12 h-12" name="asMatch" />
      <h2 className="text-white whitespace-nowrap text-2xl mt-6 mb-4 font-red-hat-mono font-medium">
        AsMatch AIGC zkPortrait
      </h2>
      <div
        className={classNames(
          'grid gap-2 grid-cols-3 grid-rows-3 w-full justify-between mt-8 mb-20',
          'lg:gap-6 lg:grid-cols-9'
        )}>
        {imgs.map((item, index) => {
          return (
            <img
              className="img-bg w-24 h-24 rounded-xl cursor-pointer hover:scale-200 transform duration-500 hover:z-10"
              key={index}
              src={item}
            />
          );
        })}
      </div>
      {!address ? (
        <ConnectWallet
          isButtonShape={true}
          className={
            'bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 w-44 cursor-pointer rounded-lg'
          }
        />
      ) : (
        <button
          className={classNames(
            'min-w-45 bg-connect-wallet-button text-white font-red-hat-text text-sm h-10 cursor-pointer rounded-lg',
            notEligible && 'cursor-not-allowed bg-opacity-70'
          )}
          onClick={toUpload}
          disabled={notEligible}>
          <p className="unselectable-text">
            {notEligible ? 'Not Eligible' : 'Start'}
          </p>
        </button>
      )}
      <OnGoingTaskNotification navigate={navigate} />
    </div>
  );
};

export default Home;

import BN from 'bn.js';
import { localStorageKeys } from 'constants/LocalStorageConstants';
import { useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import { useConfig } from 'contexts/configContext';
import { useTxStatus } from 'contexts/txStatusContext';
import { useUsdPrices } from 'contexts/usdPricesContext';
import { useZkSbtSdk } from 'contexts/zkSbtContext';
import {
  useEthersProvider,
  useEthersSigner,
  useNativeBalance,
  usePublicAddress
} from 'hooks';
import { getNetwork } from '@wagmi/core';
import { Step, useSBT } from 'pages/SBTPage/SBTContext';
import { useSBTTheme } from 'pages/SBTPage/SBTContext/sbtThemeContext';
import {
  DEFAULT_ATTRIBUTE,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import AssetType from 'types/AssetType';
import Balance from 'types/Balance';
import { useAccount } from 'wagmi';
import ButtonWithWallet from '../ButtonWithWallet';
import {
  GenerateStatus,
  useGenerating
} from 'pages/SBTPage/SBTContext/generatingContext';
import { Signer } from 'ethers';
import { MINT_ID_KEY } from 'pages/SBTPage/const';

const BtnComponent = ({
  loading,
  total
}: {
  loading: boolean;
  total: Balance | null;
}) => {
  return (
    <>
      {total ? 'Confirm' : 'Estimating GasFee...'}
      {loading && <DotLoader />}
    </>
  );
};
const ThemeCheckModal = ({ hideModal }: { hideModal: () => void }) => {
  const [loading, toggleLoading] = useState(false);

  const { setCurrentStep, getSalt } = useSBT();
  const nativeBalance = useNativeBalance();
  const { category, fees, getFees, nftPass, setMinted, switchToPacific } =
    useSbtToken();
  const { checkedThemeItems, generateImgs } = useSBTTheme();
  const { setGenerateStatus } = useGenerating();
  const config = useConfig();
  const publicAddress = usePublicAddress();
  const { txStatus } = useTxStatus();
  const externalAccount = useAccount();
  const { address } = useAccount();
  const { zkSbtSdk } = useZkSbtSdk();
  const { nativeUsdPrice } = useUsdPrices();
  const provider = useEthersProvider();
  const signer = useEthersSigner();
  const [gasFee, setGasFee] = useState<Balance | null>(null);
  const [total, setTotal] = useState<Balance | null>(null);
  const { chain } = getNetwork();
  const isSameNetwork = chain?.id === config.EVM_CHAIN.id;
  const defaultDecimals = 6

  useEffect(() => {
    const getGasFee = async () => {
      const gasFeeRaw = await zkSbtSdk?.estimateMintGas(signer as Signer);
      const gasFee = new Balance(
        AssetType.Pacific(),
        new BN(gasFeeRaw?.toString() ?? '')
      );
      const serverFee = new BN(fees?.actualFeeDecimal || 0);
      const total = new Balance(
        AssetType.Pacific(),
        serverFee.add(new BN(gasFeeRaw?.toString() ?? ''))
      );
      setGasFee(gasFee);
      setTotal(total);
    };
    if (signer && fees && provider) getGasFee();
  }, [signer, fees, provider]);

  const toGeneratingPage = async () => {
    toggleLoading(true);
    try {
      await switchToPacific();
      const tokenId = nftPass;
      const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
      const mintId = String(aigcCategory || category);
      const salt = await getSalt({ address, mintId, tokenId });
      const attribute = DEFAULT_ATTRIBUTE;
      const signature = await zkSbtSdk?.claimSbtSignature(
        BigInt(mintId),
        attribute
      );

      // zkp
      const serviceType = mintId;
      const serviceData = JSON.stringify({ tokenId });
      const zkp = await zkSbtSdk?.generateServiceProof(
        BigInt(mintId),
        attribute,
        serviceType,
        serviceData,
        BigInt(salt)
      );
      const nullifierHash = zkp?.publicSignals.nullifierHash;
      const proof = zkp?.proof;

      // payment
      const paymentUrl = `${config.SBT_NODE_SERVICE}/npo/aigc/servicePayment`;
      const paymentData = {
        public_address: publicAddress,
        address,
        mintId,
        attribute,
        tokenId,
        serviceData,
        nullifierHash,
        proof,
        salt,
        signature
      };
      const res = await axios.post(paymentUrl, paymentData);
      const { transactionHash } = await zkSbtSdk?.payForService(
        BigInt(mintId),
        attribute,
        serviceType,
        serviceData,
        res?.data?.data?.fee,
        res?.data?.data?.signature
      );

      // update transactionEvent
      const updateTransactionEventUrl = `${config.SBT_NODE_SERVICE}/npo/aigc/updateTransactionEvent`;
      await axios.post(updateTransactionEventUrl, { hash: transactionHash });
      setGenerateStatus(GenerateStatus.doing);
      await generateImgs(tokenId, mintId);
    } catch (error) {
      console.log(error, 'toGeneratingPage error');
      toggleLoading(false);
      return;
    }

    setMinted();
    toggleLoading(false);
    hideModal();
    // reset the progress counting time when start generating new images again
    localStorage.setItem(
      `${localStorageKeys.GeneratingStart}-${externalAccount?.address}`,
      ''
    );
    setTimeout(() => {
      setCurrentStep(Step.Generating);
    }, 100);
  };

  const errorMsg = useMemo(() => {
    // no need to show the static error message during a transaction
    if (loading) {
      return '';
    }
    if (
      !Number(nativeBalance?.toString(defaultDecimals)) ||
      (isSameNetwork && nativeBalance && total && total?.gt(nativeBalance))
    ) {
      return 'Your account does not have enough balance for this transaction.';
    }
    return '';
  }, [nativeBalance, loading]);

  const disabled =
    loading ||
    !Number(nativeBalance?.toString(defaultDecimals)) ||
    !Number(total?.toString(defaultDecimals)) ||
    !!errorMsg;

  return (
    <div className="text-white text-center">
      <h2 className="text-2xl text-left font-bold">Checkout</h2>
      <div className="bg-secondary rounded-lg mt-4 mb-2 lg:mt-6 lg:mb-4">
        <div className="flex justify-between p-4 pb-2">
          <p>One Mint</p>
          <div className="flex flex-col text-right">
            <span className="text-check font-bold">
              {Number(fees?.aigcFee) * (1 - Number(fees?.discount)) || '-'} ETH
            </span>
          </div>
        </div>
        {!!Number(fees?.discount) && (
          <div className="flex justify-between items-center p-4 py-2 pt-0">
            <p className="text-left text-check">NFT Special Discount</p>
            <span className="ml-auto text-sm text-opacity-40 text-white mr-2 text-right">
              NFT Pass #{nftPass} Applied
            </span>
            <div className="flex flex-col text-right">
              <span className="text-red-700 font-bold">
                {!!Number(fees?.discount)
                  ? `-${Number(fees?.discount) * 100}%`
                  : ''}
              </span>
            </div>
          </div>
        )}
        <div className="flex justify-between border-b border-split p-4 pt-2">
          <p className="text-left">Gas Fee</p>
          <span className="ml-auto text-opacity-40 text-white mr-2 text-right">
            + approximately
          </span>
          <span className="text-right text-white font-bold">
            {gasFee?.toFeeDisplayString()}
          </span>
        </div>
        <div className="flex justify-between p-4 ">
          <p>Total</p>
          <div className="flex flex-col text-right">
            <span className="text-check font-bold">
              {total?.toFeeDisplayString()}
            </span>
            <span className="text-white text-opacity-60">
              {nativeUsdPrice ? total?.toUsd(nativeUsdPrice).toString() : '--'}
            </span>
          </div>
        </div>
      </div>
      <p className="text-sm text-left">
        Balance: {nativeBalance?.toDisplayString(5) ?? '-'}
      </p>
      {errorMsg && (
        <p className="text-error mt-2 text-left">
          <Icon name="information" className="mr-2 inline-block" />
          {errorMsg}
        </p>
      )}
      <ButtonWithWallet
        btnComponent={<BtnComponent loading={loading} total={total} />}
        onClick={toGeneratingPage}
        disabled={disabled}
        className="w-full lg:w-auto px-16 lg:px-36 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter mt-4 lg:mt-6"
      />
    </div>
  );
};

export default ThemeCheckModal;

import NETWORK, { SS58 } from 'constants/NetworkConstants';
import configCalamari from './calamari.json';
import configCommon from './common.json';
import configDolphin from './dolphin.json';
import configManta from './manta.json';
import configEthereum from './ethereum.json';
import { Chain } from 'wagmi';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const configEnv = require(`./env/${
  process.env.REACT_APP_ENV ?? 'development'
}.json`);

export type Node = {
  explorer: string;
  logo: string;
  name: string;
  path: string;
  url: string | string[];
  testnet?: boolean;
};

export type ConfigType = {
  DEFAULT_SOCKET: string;
  CALAMARI_SOCKET: string | string[];
  PACIFIC_URL: string;
  CALAMARI_URL: string;
  MIN_REQUIRED_WALLET_VERSION: string;
  DEV_CONSOLE: boolean;
  DOLPHIN_SOCKET: string;
  DOLPHIN_SUBSCAN_URL: string;
  DOWNTIME: boolean;
  IS_TESTNET: boolean;
  KARURA_SOCKET: string;
  KARURA_SUBSCAN_URL: string | null;
  KUSAMA_SOCKET: string;
  KUSAMA_SUBSCAN_URL: string | null;
  MOONRIVER_RPC: string;
  MOONRIVER_SOCKET: string;
  MOONRIVER_SUBSCAN_URL: string | null;
  BLOCK_EXPLORER_URL?: string;
  NETWORK_NAME: NETWORK;
  NODES: Array<Node>;
  PAGE_TITLE: string;
  PROVIDER_SOCKET: string | string[];
  ROCOCO_SUBSCAN_URL: string | null;
  RPC: {
    mantaPay: {
      pull_ledger_diff: {
        description: string;
        params: [
          {
            name: string;
            type: any;
          },
          {
            name: string;
            type: any;
          },
          {
            name: string;
            type: any;
          }
        ];
        type: any;
      };
    };
  };
  SUBSCAN_API_KEY: string;
  SUBSCAN_URL: string;
  SUBSCAN_API_ENDPOINT: string;
  SOCKET: string | string[];
  SBT_NODE_SERVICE: string;
  RELAYER_SERVICE: string;
  FAUCET_SERVICE: string;
  GIANT_SQUID_SERVICE: string;
  EVM_CHAIN: Chain;
  CONTRACT_ADDRESS: string;
  CONTRACT_WASM: string;
  CONTRACT_ZKEY: string;
  SS58_FORMAT: SS58;
  TWITTER_URL: string;
  CALAMARI_SUBSCAN_URL?: string;
  BNB_RPC: string;
  BNB_SOCKET: string;
  BNB_SUBSCAN_URL: string;
};

export const dolphinConfig: ConfigType = {
  ...configCommon,
  ...configDolphin,
  ...configEnv
};

export const calamariConfig: ConfigType = {
  ...configCommon,
  ...configCalamari,
  ...configEnv
};

export const mantaConfig: ConfigType = {
  ...configCommon,
  ...configManta,
  ...configEnv
};

export const ethereumConfig: ConfigType = {
  ...configCommon,
  ...configEthereum,
  ...configEnv
};

import axios from 'axios';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useConfig } from 'contexts/configContext';
import { useAccount } from 'wagmi';
import { useSBT } from '.';
import { useFaceRecognition } from './faceRecognitionContext';
import { showError } from 'utils/ui/Notifications';
import { MINT_ID_KEY } from '../const';

export type ThemeItem = {
  name: string;
  url: string;
};

type SBTThemeContextValue = {
  themeList: ThemeItem[];
  checkedThemeItems: Map<string, ThemeItem>;
  toggleCheckedThemeItem: (map: Map<string, ThemeItem>) => void;
  generateImgs: (tokenId: string, category: string) => void;
};

const SBTThemeContext = createContext<SBTThemeContextValue | null>(null);

export const SBTThemeContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [themeList, setThemeList] = useState<ThemeItem[]>([]);
  const [checkedThemeItems, toggleCheckedThemeItem] = useState<
    Map<string, ThemeItem>
  >(new Map<string, ThemeItem>());

  const config = useConfig();
  const { themeGender } = useFaceRecognition();
  const externalAccount = useAccount();
  const { imgList, modelId, setModelId } = useSBT();

  useEffect(() => {
    const getThemes = async () => {
      const url = `${config.SBT_NODE_SERVICE}/npo/aigc/themes`;
      const ret = await axios.post<ThemeItem[]>(url, { gender: themeGender });
      if (ret.status === 200 || ret.status === 201) {
        const newThemeList = ret?.data?.map((themeItem) => themeItem);
        setThemeList(newThemeList);
      }
    };
    themeGender && getThemes();
  }, [config.SBT_NODE_SERVICE, themeGender]);

  const generateImgs = useCallback(
    async (tokenId, category) => {
      const aigcCategory = sessionStorage.getItem(MINT_ID_KEY);
      try {
        const url = `${config.SBT_NODE_SERVICE}/npo/aigc/gen`;
        const data = {
          address: externalAccount?.address,
          name: externalAccount?.address,
          artist_style: [...checkedThemeItems.keys()],
          urls: imgList?.map((imgFile) => imgFile?.url),
          images: imgList?.map((imgFile) => imgFile?.image),
          gender: themeGender,
          token_id: tokenId,
          category: aigcCategory || category
        };
        const ret = await axios.post<{
          data: { model_id: string };
          message?: string;
        }>(url, data);
        if (ret.status === 200 || ret.status === 201) {
          setModelId(ret.data?.data?.model_id);
        } else {
          showError(ret.data?.message);
        }
      } catch (error) {
        console.log('aigc/gen error', error);
      }
    },
    [
      checkedThemeItems,
      config.SBT_NODE_SERVICE,
      externalAccount,
      imgList,
      themeGender
    ]
  );

  const value = useMemo(
    () => ({
      themeList,
      checkedThemeItems,
      toggleCheckedThemeItem,
      generateImgs
    }),
    [themeList, checkedThemeItems, generateImgs]
  );

  return (
    <SBTThemeContext.Provider value={value}>
      {children}
    </SBTThemeContext.Provider>
  );
};

export const useSBTTheme = () => {
  const data = useContext(SBTThemeContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useSBTTheme() can only be used inside of <SBTThemeContext />, please declare it at a higher level.'
    );
  }
  return data;
};

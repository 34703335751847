import { memo, UIEvent, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import Icon from 'components/Icon';
import DotLoader from 'components/Loaders/DotLoader';
import { Step, useSBT } from 'pages/SBTPage/SBTContext';
import { useFaceRecognition } from 'pages/SBTPage/SBTContext/faceRecognitionContext';
import ButtonWithWallet from '../ButtonWithWallet';
import UploadImg from '../UploadImg';
import { useResponsive } from 'hooks';

const MIN_UPLOAD_LEN = 5;

const UploadItem = memo(function UploadItem({
  file,
  index
}: {
  file: File;
  index: number;
}) {
  const { handleRemove, checkInvalid } = useFaceRecognition();

  const imgUrl = useMemo(() => URL.createObjectURL(file), [file]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [imgUrl]);

  const invalid = checkInvalid(index);
  const inValidStyle = invalid ? '' : 'hidden group-hover:block';

  return (
    <div className="relative w-max group" key={index}>
      <img
        src={imgUrl}
        className="rounded-lg w-22 h-22 img-bg lg:w-52 lg:h-52"
      />
      <Icon
        onClick={() => {
          handleRemove(index);
        }}
        name="close"
        className={`absolute ${inValidStyle} -right-3 -top-3  cursor-pointer`}
      />
      {invalid && (
        <Icon
          name="invalid"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
});

const TipComponent = ({ maxUploadLen }: { maxUploadLen: number }) => {
  const { imgList } = useSBT();
  const { errorMsg } = useFaceRecognition();

  const maxLenInfo = useMemo(() => {
    if (imgList?.length > maxUploadLen) {
      return `You have exceeded the ${maxUploadLen} pictures limit. We only support ${maxUploadLen} pictures at maximum for the AI analysis.`;
    }
    return '';
  }, [imgList?.length]);

  if (errorMsg) {
    return (
      <p className="flex mt-2 text-error text-xs">
        <Icon
          name="information"
          className="mr-2 w-4 h-4 pt-1 lg:w-auto lg:h-auto lg:pt-0"
        />
        {errorMsg}
      </p>
    );
  }
  if (maxLenInfo) {
    return (
      <p className="flex mt-2 text-error text-xs">
        <Icon name="information" className="mr-2 w-4 h-4 lg:w-auto lg:h-auto" />
        {maxLenInfo}
      </p>
    );
  }
  return null;
};

const Cover = () => <div className="upload-img-cover" />;

const BtnComponent = ({
  detectLoading,
  uploadLoading
}: {
  detectLoading: boolean;
  uploadLoading: boolean;
}) => {
  return (
    <>
      {detectLoading || uploadLoading ? 'Processing' : 'Confirm'}
      {(detectLoading || uploadLoading) && (
        <DotLoader cls="transform scale-150 ml-4" />
      )}
    </>
  );
};

const UploadPanel = () => {
  const { isDesktop } = useResponsive();
  const MAX_UPLOAD_LEN = isDesktop ? 20 : 10;
  const [showCover, toggleCover] = useState(false);
  const [uploadLoading, toggleUploadLoading] = useState(false);

  const imgContainer = useRef<HTMLDivElement>(null);

  const { setCurrentStep, imgList } = useSBT();
  const { modelsLoaded, detectFaces, errorMsg, getGender, detectLoading } =
    useFaceRecognition();

  useEffect(() => {
    if (!imgContainer?.current || !modelsLoaded) {
      return;
    }

    detectFaces(imgContainer);
  }, [detectFaces, imgList, modelsLoaded]);

  const toThemePage = async () => {
    if (!imgList?.length) return;
    setCurrentStep(Step.Theme);
    getGender();
  };

  const btnDisabled = useMemo(() => {
    return (
      imgList?.length < MIN_UPLOAD_LEN ||
      imgList?.length > MAX_UPLOAD_LEN ||
      !!errorMsg ||
      detectLoading
    );
  }, [imgList, errorMsg, detectLoading]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop > 0) {
      toggleCover(true);
    } else {
      toggleCover(false);
    }
  };

  return (
    <div
      className={classNames(
        'relative flex-1 flex flex-col mx-auto mb-8 rounded-xl p-6 w-full',
        'lg:w-75'
      )}>
      <h1 className="text-2xl my-4 lg:text-3xl lg:my-6">Upload Photos</h1>
      <p className="text-sm text-opacity-60 text-white">
        Please upload at least {MIN_UPLOAD_LEN} and at most {MAX_UPLOAD_LEN}{' '}
        selfies. Adding more photos will produce a better zkPortrait. Please make
        sure the image clearly depicts your face. Avoid using any images that
        have other faces in them. Please also make sure the background is clean.
        This will ensure the best generation of your zkPortrait.
      </p>
      {!isDesktop && (
        <p className="flex mt-2 text-tip text-xss">
          <Icon
            name="information"
            className="-mt-1 mr-2 w-6 h-6 lg:w-auto lg:h-auto lg:pt-0"
          />
          <span>
            You may encounter a crash if you upload too many pictures or if your
            images are too large. Consider uploading smaller-sized images or
            reducing the number of images uploaded.
          </span>
        </p>
      )}
      <TipComponent maxUploadLen={MAX_UPLOAD_LEN} />
      <div
        className={classNames(
          'grid w-full gap-2 grid-cols-3 mb-16 pt-4 pr-4  relative',
          'lg:gap-6 lg:grid-cols-5 lg:mt-3 lg:max-h-44vh lg:overflow-y-auto'
        )}
        ref={imgContainer}
        onScroll={handleScroll}>
        {imgList?.map(({ file }, index) => {
          if (!file) {
            return null;
          }
          return (
            <UploadItem file={file} index={index} key={index + file.name} />
          );
        })}
        {imgList?.length < MAX_UPLOAD_LEN ? (
          <UploadImg
            toggleUploadLoading={toggleUploadLoading}
            uploadLoading={uploadLoading}
          />
        ) : null}
        {showCover && <Cover />}
      </div>
      {isDesktop ? (
        <ButtonWithWallet
          btnComponent={
            <BtnComponent
              detectLoading={detectLoading}
              uploadLoading={uploadLoading}
            />
          }
          onClick={toThemePage}
          disabled={btnDisabled}
          className={
            'min-w-45 flex justify-center items-center absolute py-2 unselectable-text text-center text-white rounded-lg gradient-button filter bottom-6 '
          }
        />
      ) : (
        <div className="fixed left-0 w-full py-4 px-6 flex justify-center items-center bg-sider bottom-0">
          <ButtonWithWallet
            btnComponent={
              <BtnComponent
                detectLoading={detectLoading}
                uploadLoading={uploadLoading}
              />
            }
            onClick={toThemePage}
            disabled={btnDisabled}
            className={
              'w-full flex justify-center items-center py-2 unselectable-text text-center text-white rounded-lg gradient-button filter bottom-6 '
            }
          />
        </div>
      )}
    </div>
  );
};

export default UploadPanel;

import APP_NAME from 'constants/AppConstants';
import { SS58 } from 'constants/NetworkConstants';
import keyring, { Keyring } from '@polkadot/ui-keyring';
import { getSubstrateWallets } from 'utils';
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  getAuthedWalletListStorage,
  setAuthedWalletListStorage
} from 'utils/persistence/connectAuthorizationStorage';
import { getLastAccessedExternalAccount } from 'utils/persistence/externalAccountStorage';
import {
  getLastAccessedWallet,
  setLastAccessedWallet
} from 'utils/persistence/walletStorage';
import { Wallet, WalletAccount } from 'manta-extension-connect';
import { KeyringPair } from '@polkadot/keyring/types';

type KeyringContextValue = {
  keyring: Keyring;
  isKeyringInit: boolean;
  keyringAddresses: string[];
  selectedWallet: Wallet | null;
  keyringIsBusy: MutableRefObject<boolean>;
  connectWallet: (
    extensionName: string,
    saveToStorage?: boolean
  ) => Promise<boolean | undefined>;
  connectWalletExtension: (extensionName: string) => void;
  refreshWalletAccounts: (wallet: Wallet) => Promise<void>;
  getLatestAccountAndPairs: () => {
    account: KeyringPair;
    pairs: KeyringPair[];
  };
};
const KeyringContext = createContext<KeyringContextValue | null>(null);

export const KeyringContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isKeyringInit, setIsKeyringInit] = useState(false);
  const [keyringAddresses, setKeyringAddresses] = useState<string[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [authedWalletList, setAuthedWalletList] = useState<string[]>(
    getAuthedWalletListStorage()
  );
  const keyringIsBusy = useRef(false);

  const addWalletName = (walletName: string, walletNameList: string[]) => {
    const copyWalletNameList = [...walletNameList];
    if (!copyWalletNameList.includes(walletName)) {
      copyWalletNameList.push(walletName);
      return copyWalletNameList;
    }
    return [];
  };

  const connectWalletExtension = useCallback(
    (extensionName: string) => {
      const walletNames = addWalletName(extensionName, authedWalletList);
      setAuthedWalletListStorage(walletNames);
      setAuthedWalletList(walletNames);
    },
    [authedWalletList]
  );

  const refreshWalletAccounts = useCallback(async (wallet: Wallet) => {
    keyringIsBusy.current = true;
    let currentKeyringAddresses = keyring
      .getAccounts()
      .map((account) => account.address);

    let originUpdatedAccounts: WalletAccount[] = [];
    try {
      originUpdatedAccounts = await wallet.getAccounts();
    } catch (e) {
      // will throw error if manta wallet is not connected, which we don't want
      console.log('wallet.getAccounts error: ', e);
    }
    const updatedAccounts = originUpdatedAccounts.filter((a) => {
      // @ts-ignore
      return ['ecdsa', 'ed25519', 'sr25519'].includes(a.type);
    }); // ethereum account address should be avoid in substrate (tailsman)
    const substrateAddresses: string[] = updatedAccounts.map(
      (account) => account.address
    );
    currentKeyringAddresses.forEach((address) => {
      keyring.forgetAccount(address);
    });
    // keyring has the possibility to still contain accounts
    currentKeyringAddresses = keyring
      .getAccounts()
      .map((account) => account.address);

    if (currentKeyringAddresses.length === 0) {
      updatedAccounts.forEach((account) => {
        // loadInjected is a privated function, will caused eslint error
        // @ts-ignore
        keyring.loadInjected(account.address, { ...account }, account.type);
      });

      setSelectedWallet(wallet);
      setKeyringAddresses(substrateAddresses);
    }

    keyringIsBusy.current = false;
  }, []);

  const getLatestAccountAndPairs = () => {
    const pairs = keyring.getPairs();
    const {
      meta: { source }
    } = pairs[0] || { meta: {} };
    const account =
      getLastAccessedExternalAccount(keyring, source as string) || pairs[0];
    return { account, pairs };
  };

  const initKeyring = useCallback(async () => {
    if (!isKeyringInit) {
      const isManta = window?.location?.pathname?.includes('manta');
      keyring.loadAll(
        {
          ss58Format: isManta ? SS58.MANTA : SS58.CALAMARI
        },
        []
      );
      setIsKeyringInit(true);
    }
  }, [isKeyringInit]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isKeyringInit) {
        initKeyring();
      } else {
        clearInterval(timer);
      }
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, [initKeyring, isKeyringInit]);

  const connectWallet = useCallback(
    async (extensionName: string, saveToStorage = true) => {
      if (!isKeyringInit) {
        return;
      }
      const substrateWallets = getSubstrateWallets();
      const selectedWallet = substrateWallets.find(
        (wallet) => wallet.extensionName === extensionName
      );
      if (!selectedWallet?.extension) {
        try {
          if (selectedWallet) {
            await selectedWallet.enable(APP_NAME);
            selectedWallet?.subscribeAccounts((accounts) => {
              // when accounts.length === 0, clear manta-extension-connect package inner data
              if (!accounts?.length) {
                (selectedWallet as any)._extension = undefined;
                (selectedWallet as any)._signer = undefined;
                setSelectedWallet(null);
              }
              refreshWalletAccounts(selectedWallet);
            });
          }
          saveToStorage &&
            selectedWallet &&
            setLastAccessedWallet(selectedWallet);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    [isKeyringInit, refreshWalletAccounts]
  );

  useEffect(() => {
    if (!isKeyringInit) {
      return;
    }
    setTimeout(() => {
      const extensionName = (getLastAccessedWallet() as Wallet)?.extensionName;
      connectWallet(extensionName);
    }, 0);
  }, [connectWallet, isKeyringInit]);

  const value = useMemo(
    () => ({
      keyring, // keyring object would not change even if properties changed
      isKeyringInit,
      keyringAddresses, //keyring object would not change so use keyringAddresses to trigger re-render
      selectedWallet,
      keyringIsBusy,
      connectWallet,
      connectWalletExtension,
      refreshWalletAccounts,
      getLatestAccountAndPairs
    }),
    [
      connectWallet,
      connectWalletExtension,
      isKeyringInit,
      keyringAddresses,
      selectedWallet
    ]
  );

  return (
    <KeyringContext.Provider value={value}>{children}</KeyringContext.Provider>
  );
};

export const useKeyring = () => {
  const data = useContext(KeyringContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useKeyring can only be used inside of <KeyringContext />, please declare it at a higher level.'
    );
  }
  return data;
};

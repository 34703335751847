export const randomArray = (arr: Array<any>) => {
  const newArr = [...arr];
  for (let i = 0; i < newArr.length; i++) {
    const rand = Math.min(
      newArr.length - 1,
      Math.ceil(Math.random() * newArr.length)
    );
    [newArr[rand], newArr[i]] = [newArr[i], newArr[rand]];
  }
  return newArr;
};

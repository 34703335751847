import {
  useEffect,
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import axios from 'axios';
import AssetType from 'types/AssetType';
import Decimal from 'decimal.js';
import Usd from 'types/Usd';
import { useConfig } from './configContext';

type UsdPrice = Record<string, Usd | null>;
type UsdPricesContextValue = {
  usdPrices: UsdPrice;
  nativeUsdPrice: Usd | null;
};
const UsdPricesContext = createContext<UsdPricesContextValue | null>(null);

export const UsdPricesContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const { IS_TESTNET, NETWORK_NAME } = useConfig();
  const [usdPrices, setUsdPrices] = useState<UsdPrice>({} as UsdPrice);

  const fetchUsdPrices = useCallback(async () => {
    try {
      const assets = AssetType.AllCurrencies(
        { IS_TESTNET, NETWORK_NAME },
        false
      );
      if (!assets?.length) {
        return;
      }
      const ids = assets?.reduce((res, asset, index) => {
        return `${res}${asset.coingeckoId}${
          index < assets?.length - 1 ? ',' : ''
        }`;
      }, '');

      const res = await axios.get(
        'https://52rhbkf39l.execute-api.eu-central-1.amazonaws.com/prod/prices/ethereum,usd-coin'
      );

      if (res.data) {
        const prices = {} as UsdPrice;
        assets?.forEach((asset) => {
          if (asset?.baseTicker) {
            prices[asset?.baseTicker] = res.data[asset.coingeckoId]
              ? new Usd(new Decimal(res.data[asset.coingeckoId]['usd']))
              : null;
          }
        });
        setUsdPrices({ ...prices });
      }
    } catch (err) {
      setUsdPrices({});
    }
  }, [IS_TESTNET, NETWORK_NAME]);

  useEffect(() => {
    fetchUsdPrices();
  }, [fetchUsdPrices]);

  const nativeUsdPrice = useMemo(() => {
    return usdPrices[
      AssetType.Native({ IS_TESTNET, NETWORK_NAME })?.baseTicker
    ];
  }, [IS_TESTNET, NETWORK_NAME, usdPrices]);

  const value = useMemo(
    () => ({
      usdPrices,
      nativeUsdPrice
    }),
    [usdPrices, nativeUsdPrice]
  );

  return (
    <UsdPricesContext.Provider value={value}>
      {children}
    </UsdPricesContext.Provider>
  );
};

export const useUsdPrices = () => {
  const data = useContext(UsdPricesContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useUsdPrices can only be used inside of <UsdPricesContext />, please declare it at a higher level.'
    );
  }
  return data;
};

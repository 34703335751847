import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

const ModalContainer = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000
    }}>
    {children}
  </div>
);

const ModalBackDrop = ({ onClick }: { onClick: MouseEventHandler }) => (
  <div
    onClick={onClick}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: -1
    }}
  />
);

interface IUseModal {
  outLineCss?: string;
  closeDisabled?: boolean;
  closeCallback?: () => void;
  closeOnBackdropClick?: boolean;
}

type UseModalResult = {
  ModalWrapper: ({ children }: { children: ReactNode }) => JSX.Element | null;
  showModal: () => void;
  hideModal: () => void;
  open: boolean;
};

export const useModal: (options?: IUseModal) => UseModalResult = (
  options = {
    outLineCss: '',
    closeDisabled: false,
    closeOnBackdropClick: true
  }
) => {
  const { closeDisabled, closeCallback, closeOnBackdropClick, outLineCss } =
    options;

  const [open, setOpen] = useState(false);

  const showModal = useCallback(() => setOpen(true), []);
  const hideModal = useCallback(
    (triggerCloseCallback = true) => {
      setOpen(false);
      if (closeCallback && triggerCloseCallback) {
        closeCallback();
      }
    },
    [closeCallback]
  );

  useEffect(() => {
    // prevents horizontal scroll when modal is open
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const ModalWrapper = useMemo(
    function ModalWrapper() {
      return function ModalWrapper({ children }: { children: ReactNode }) {
        return open ? (
          <ModalContainer>
            <ModalBackDrop
              onClick={() => {
                if (closeOnBackdropClick) {
                  hideModal();
                }
              }}
            />
            {!closeDisabled ? (
              <div
                className={
                  outLineCss
                    ? outLineCss
                    : classNames(
                        'relative inline-block p-4 bg-primary rounded-xl text-black w-full mx-6 ',
                        'md:w-100 lg:mx-auto lg:p-6'
                      )
                }>
                <div
                  className="absolute z-10 top-5 right-7 text-black dark:text-white cursor-pointer text-lg"
                  onClick={() => hideModal()}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                {children}
              </div>
            ) : (
              <div
                className={
                  outLineCss
                    ? outLineCss
                    : 'relative inline-block px-6 py-4 rounded-lg bg-secondary text-white'
                }>
                {children}
              </div>
            )}
          </ModalContainer>
        ) : null;
      };
    },
    [closeDisabled, closeOnBackdropClick, hideModal, open, outLineCss]
  );

  return { ModalWrapper, showModal, hideModal, open };
};

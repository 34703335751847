import NETWORK from 'constants/NetworkConstants';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { dolphinConfig, calamariConfig, ConfigType, mantaConfig, ethereumConfig } from 'config';

const ConfigContext = createContext<ConfigType | null>(null);

export const ConfigContextProvider = ({
  children,
  network
}: {
  children: ReactNode;
  network: NETWORK;
}) => {
  const config = useMemo(() => {
    switch (network) {
      case NETWORK.DOLPHIN:
        return dolphinConfig;
      case NETWORK.CALAMARI:
        return calamariConfig;
      case NETWORK.MANTA:
        return mantaConfig;
      default:
        return ethereumConfig;
    }
  }, [network]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const data = useContext(ConfigContext);
  if (!data || !Object.keys(data).length) {
    throw new Error(
      'useConfig can only be used inside of <ConfigContext />, please declare it at a higher level.'
    );
  }
  return data;
};

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useConfig } from 'contexts/configContext';
import { dolphinConfig } from 'config';

export const NAVLINKPATH = {
  Transact: '/transact',
  Bridge: '/bridge',
  Stake: '/stake',
  NPO: '/sbt',
  NPOList: '/sbt/list'
};

export const ONLINE_BASE_URL = 'https://app.manta.network';

const NavLinks = () => {
  const { NETWORK_NAME, EVM_CHAIN } = useConfig();
  const location = useLocation();
  const networkUrlParam = `/calamari`;

  const isDolphinPage = NETWORK_NAME === dolphinConfig.NETWORK_NAME;
  const isActiveTransactPage = location.pathname.includes(NAVLINKPATH.Transact);
  const isActiveBridgePage = location.pathname.includes(NAVLINKPATH.Bridge);
  const isActiveStakePage = location.pathname.includes(NAVLINKPATH.Stake);
  const isActiveNPOPage = location.pathname.includes(NAVLINKPATH.NPO);

  return (
    <div className="ml-16 flex flex-row justify-between w-128 shadow-2xl items-center text-sm font-red-hat-text">
      <a
        href={`${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Transact}`}
        className={classNames(
          'py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100',
          {
            ' text-white text-opacity-100 font-bold': isActiveTransactPage
          }
        )}
        target="_blank"
        rel="noopener noreferrer">
        MantaPay
      </a>
      <a
        href={`${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Bridge}`}
        className={classNames(
          'py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100',
          {
            'text-white text-opacity-100 font-bold': isActiveBridgePage
          }
        )}
        target="_blank"
        rel="noopener noreferrer">
        Bridge
      </a>
      <NavLink
        to={`/evm${NAVLINKPATH.NPO}`}
        className={classNames(
          'py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100',
          {
            'text-white text-opacity-100 font-bold': isActiveNPOPage
          }
        )}>
        NPO
      </NavLink>
      {!isDolphinPage && (
        <a
          href={`${ONLINE_BASE_URL}${networkUrlParam}${NAVLINKPATH.Stake}`}
          className={classNames(
            'py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100',
            {
              ' text-white text-opacity-100 font-bold': isActiveStakePage
            }
          )}
          target="_blank"
          rel="noopener noreferrer">
          Staking
        </a>
      )}
      <a
        href="https://forum.manta.network/"
        className="py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100"
        target="_blank"
        rel="noreferrer">
        Govern
      </a>
      <a
        href={`${EVM_CHAIN?.blockExplorers?.etherscan?.url}`}
        className="py-3 text-white text-opacity-60 text-center hover:text-white hover:text-opacity-100"
        target="_blank"
        rel="noreferrer">
        Block Explorer
      </a>
    </div>
  );
};

export default NavLinks;

import { useEffect, useState } from 'react';

import classNames from 'classnames';
import Icon from 'components/Icon';
import { Popover } from 'element-react';
import { useGenerated } from 'pages/SBTPage/SBTContext/generatedContext';
import { useNavigate } from 'react-router-dom';
import asMatchImg from 'resources/images/sbt/asMatch.jpg';
import { useGenerating } from 'pages/SBTPage/SBTContext/generatingContext';
import { firstUpperCase } from 'utils/string';

const PopContent = () => {
  return (
    <div className="flex items-center text-xss text-white text-left">
      <img src={asMatchImg} className="w-24" />
      <p className="flex-1 ml-4">
        For AsMatch Users, please click here to copy and paste to the page at
        AsMatch App as shown on the left. If you have multiple zkSBTs, don’t
        worry this also works for multiple zkSBTs.
      </p>
    </div>
  );
};

const MintedModal = () => {
  const [copied, toggleCopied] = useState(false);
  const navigate = useNavigate();
  const { generatedImgThemes } = useGenerating();
  const { mintSet } = useGenerated();

  useEffect(() => {
    const timer = setTimeout(() => copied && toggleCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  const copiedStyled = copied ? 'opacity-60' : '';

  return (
    <div className="text-white w-full lg:w-204">
      <h2 className="text-2xl">MINTED！</h2>
      <p className="text-white text-opacity-60 text-xs mb-2 mt-6">
        Your zkPortrait should appear in your Manta Wallet.You can bond your
        newly minted zkPortrait to your
        <span
          className="text-check cursor-pointer"
          onClick={() => {
            window.open(
              'https://www.asmatch.app/',
              '_blank',
              'noopener,noreferrer'
            );
          }}>
          {' ' + 'AsMatch' + ' '}
        </span>
        profile (click to download the app).
      </p>
      <div
        className={classNames(
          'grid w-full gap-2 grid-cols-3 pb-12 mt-6',
          'lg:grid-cols-4 lg:gap-6'
        )}>
        {[...mintSet]?.map((generatedImg, index) => {
          return (
            <div key={index} className="relative">
              <img
                src={generatedImg}
                className={classNames(
                  'rounded-lg w-22 h-22 img-bg',
                  'lg:w-44 lg:h-44'
                )}
              />
              <div className="absolute left-2 bottom-2 text-xs lg:text-base">
                {firstUpperCase(generatedImgThemes[generatedImg] || '')}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center">
        <button
          className={`w-60 px-4 py-2 unselectable-text text-center text-white rounded-lg gradient-button filter ${copiedStyled}`}
          onClick={() => {
            navigate('/');
          }}>
          Confirm
        </button>
        {/*
        // @ts-ignore */}
        <Popover
          trigger="hover"
          placement="right"
          content={<PopContent />}
          width="356">
          <div>
            <Icon name="question" className="ml-4 cursor-pointer" />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default MintedModal;

import { useMemo } from 'react';

import Icon from 'components/Icon';

import classNames from 'classnames';
import { useConfig } from 'contexts/configContext';
import { useMantaWallet } from 'contexts/mantaWalletContext';
import { useNativeBalance } from 'hooks';
import { Step, UploadFile, useSBT } from 'pages/SBTPage/SBTContext';
import {
  MINT_CATEGORY,
  MintStatusText,
  useSbtToken
} from 'pages/SBTPage/SBTContext/sbtTokenContext';
import { ProcessingStatus } from 'pages/SBTPage/const';
import { useNavigate } from 'react-router-dom';
import { UpdateVersionNotification } from 'utils/ui/Notifications';
import versionLargerThan from 'utils/versionLarger';
import ButtonWithWallet from '../ButtonWithWallet';

const MintButton = ({
  showMintModal,
  checkBalance = false,
  leftToRight = false,
  disable
}: {
  showMintModal: () => void;
  checkBalance?: boolean;
  leftToRight?: boolean;
  disable?: boolean;
}) => {
  const { onGoingTask, setCurrentStep, setImgList } = useSBT();
  const { isMinted, gasFee, mintStatusMsg, mintErrMsg, setMinting, category } =
    useSbtToken();
  const nativeBalance = useNativeBalance();
  const { MIN_REQUIRED_WALLET_VERSION } = useConfig();
  const { mantaWalletVersion } = useMantaWallet();
  const outdated = versionLargerThan(
    MIN_REQUIRED_WALLET_VERSION,
    mantaWalletVersion
  );
  const navigate = useNavigate();
  const isZkAsMatchAigc = MINT_CATEGORY.zkAsMatchAigc === category;

  const errorMsg = useMemo(() => {
    if (mintStatusMsg === MintStatusText.MINTED) {
      return '';
    }
    if (mintErrMsg) {
      return mintErrMsg;
    }
    if (gasFee && nativeBalance && gasFee?.gt(nativeBalance)) {
      return 'Insufficient balance';
    }
    return '';
  }, [mintStatusMsg, mintErrMsg, nativeBalance, gasFee, checkBalance]);

  const handleClickEnterOrMint = async () => {
    if (isZkAsMatchAigc) {
      if (
        onGoingTask?.status_str === ProcessingStatus.PREPAREMINTED ||
        onGoingTask?.status_str === ProcessingStatus.GENERATED
      ) {
        setCurrentStep(Step.Generated);
      }
      if (onGoingTask?.status_str === ProcessingStatus.GENERATING) {
        setCurrentStep(Step.Generating);
      }
      setImgList((onGoingTask?.urls as UploadFile[]) || []);
      navigate('/evm/sbt/aigc');
    }
    setMinting();
    if (outdated) {
      UpdateVersionNotification();
      return;
    }
    showMintModal();
  };

  const btnComponent = useMemo(() => {
    if (
      isZkAsMatchAigc &&
      onGoingTask?.status_str !== ProcessingStatus.GENERATING &&
      onGoingTask?.status_str !== ProcessingStatus.PREPAREMINTED &&
      onGoingTask?.status_str !== ProcessingStatus.GENERATED
    ) {
      return 'Enter';
    }
    if (mintStatusMsg) return mintStatusMsg;
    return 'Mint';
  }, [mintStatusMsg]);

  // const disabled = true;
  const disabled = !!errorMsg || !!isMinted || disable;

  return (
    <div className={`flex flex-col w-full ${leftToRight ? '' : 'items-end'}`}>
      <ButtonWithWallet
        btnComponent={btnComponent}
        onClick={disabled ? undefined : handleClickEnterOrMint}
        disabled={disabled}
        className={classNames(
          `${
            leftToRight ? '' : 'self-end'
          } flex items-center justify-center unselectable-text text-center text-white rounded-lg gradient-button w-full h-10 filter`,
          'lg:w-60'
        )}
      />
      {errorMsg && (
        <p
          className={classNames(
            'flex items-center  text-xsss mt-2',
            errorMsg === 'Current address is minting'
              ? 'text-green-500'
              : 'text-error'
          )}>
          <Icon name="information" className="mr-2" /> {errorMsg}
        </p>
      )}
    </div>
  );
};
export default MintButton;

import * as allChains from '@wagmi/core/chains';
import { publicProvider } from '@wagmi/core/providers/public';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { ReactNode } from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

import Navbar from 'components/Navbar';
import { useConfig } from 'contexts/configContext';
import { PublicBalancesContextProvider } from 'contexts/publicBalancesContext';
import { UsdPricesContextProvider } from 'contexts/usdPricesContext';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Main from './Main';
import { SBTContextProvider } from './SBTContext';
import { KusamaChainProvider } from './SBTContext/KusamaChainContext';
import { PolkadotChainProvider } from './SBTContext/PolkadotChainContext';
import { FaceRecognitionContextProvider } from './SBTContext/faceRecognitionContext';
import { GeneratedContextProvider } from './SBTContext/generatedContext';
import { GeneratingContextProvider } from './SBTContext/generatingContext';
import { MintContextProvider } from './SBTContext/mintContext';
import { SBTThemeContextProvider } from './SBTContext/sbtThemeContext';
import AddressChangeNotification from './components/AddressChangeNotification';
import MintedList from './components/MintedList';
import OnGoingTaskNotification from './components/OnGoingTaskModal';

import { ZkSbtSdkContextProvider } from 'contexts/zkSbtContext';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/swiper.scss'; // core Swiper
import { MintedListContextProvider } from './SBTContext/mintedListContext';
import { SbtCommonContextProvider } from './SBTContext/sbtCommonContext';
import Home from './components/Home/EVM';
import ProjectDetail from './components/ProjectDetail';
import './index.scss';
import { SbtTokenContextProvider } from './SBTContext/sbtTokenContext';

const ChainsProviders = ({ children }: { children: ReactNode }) => {
  return (
    <PolkadotChainProvider>
      <KusamaChainProvider>{children}</KusamaChainProvider>
    </PolkadotChainProvider>
  );
};

const SBTProviders = ({ children }: { children: ReactNode }) => {
  return (
    <SBTContextProvider>
      <FaceRecognitionContextProvider>
        <SBTThemeContextProvider>
          <GeneratingContextProvider>
            <GeneratedContextProvider>{children}</GeneratedContextProvider>
          </GeneratingContextProvider>
        </SBTThemeContextProvider>
      </FaceRecognitionContextProvider>
    </SBTContextProvider>
  );
};

const MintedListWrap = () => (
  <MintedListContextProvider>
    <MintedList />
  </MintedListContextProvider>
);

export const SBT = () => {
  const { PAGE_TITLE, EVM_CHAIN } = useConfig();
  document.title = PAGE_TITLE;
  const projectId = 'd31dd9bf162e306e3712cb3b7ca5552f';
  const allChainValues = [...Object.values(allChains), EVM_CHAIN];

  const { publicClient } = configureChains(allChainValues, [
    w3mProvider({ projectId }),
    publicProvider()
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      ...w3mConnectors({ projectId, chains: allChainValues } as any)
      // new MetaMaskConnector({
      //   chains: [EVM_CHAIN],
      //   options: {
      //     shimDisconnect: true,
      //     UNSTABLE_shimOnConnectSelectAccount: true
      //   }
      // })
    ],
    publicClient
  });
  const ethereumClient = new EthereumClient(wagmiConfig, allChainValues);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <ZkSbtSdkContextProvider>
          <UsdPricesContextProvider>
            <PublicBalancesContextProvider>
              <SbtCommonContextProvider>
                <SBTProviders>
                  <SbtTokenContextProvider>
                    <div className="text-white min-h-screen flex flex-col sbt-page">
                      <Navbar />
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                          path="/projects/:projectType"
                          element={<ProjectDetail />}
                        />
                        <Route path="/list" element={<MintedListWrap />} />
                        <Route path="/aigc" element={<Main />} />
                      </Routes>
                      <AddressChangeNotification />
                    </div>
                  </SbtTokenContextProvider>
                </SBTProviders>
              </SbtCommonContextProvider>
            </PublicBalancesContextProvider>
          </UsdPricesContextProvider>
        </ZkSbtSdkContextProvider>
      </WagmiConfig>

      <Web3Modal
        defaultChain={EVM_CHAIN}
        // explorerExcludedWalletIds={[
        //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'
        // ]}
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
};

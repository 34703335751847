import { useWeb3Modal } from '@web3modal/react';
import Icon, { IconName, IconProps } from 'components/Icon';
import { isMetaMask, useConnectMetamask } from 'hooks';
import linkArrow from 'resources/icons/link_arrow.svg';
import { useAccount, useDisconnect } from 'wagmi';

export interface WalletItemProps {
  icon: IconName;
  label: string;
  iconStyle?: Omit<IconProps, 'name'>;
  onClick: () => void;
}

export enum WalletType {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect'
}

export const iconNames: Record<WalletType, IconName> = {
  [WalletType.MetaMask]: 'metamask',
  [WalletType.WalletConnect]: 'walletconnect'
};

const WalletItem = ({ icon, label, onClick, iconStyle }: WalletItemProps) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between items-center p-4 mb-4 border border-white-transparent rounded-lg  hover:opacity-70 cursor-pointer">
      <div className="flex">
        <Icon className="mr-3.5 w-6 h-6" {...iconStyle} name={icon} />
        <div>{label}</div>
      </div>
      <img src={linkArrow} />
    </div>
  );
};

const EVMWalletModal = ({ hideModal }: { hideModal: () => void }) => {
  const { open } = useWeb3Modal();
  const { connector } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const connectMetamask = useConnectMetamask({ autoConnect: false });

  const handleMetaMask = async () => {
    await connectMetamask();
    hideModal();
  };

  const handleWalletConnect = async () => {
    if (connector?.name === WalletType.MetaMask) {
      await disconnectAsync();
    }
    open();
    hideModal();
  };

  return (
    <div className="flex relative w-full text-white  flex-col">
      <div className="text-xl lg:text-2xl font-semibold font-red-hat-text mb-6">
        Connect Wallet
      </div>
      {isMetaMask && (
        <WalletItem
          onClick={async () => {
            handleMetaMask();
          }}
          icon={iconNames[WalletType.MetaMask]}
          label="MetaMask"
        />
      )}
      <WalletItem
        iconStyle={{
          className: 'mr-3 w-7 h-7'
        }}
        onClick={handleWalletConnect}
        icon={iconNames[WalletType.WalletConnect]}
        label="Wallet Connect"
      />
    </div>
  );
};

export default EVMWalletModal;

import { Navigation, type Swiper as SwiperRefType } from 'swiper';
import { MutableRefObject } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  useSBTTheme,
  ThemeItem
} from 'pages/SBTPage/SBTContext/sbtThemeContext';
import { useResponsive } from 'hooks';

const Item = ({
  item,
  button
}: {
  item: ThemeItem;
  button: React.ReactNode;
}) => {
  const { isDesktop } = useResponsive();
  return (
    <div className="relative w-full lg:h-content lg:w-auto">
      <img src={item?.url} className="w-full h-80 rounded-lg lg:w-96 lg:h-96" />
      <span className="absolute bottom-4 left-1/2 transform -translate-x-1/2 trans bg-gradient rounded-full text-sm px-2">
        {item?.name}
      </span>
      {button}
    </div>
  );
};
const ThemeChecked = ({
  swiperRef,
  defaultThemeItem,
  button
}: {
  swiperRef: MutableRefObject<SwiperRefType | null>;
  defaultThemeItem: ThemeItem;
  button: React.ReactNode;
}) => {
  const { isDesktop } = useResponsive();
  const { checkedThemeItems } = useSBTTheme();
  if (checkedThemeItems.size === 0) {
    return <Item item={defaultThemeItem} button={button}></Item>;
  }

  return (
    <div className="relative h-full">
      <Swiper
        className="w-full h-80 lg:w-96 m-0 h-content"
        autoplay={false}
        navigation={true}
        modules={[Navigation]}
        onSwiper={(swiper) => {
          swiperRef && (swiperRef.current = swiper);
        }}>
        {[...checkedThemeItems.values()].map((item) => {
          return (
            <SwiperSlide key={item.name}>
              <Item item={item} button={button} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {button}
    </div>
  );
};
export default ThemeChecked;

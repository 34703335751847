import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { Notification } from 'element-react';
import { ReactNode } from 'react';

import {
  NotificationContent,
  TxSuccessNotificationContent
} from 'components/NotificationContent';

export const showError = (msg: ReactNode) => {
  Notification.error({
    title: 'Error',
    message: <NotificationContent msg={msg} />,
    duration: 15000,
    offset: 70
  });
};

export const showWarning = (msg: ReactNode) => {
  Notification({
    title: 'Warning',
    message: <NotificationContent msg={msg} />,
    type: 'warning',
    duration: 15000,
    offset: 70
  });
};

export const showSuccess = (subscanBaseUrl = '', extrinsic = '') => {
  Notification({
    title: 'Success',
    message: (
      <TxSuccessNotificationContent
        subscanBaseUrl={subscanBaseUrl}
        extrinsic={extrinsic}
      />
    ),
    type: 'success',
    duration: 15000,
    offset: 70
  });
};

export const showCustomSuccess = (msg: ReactNode, duration = 15000) => {
  Notification({
    title: 'Success',
    message: <NotificationContent msg={msg} />,
    type: 'success',
    duration,
    offset: 70
  });
};

export const showInfo = (msg: ReactNode, duration = 7500) => {
  Notification.info({
    title: 'Info',
    message: <NotificationContent msg={msg} />,
    duration,
    offset: 70
  });
};

export const WarningNotification = ({
  title,
  content,
  linkUrl,
  linkText
}: {
  title: string;
  content: string;
  linkUrl?: string;
  linkText?: string;
}) => {
  return (
    <div className={classNames('flex items-center justify-center -mt-1')}>
      <Icon
        className="w-12 h-12 bg-red"
        style={{ color: '#FFA132' }}
        name="warning"
      />
      <div className="mx-4">
        <div className="text-sm font-semibold text-thirdry mb-1">{title}</div>
        <p className="text-xs my-1">{content}</p>
        {linkUrl && (
          <a
            href={linkUrl}
            target="_blank"
            className={classNames('text-xs mt-1', {
              'cursor-pointer': linkUrl
            })}
            rel="noreferrer">
            <span className="mr-2">{linkText}</span>
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
        )}
      </div>
    </div>
  );
};

export const UpdateVersionNotification = () => {
  // use setTimeout to fix two Notifications overlap issue
  setTimeout(() => {
    const updateVersionWarningInfo = {
      title: 'Please Update Manta Wallet',
      content:
        'Please update Manta Wallet to the latest version and then refresh the current page.',
      linkUrl: 'https://docs.manta.network/docs/guides/MantaWalletUpdate',
      linkText: 'Learn how to update'
    };
    Notification({
      message: <WarningNotification {...updateVersionWarningInfo} />,
      duration: 15000,
      offset: 70
    });
  }, 0);
};
